import Constants from 'constants/index';
import { getHighestUserPermission } from 'utils/user';
import { createInstance } from '@amplitude/analytics-browser';
import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { isUserInternational } from 'store/user/selectors';
import { useSelector } from 'react-redux';
import { addPlatformPlugin } from './AmplitudePlatformPlugin';

export const AmplitudeContext = createContext();

export const AmplitudeProvider = ({ apiKey, user, options, children }) => {
  const isInternationalUser = useSelector(isUserInternational);

  const [track, setTrack] = useState(null);

  useEffect(() => {
    if (!isInternationalUser && apiKey && options && user && !track) {
      const userId = user?.['cfa-guid'];
      const userType = user?.['userType'];
      const userPerms = user?.['cfa_perms'];
      const permissionLevel = getHighestUserPermission(
        get(user?.cfa_perms, Constants.APPLICATION_SYSTEM_TAG),
      );
      const userLocations = user?.['cfa-locations'];

      const amplitudeInstance = createInstance();
      amplitudeInstance.add(addPlatformPlugin());
      amplitudeInstance.init(apiKey, userId, options);
      setTrack(() => (...args) => {
        const [eventName, properties] = args;
        const date = new Date().toLocaleDateString();
        const time = new Date().toLocaleTimeString();
        const version = Constants.APP_VERSION;
        const env = Constants.ENV;

        amplitudeInstance.track('UserAction', {
          action: eventName,
          date: date,
          permission_level: permissionLevel,
          time: time,
          version: version,
          user_type: userType,
          user_perms: userPerms,
          user_locations: userLocations,
          env: env,
          ...properties,
        });
      });
    }
  }, [apiKey, isInternationalUser, options, track, user]);

  return (
    <AmplitudeContext.Provider value={track}>
      {children}
    </AmplitudeContext.Provider>
  );
};

AmplitudeProvider.propTypes = {
  children: PropTypes.any.isRequired,
  apiKey: PropTypes.string.isRequired,
  user: PropTypes.shape({
    'cfa-guid': PropTypes.string,
    userType: PropTypes.string,
    cfa_perms: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    'cfa-locations': PropTypes.array,
  }),
  options: PropTypes.object,
};

AmplitudeProvider.defaultProps = {
  user: null,
  options: {},
};
