import {
  Button,
  Divider,
  Surface,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import {
  isOperatorConsultant,
  selectUserIsLicenseeStaff,
  selectUserLicenseeLocations,
} from '@/store/user/selectors';
import {
  setTogglePlansLicensee,
  setTogglePlansMyRestaurants,
} from '@/store/licensee/slice';
import {
  addManagePlansCategoryFilter,
  addManagePlansLocationFilter,
  removeManagePlansFilter,
  resetManagePlans,
} from '@/store/managePlansFilter/slice';
import StickyArea from '@/components/Cards/StickyFilterCard/StickyArea';
import CheckboxFilterSection from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';
import { selectFilters } from '@/store/managePlansFilter/selector';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { setShowLicenseeLocationPicker } from '@/store/licenseeLocationPicker/slice';

interface LicenseeOptionsProps {
  isFetching: boolean;
}

const LicenseeOptions: React.FC<LicenseeOptionsProps> = ({ isFetching }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { licensee: licenseeFeatureFlag } = useFlags();
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const currentFilters: string[] = useSelector(selectFilters);
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const categoryLabels = useCategoryLabels(currentFilters);

  const toggleMyRestaurants = () => {
    dispatch(setTogglePlansMyRestaurants());
    dispatch(resetManagePlans());
  };

  const toggleLicensee = () => {
    dispatch(setTogglePlansLicensee());
    dispatch(resetManagePlans());
  };

  return (
    !isSmAndDown &&
    !isFetching && (
      <StickyArea>
        {!!isOperatorConsultantUser && licenseeFeatureFlag && (
          <ToggleButtonGroup
            fullWidth
            orientation="horizontal"
            size="sm"
            value={isLicenseePlans}
          >
            <ToggleButton
              aria-label="my-restaurants"
              onClick={toggleMyRestaurants}
              value={false}
            >
              <Typography
                fontWeight={isLicenseePlans ? 'regular' : 'bold'}
                variant="body1"
              >
                {t('Licensee.myRestaurants')}
              </Typography>
            </ToggleButton>
            <ToggleButton
              aria-label="licensees"
              onClick={toggleLicensee}
              value={true}
            >
              <Typography
                fontWeight={isLicenseePlans ? 'bold' : 'regular'}
                variant="body1"
              >
                {t('Licensee.licensees')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        )}

        <FilterCardSurface elevation={1} radii="sm" variant="outlined">
          {isOperatorConsultantUser &&
            isLicenseePlans &&
            licenseeFeatureFlag && (
              <>
                <CheckboxFilterSection
                  labels={userLicenseeLocations.reduce((acc, location) => {
                    // eslint-disable-next-line no-param-reassign
                    acc = {
                      ...acc,
                      [location?.number]: {
                        translationString: location?.name,
                        value: !!currentFilters.includes(location?.number),
                      },
                    };
                    return acc;
                  }, {})}
                  onChange={(value: string) => {
                    if (!!currentFilters.includes(value)) {
                      dispatch(removeManagePlansFilter({ filter: value }));
                    } else {
                      dispatch(addManagePlansLocationFilter({ filter: value }));
                    }
                  }}
                  title={t('Licensee.licenseeLocations')}
                />
                <Divider style={{ margin: '16px 0' }} variant="fullLength" />
              </>
            )}
          {!!userIsLicenseeStaff && !isSmAndDown && licenseeFeatureFlag && (
            <>
              <StaffChooseLocationContainer>
                <Typography fontWeight="bold" variant="body1">
                  {t('Licensee.licenseeLocation')}
                </Typography>
                {activeLicenseeLocation?.name && (
                  <Typography variant="body1">
                    {`${activeLicenseeLocation.number} ${activeLicenseeLocation.name}`}
                  </Typography>
                )}
                <ChooseLocationButton
                  color="secondary"
                  onClick={() => {
                    dispatch(setShowLicenseeLocationPicker());
                  }}
                  size="sm"
                  variant="outlined"
                >
                  {activeLicenseeLocation?.name
                    ? t('Generic.change')
                    : t('Generic.choose')}
                </ChooseLocationButton>
              </StaffChooseLocationContainer>
              <Divider style={{ margin: '16px 0' }} variant="fullLength" />
            </>
          )}
          <CheckboxFilterSection
            labels={categoryLabels}
            onChange={(value: string) => {
              if (!!currentFilters.includes(value)) {
                dispatch(removeManagePlansFilter({ filter: value }));
              } else {
                dispatch(addManagePlansCategoryFilter({ filter: value }));
              }
            }}
            title={t('Browse.categories')}
          />
        </FilterCardSurface>
      </StickyArea>
    )
  );
};

const FilterCardSurface = styled(Surface)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
  overflow-y: auto;
`;

const StaffChooseLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChooseLocationButton = styled(Button)`
  width: fit-content;
  margin-bottom: 0;
`;

export default LicenseeOptions;
