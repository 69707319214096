import {
  Button,
  TextFieldType,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ToggleButtons from './ToggleButtons';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import {
  isOperatorConsultant,
  selectAllLocationsWithAtLeastTrainer,
  selectUserIsLicenseeStaff,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { setShowLicenseeLocationPicker } from '@/store/licenseeLocationPicker/slice';
import SearchFilterHeader from '@/components/SearchFilterHeader/SearchFilterHeader';
import {
  addTeamMembersLocationFilter,
  clearTeamMembersSearchFilter,
  removeTeamMembersFilter,
  setTeamMembersSearchFilter,
  setTeamMembersSort,
} from '@/store/teamMembersFilter/slice';
import {
  selectFilters,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from '@/store/teamMembersFilter/selector';
import FilterAndSortButton from '@/components/FilterAndSortButton/FilterAndSortButton';
import { useSortOptions } from '@/hooks/useSortOptions';
import { TeamMemberWithPlans } from '@/types/types';
import CheckboxFilterSection from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';

interface FilterAndSortTeamMembersMobileProps {
  isFetching: boolean;
}

const FilterAndSortTeamMembersMobile: React.FC<
  FilterAndSortTeamMembersMobileProps
> = ({ isFetching }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { licensee: licenseeFeatureFlag } = useFlags();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const sortOptions = useSortOptions({ reportsTeamMembersTab: true });
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const searchFilter: string = useSelector(selectSearchFilter);
  const currentFilters: string[] = useSelector(selectFilters);
  const sort: string = useSelector(selectSort);
  const teamMembers: TeamMemberWithPlans[] = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const locations: string[] = useSelector(selectAllLocationsWithAtLeastTrainer);

  return (
    <>
      {!!isSmAndDown && isOperatorConsultantUser && licenseeFeatureFlag && (
        <ToggleButtons />
      )}
      {!!userIsLicenseeStaff && !!isSmAndDown && licenseeFeatureFlag && (
        <Button
          color="secondary"
          onClick={() => {
            dispatch(setShowLicenseeLocationPicker());
          }}
          size="lg"
          style={{ marginTop: 16 }}
          variant="outlined"
        >
          {activeLicenseeLocation?.number
            ? t('Licensee.changeLicenseeLocation', {
                locationNumber: activeLicenseeLocation?.number,
              })
            : t('Generic.chooseLicenseeLocation')}
        </Button>
      )}
      <SearchFilterHeader
        hideTitle={
          ((isLicenseePlans && isOperatorConsultantUser) ||
            userIsLicenseeStaff) &&
          !!isSmAndDown
        }
        onChange={(e: React.ChangeEvent<TextFieldType>) =>
          dispatch(setTeamMembersSearchFilter({ searchFilter: e.target.value }))
        }
        onClear={() => dispatch(clearTeamMembersSearchFilter())}
        searchPlaceholder={t('TrainingPlans.teamMembers.searchbarPlaceholder')}
        searchValue={searchFilter}
        title={t('Reports.tabTeamMembers')}
      />
      {!!isSmAndDown && !isFetching && (
        <FilterAndSortButton
          onSortChange={option => {
            dispatch(setTeamMembersSort({ sort: option.value }));
          }}
          sortOptions={sortOptions}
          sortValue={sortOptions?.find(option => option.value === sort)}
          text={`${t('TrainingPlans.filtering.show')} ${
            teamMembers?.length ?? 0
          } ${t('TrainingPlans.filtering.results')}`}
        >
          {locations && locations?.length > 1 && (
            <CheckboxFilterSection
              labels={locations.reduce(
                (acc, loc) => ({
                  ...acc,
                  [loc]: {
                    translationString: loc,
                    value: !!currentFilters.includes(loc),
                  },
                }),
                {},
              )}
              onChange={(value: string) => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeTeamMembersFilter({ filter: value }));
                } else {
                  dispatch(addTeamMembersLocationFilter({ filter: value }));
                }
              }}
              title={t('Generic.locations')}
            />
          )}
        </FilterAndSortButton>
      )}
    </>
  );
};

export default FilterAndSortTeamMembersMobile;
