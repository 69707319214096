import Constants from 'constants/index';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextFieldType,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import {
  selectFilters,
  selectSearchFilter,
} from 'store/managePlansFilter/selector';
import { useGetReportsPlansAssignableQuery } from 'services/pathwayApi';
import {
  clearManagePlansSearchFilter,
  setManagePlans,
  setManagePlansSearchFilter,
} from 'store/managePlansFilter/slice';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  isUserLicensee,
  isOperatorConsultant,
  selectUserLicenseeLocations,
  selectUserIsLicenseeStaff,
} from 'store/user/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FilterAndSortMobile from './FilterAndSortMobile';
import LicenseeOptions from './LicenseeOptions';
import LicenseeOptionsMobile from './LicenseeOptionsMobile';
import PlansList from './PlansList';
import PrintReport from './PrintReport';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import { getNameFromLanguage } from '@/utils/language';
import SearchFilterHeader from '@/components/SearchFilterHeader/SearchFilterHeader';
import { setHeader } from '@/store/header/slice';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { LanguageObject, ReportToPrint } from '@/types/types';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';

const ReportsPlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { xpApi: xpApiFeatureFlag, licensee: licenseeFeatureFlag } = useFlags();
  const currentFilters: string[] = useSelector(selectFilters);
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);
  const isLicenseeUser = useSelector(isUserLicensee);
  const [reportToPrint, setReportToPrint] = useState<ReportToPrint>();
  const licenseeLocations = isLicenseePlans
    ? userLicenseeLocations?.map(location => location.number) ?? []
    : undefined;
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const searchFilter = useSelector(selectSearchFilter) ?? '';
  const categoryLabels = useCategoryLabels(currentFilters);

  if (isLicenseeUser || isLicenseePlans || userIsLicenseeStaff) {
    delete categoryLabels?.[Constants.PLAN_CATEGORIES.COMPLIANCE];
  }

  const getPlanLocations = () => {
    if (userIsLicenseeStaff || isLicenseeUser) {
      return activeLicenseeLocation?.number
        ? [activeLicenseeLocation?.number]
        : [];
    }
    if (isLicenseePlans) {
      return licenseeLocations as string[];
    }
    return locationsWithAtLeastLeaderPermission;
  };

  const planLocations = getPlanLocations();

  const {
    data: oldUnOrderedPlans,
    isFetching: isFetchingPlansOld,
    isSuccess: isSuccessPlansOld,
  } = useGetReportsPlansAssignableQuery(
    {
      locations: planLocations,
    },
    {
      skip:
        !!xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpUnOrderedPlans,
    isFetching: isFetchingPlansXp,
    isSuccess: isSuccessPlansXp,
  } = useGetReportsPlansAssignableQuery(
    //TODO proxy does not exist yet, replace with proxy EP when available P-606
    {
      locations: planLocations,
    },
    {
      skip:
        !xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
      refetchOnMountOrArgChange: true,
    },
  );

  const isSuccess: boolean = xpApiFeatureFlag
    ? isSuccessPlansXp
    : isSuccessPlansOld;

  const isFetching: boolean = xpApiFeatureFlag
    ? isFetchingPlansXp
    : isFetchingPlansOld;

  const unOrderedPlans = xpApiFeatureFlag
    ? xpUnOrderedPlans
    : oldUnOrderedPlans;

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Set Plans
  useEffect(() => {
    if (isSuccess) {
      const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
        return {
          ...plan,
          planName: getNameFromLanguage(
            plan?.checklist?.name as LanguageObject,
          ).toLowerCase(),
        };
      });
      if (isLicenseeUser || isLicenseePlans) {
        dispatch(setManagePlans({ plans: storePlansWithName ?? [] }));
      } else {
        dispatch(
          setManagePlans({
            plans:
              storePlansWithName?.concat(unOrderedPlans?.courses as any) ?? [],
          }),
        );
      }
    }
  }, [dispatch, isLicenseePlans, isLicenseeUser, isSuccess, unOrderedPlans]);

  const handlePrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }: {
    category: string;
    id: string;
    isCompliancePlan: boolean;
    locations: string[];
    planName: string;
  }) => {
    setReportToPrint({
      category,
      id,
      isCompliancePlan,
      locations,
      planName,
    });
  };

  return (
    <>
      <StyledContent>
        <LicenseeOptionsMobile />
        <SearchFilterHeader
          hideTitle={
            (isOperatorConsultantUser || userIsLicenseeStaff) &&
            licenseeFeatureFlag &&
            !!isSmAndDown
          }
          onChange={(e: React.ChangeEvent<TextFieldType>) =>
            dispatch(
              setManagePlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearManagePlansSearchFilter())}
          searchPlaceholder={t('Reports.filtering.searchPlans')}
          searchValue={searchFilter}
          title={t('Reports.tabPlans')}
        />
        <FilterAndSortMobile isFetching={isFetching} />
        <PlansListWrapper>
          <LicenseeOptions isFetching={isFetching} />
          <PlansList
            isFetching={isFetching}
            onPrintReport={({
              category,
              id,
              isCompliancePlan,
              locations,
              planName,
            }) =>
              handlePrintReport({
                category,
                id,
                isCompliancePlan,
                locations,
                planName,
              })
            }
          />
        </PlansListWrapper>
      </StyledContent>
      <PrintReport
        reportToPrint={reportToPrint as ReportToPrint}
        turnOffPrintTrigger={() =>
          setReportToPrint({
            category: '',
            id: '',
            isCompliancePlan: false,
            locations: [],
            planName: '',
          })
        }
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

export default withRoles(
  ReportsPlansTab,
  [],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
