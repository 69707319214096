import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import { isApiError } from 'utils/request';
import {
  generateTotalTime,
  getHrsFromTotalMins,
  getMinsFromTotalMins,
  getTimeSpentOnPlanForTeamMember,
} from 'utils/time';
import store from 'store';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useGetAssignedChecklistsQuery,
  useUpdateChecklistStatusMutation,
  useUpdateChecklistStepStatusMutation,
} from 'services/pathwayApi';
import { useEffect, useState } from 'react';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUser,
  selectUserId,
  selectUserLanguage,
} from 'store/user/selectors';
import { Button, Tooltip, Typography } from 'cfa-react-components';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import {
  ChecklistSectionStepDTO,
  ChecklistStatusUpdate,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
// @ts-ignore
import PlanItemCard from '../SharedComponents/PlansItemCards/PlanItemCard';
// @ts-ignore
import PlanItemTask from '../SharedComponents/PlansItemCards/PlanItemTask';
// @ts-ignore
import PlanItemProcedure from '../SharedComponents/PlansItemCards/PlanItemProcedure';
// @ts-ignore
import PlanItemQuiz from '../SharedComponents/PlansItemCards/PlanItemQuiz';
// @ts-ignore
import PlanItemBrightcoveVideo from '../SharedComponents/PlansItemCards/PlanItemBrightcoveVideo';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import GenericError from '@/components/Error/GenericError';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import PageNotFound404 from '@/pages/404/404';
import useTrainingPlanTimer from '@/hooks/useTrainingPlanTimer';
import useDocumentCookieRefresh from '@/hooks/useDocumentCookieRefresh';
import { Error, LanguageObject } from '@/types/types';

const AssignedPlanView = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { planId } = useParams<{ planId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();
  const userId = useSelector(selectUserId);
  const userLanguage = useSelector(selectUserLanguage);
  const state = store.getState();
  const user = selectUser(state);
  const {
    data: plans,
    error,
    refetch: refetchPlan,
    // @ts-ignore
  } = useGetAssignedChecklistsQuery({}, { refetchOnMountOrArgChange: true });

  const [updateChecklistStepStatus] = useUpdateChecklistStepStatusMutation();
  const [updateChecklistStatus] = useUpdateChecklistStatusMutation();
  const [plan, setPlan] = useState<UserChecklistResultDTO>();
  const [activeTask, setActiveTask] = useState('');
  const [activeQuizStepId, setActiveQuizStepId] = useState('');
  const [users, setUsers] = useState<string[]>();
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    if (plans) {
      setPlan(
        plans?.checklistResults?.find(
          tempPlan => tempPlan?.checklist?.id === planId,
        ),
      );
    }
  }, [plans, planId]);

  const timeSpentOnPlan = getTimeSpentOnPlanForTeamMember(
    (plan as any)?.status,
    user.userId,
  );
  const timeSpentHrs = getHrsFromTotalMins(timeSpentOnPlan);
  const timeSpentMins = getMinsFromTotalMins(timeSpentOnPlan);

  const isStepCompleted = (stepId: string) => {
    if (!stepId) {
      return false;
    }
    return (
      plan?.status &&
      plan.status
        .filter(status => status.userId === user.userId)
        .every(
          planAssigned =>
            planAssigned?.steps?.find(step => step.stepId === stepId)
              ?.status === Constants.TRAINING_PLANS.COMPLETE,
        )
    );
  };

  const getStepStatus = (status: boolean) => {
    return status
      ? Constants.TRAINING_PLANS.COMPLETE
      : Constants.TRAINING_PLANS.INCOMPLETE;
  };

  useEffect(() => {
    if (plan) {
      dispatch(
        setHeader(getNameFromLanguage(plan?.checklist?.name as LanguageObject)),
      );
    }
  }, [plan, dispatch]);

  useEffect(() => {
    if (plan?.checklist?.category) {
      dispatch(
        setHeaderLabel(
          t(mapCategoryToAliasTranslation(plan?.checklist?.category)),
        ),
      );
      return () => {
        dispatch(setHeaderLabel(''));
      };
    }
  }, [plan, dispatch, t]);

  useEffect(() => {
    if (plan?.checklist?.estimatedMinutes) {
      dispatch(
        setHeaderSubtext(
          `${t(
            'TrainingPlans.accountability.estimatedTimeToComplete',
          )}: ${generateTotalTime(
            plan.checklist.estimatedMinutes,
            t('Generic.hour'),
            t('Generic.mins'),
          )}`,
        ),
      );
    }
    return () => {
      dispatch(setHeaderSubtext(''));
    };
  }, [plan, dispatch, t]);

  const [startTaskTimer, stopTaskTimer] = useTrainingPlanTimer(
    planId,
    activeTask,
    userId,
    getStepStatus(isStepCompleted(activeTask) as boolean),
  );

  const [startQuizTimer, stopQuizTimer] = useTrainingPlanTimer(
    planId,
    activeQuizStepId,
    userId,
    getStepStatus(isStepCompleted(activeQuizStepId) as boolean),
  );

  useEffect(() => {
    if (activeQuizStepId !== '' && isStepCompleted(activeQuizStepId)) {
      setActiveQuizStepId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId, plan]);

  // timer for tasks
  useEffect(() => {
    if (activeTask !== '') {
      startTaskTimer?.();
    } else {
      stopTaskTimer?.();
    }
    return () => {
      stopTaskTimer?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTask]);

  // quiz timer
  useEffect(() => {
    if (activeQuizStepId !== '') {
      startQuizTimer?.();
    } else {
      stopQuizTimer?.();
    }
    return () => {
      stopQuizTimer?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId]);

  useEffect(() => {
    setUsers([user.userId]);
  }, [user]);

  //Error Handling
  if (
    !!plans?.checklistResults &&
    !plans?.checklistResults?.find(
      tempPlan => tempPlan?.checklist?.id === planId,
    )
  ) {
    Constants.BUGSNAG_ENABLED && notifyBugsnag(error);
    return <PageNotFound404 />;
  }
  if (isApiError(error)) {
    if ((error as Error)?.status === 404) {
      Constants.BUGSNAG_ENABLED && notifyBugsnag(error);
      return <PageNotFound404 />;
    } else {
      Constants.BUGSNAG_ENABLED && notifyBugsnag(error);
      return <GenericError />;
    }
  }

  const isPlanComplete =
    plan?.status?.[0]?.status === Constants.TRAINING_PLANS.COMPLETED;

  const handleSetActiveTask = (step: ChecklistSectionStepDTO) => {
    activeTask === step.id
      ? setActiveTask('')
      : setActiveTask(step.id as string);
  };

  const handleSetActiveQuiz = (step: ChecklistSectionStepDTO) => {
    activeQuizStepId === step.id
      ? setActiveQuizStepId('')
      : setActiveQuizStepId(step.id as string);
  };

  const handleSetActiveVideo = (step: ChecklistSectionStepDTO) => {
    activeTask === step.id
      ? setActiveTask('')
      : setActiveTask(step.id as string);
  };

  const onCardClick = (step: ChecklistSectionStepDTO) => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/assigned/toComplete`,
      state: {
        step,
        planId,
        stepStatus: getStepStatus(
          isStepCompleted(step.id as string) as boolean,
        ),
        isViewingFromTrainingPlan: true,
      },
    });
  };

  const onOvalIconToggle = (id: string) => {
    const isCompleted = !isStepCompleted(id);
    const stepStatus = getStepStatus(isCompleted);
    if (!isPlanComplete) {
      if (id === activeTask) {
        setActiveTask('');
      }
      updateChecklistStepStatus({
        checklistId: planId,
        stepId: id,
        // @ts-ignore
        status: stepStatus,
      })
        .unwrap()
        .then(() => {
          refetchPlan();
        })
        .catch(err => {
          notifyBugsnag(err);
        });
    }
  };

  const onCompleteButtonClick = (planName: LanguageObject) => {
    updateChecklistStatus({
      checklist: planId,
      status: Constants.TRAINING_PLANS
        .COMPLETED as ChecklistStatusUpdate['status'],
    })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
          state: { completedPlan: getNameFromLanguage(planName) },
        });
      })
      .catch(err => {
        setApiError(err);
        notifyBugsnag(err);
      });
  };
  const renderCompleteButton = (planName: LanguageObject) => (
    <StyledButtonWrapper>
      <Button
        color={'secondary'}
        data-testid="CompleteButton"
        disabled={!areAllStepsComplete() || isPlanComplete}
        onClick={() => onCompleteButtonClick(planName)}
        variant="filled"
      >
        {t('Button.complete')}
      </Button>
    </StyledButtonWrapper>
  );

  const areAllStepsComplete = () => {
    const stepsFromChecklist =
      plan?.checklist?.sections &&
      plan?.checklist.sections
        .flatMap(section => section?.steps?.filter(step => step.available))
        .concat(
          plan?.checklist?.steps &&
            plan?.checklist?.steps.filter(step => step.available),
        );
    const stepsFromStatus = plan?.status?.filter(
      status => status.userId === user.userId,
    )?.[0]?.steps;
    const matchedSteps = stepsFromStatus?.filter(
      step =>
        stepsFromChecklist &&
        stepsFromChecklist.map(it => it && it.id).includes(step.stepId),
    );

    return (
      matchedSteps?.every(
        step => step.status === Constants.TRAINING_PLANS.COMPLETE,
      ) && matchedSteps.length === stepsFromChecklist?.length
    );
  };

  return (
    <>
      {plan && (
        <AssignedPlanHeader>
          <TimeSpentContainer>
            <TypeHeader variant="overline3">
              {isPlanComplete
                ? t('Generic.planCompletedIn')
                : t('TrainingPlans.accountability.timeSpentOnPlan')}
            </TypeHeader>
            <Tooltip
              content={t('TrainingPlans.timeSpentOnPlanTooltip')}
              placement="bottom"
              showOnElementEvents={['hover']}
            >
              <TimeSpentText>{` ${
                timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
              } ${timeSpentMins} ${t('Generic.mins')}`}</TimeSpentText>
            </Tooltip>
          </TimeSpentContainer>
        </AssignedPlanHeader>
      )}
      {isDocumentCookieSuccess &&
        plan?.checklist?.steps &&
        plan?.checklist?.steps.map((step, index) => {
          switch (step?.type) {
            case Constants.STEP_TYPES.QUIZ:
              return (
                <PlanItemCard key={index}>
                  <PlanItemQuiz
                    disabled={isPlanComplete}
                    isActive={activeQuizStepId === step.id}
                    isComplete={isStepCompleted(step.id as string)}
                    language={userLanguage}
                    onClick={() => handleSetActiveQuiz(step)}
                    onRefetch={refetchPlan}
                    planId={plan?.checklist?.id}
                    statuses={plan?.status}
                    step={step}
                    users={users}
                  />
                </PlanItemCard>
              );
            case Constants.STEP_TYPES.TASK:
              return (
                <PlanItemCard key={index}>
                  <PlanItemTask
                    disabled={isPlanComplete}
                    isActive={activeTask === step.id}
                    isComplete={isStepCompleted(step.id as string)}
                    onClick={() => handleSetActiveTask(step)}
                    onCompleteToggle={() => onOvalIconToggle(step.id as string)}
                    statuses={plan?.status}
                    step={step}
                    user={user}
                  />
                </PlanItemCard>
              );
            case Constants.STEP_TYPES.DOCUMENT:
              return step?.reference?.type?.toLowerCase() ===
                Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
                <PlanItemCard key={index}>
                  <PlanItemBrightcoveVideo
                    disabled={isPlanComplete}
                    isActive={activeTask === step.id}
                    isComplete={isStepCompleted(step.id as string)}
                    language={userLanguage}
                    onClick={() => handleSetActiveVideo(step)}
                    onCompleteToggle={() => onOvalIconToggle(step.id as string)}
                    planId={plan?.checklist?.id}
                    statuses={plan?.status}
                    step={step}
                    users={users}
                  />
                </PlanItemCard>
              ) : (
                <PlanItemCard key={index}>
                  <PlanItemProcedure
                    disabled={isPlanComplete}
                    isActive={activeTask === step.id}
                    isComplete={isStepCompleted(step.id as string)}
                    isMissing={!step?.available}
                    onClick={() => onCardClick(step)}
                    onCompleteToggle={() => onOvalIconToggle(step.id as string)}
                    statuses={plan?.status}
                    step={step}
                    user={user}
                  />
                </PlanItemCard>
              );
            default:
              return null;
          }
        })}
      {plan?.checklist?.sections &&
        plan?.checklist?.sections.map(section => {
          return (
            !!section?.steps &&
            !!section.steps.length && (
              <PlanItemCard
                disabled
                key={section?.id}
                sectionName={getNameFromLanguage(
                  section.name as LanguageObject,
                )}
              >
                {section.steps.map((step, stepIndex) => {
                  switch (step?.type) {
                    case Constants.STEP_TYPES.QUIZ:
                      return (
                        <PlanItemQuiz
                          disabled={isPlanComplete}
                          isActive={activeQuizStepId === step.id}
                          isComplete={isStepCompleted(step.id as string)}
                          key={stepIndex}
                          language={userLanguage}
                          onClick={() => handleSetActiveQuiz(step)}
                          onRefetch={refetchPlan}
                          planId={plan?.checklist?.id}
                          statuses={plan?.status}
                          step={step}
                          users={users}
                        />
                      );
                    case Constants.STEP_TYPES.TASK:
                      return (
                        <PlanItemTask
                          disabled={isPlanComplete}
                          isActive={activeTask === step.id}
                          isComplete={isStepCompleted(step.id as string)}
                          key={stepIndex}
                          onClick={() => handleSetActiveTask(step)}
                          onCompleteToggle={() =>
                            onOvalIconToggle(step.id as string)
                          }
                          statuses={plan?.status}
                          step={step}
                          user={user}
                        />
                      );
                    case Constants.STEP_TYPES.DOCUMENT:
                      return step?.reference?.type?.toLowerCase() ===
                        Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
                        <PlanItemBrightcoveVideo
                          disabled={isPlanComplete}
                          isActive={activeTask === step.id}
                          isComplete={isStepCompleted(step.id as string)}
                          key={stepIndex}
                          language={userLanguage}
                          onClick={() => handleSetActiveVideo(step)}
                          onCompleteToggle={() =>
                            onOvalIconToggle(step.id as string)
                          }
                          planId={plan?.checklist?.id}
                          statuses={plan?.status}
                          step={step}
                          users={users}
                        />
                      ) : (
                        <PlanItemProcedure
                          disabled={isPlanComplete}
                          isActive={activeTask === step.id}
                          isComplete={isStepCompleted(step.id as string)}
                          isMissing={!step?.available}
                          key={stepIndex}
                          onClick={() => onCardClick(step)}
                          onCompleteToggle={() =>
                            onOvalIconToggle(step.id as string)
                          }
                          statuses={plan?.status}
                          step={step}
                          user={user}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </PlanItemCard>
            )
          );
        })}
      {plan?.checklist &&
        renderCompleteButton(plan.checklist.name as LanguageObject)}
      <ConfirmationModal
        bodyText={t('GenericError.problemCompletingAction')}
        headerText={t('GenericError.error')}
        isOpen={!!apiError}
        onClose={() => setApiError(null)}
        primaryButtonColor="primary"
        primaryButtonHandler={() => setApiError(null)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`;

const AssignedPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px 0;
`;

const TimeSpentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;
const TypeHeader = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
`;
const TimeSpentText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export default withRoles(
  AssignedPlanView,
  [],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
