import { FC, PropsWithChildren } from 'react';
import { DocumentTitle } from '..';
import {
  DocumentImage,
  DocumentImageOuterCircle,
  DocumentImageWrapper,
  MobileImageWrapper,
  PrintTitle,
} from '../wrappers';
import { useAppSelector } from '@/hooks';
import Constants from '@/constants';
import { useIsMobile } from '@/utils/document-utils';

interface TridionTitleProps {
  tags: string[];
  icon?: string | null;
  title: string;
}

export const TridionTitle: FC<TridionTitleProps> = ({ tags, icon, title }) => {
  const isPrinting = useAppSelector(
    state => state.documentTransient.isPrinting,
  );
  const isMobile = useIsMobile();

  if (isPrinting) {
    return <PrintTitle>{title}</PrintTitle>;
  }

  const MobileWrapper: FC<PropsWithChildren> = ({ children }) =>
    isMobile ? <MobileImageWrapper>{children}</MobileImageWrapper> : children;

  return (
    <>
      <DocumentTitle isMobile={isMobile} tags={tags} title={title} />
      <MobileWrapper>
        <DocumentImageWrapper $isMobile={isMobile}>
          <DocumentImageOuterCircle>
            <DocumentImage
              alt="document icon"
              src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
                icon === null || icon === undefined || icon === 'placeholder'
                  ? 'Chickfila'
                  : icon
              }.svg`}
            />
          </DocumentImageOuterCircle>
        </DocumentImageWrapper>
      </MobileWrapper>
    </>
  );
};
