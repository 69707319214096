import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';
import { IconAlertTriangleFilled, IconPrinter } from '@tabler/icons-react';
import styled from 'styled-components';
import { Tooltip } from 'cfa-react-components';
import {
  useGetAssignedCoursesQuery,
  useGetTeamMembersQuery,
} from 'services/pathwayApi';
import { useGetUsersForLocationsQuery } from 'services/xpApi';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ComplianceCourseEnrollmentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCardContent from '../PlanCardContent/PlanCardContent';
import PlanCard from '../PlanCard';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import { ComplianceCourseWithUserId } from './ManagePlansComplianceCard';
import { Report } from './ReportsPlanCard';
import { getNameFromLanguage } from '@/utils/language';
import { ComplianceCourse } from '@/types/types';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';

interface ReportsCompliancePlanCardProps {
  course: ComplianceCourse;
  enrollments: ComplianceCourseEnrollmentDTO[];
  locations: string[];
  onPrintReport: (report: Report) => void;
}

const ReportsCompliancePlanCard: React.FC<ReportsCompliancePlanCardProps> = ({
  course,
  enrollments,
  locations,
  onPrintReport,
}) => {
  const { t } = useTranslation();
  const { licensee: licenseeFeatureFlag } = useFlags();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const [totalTeamMembers, setTotalTeamMembers] = useState<number>();

  const { data: oldAllTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { skip: !!licenseeFeatureFlag, refetchOnMountOrArgChange: true },
  );

  const { data: xpAllTeamMembersData } = useGetUsersForLocationsQuery(
    { locations: locationsWithAtLeastTrainer },
    { skip: !licenseeFeatureFlag },
  );

  const { data: complianceStatus, isSuccess: isAssignedCoursesSuccess } =
    useGetAssignedCoursesQuery({
      courseId: course.id as string,
      location: locationsWithAtLeastTrainer,
    });

  const data = licenseeFeatureFlag
    ? xpAllTeamMembersData
    : oldAllTeamMembersData;

  useEffect(() => {
    if (
      isAssignedCoursesSuccess &&
      complianceStatus?.enrollments !== undefined
    ) {
      setTotalTeamMembers(
        data
          ?.map(teamMember => {
            return {
              userId:
                (
                  complianceStatus?.enrollments?.[
                    teamMember?.adId as string
                  ] as ComplianceCourseWithUserId
                )?.userId ?? teamMember.adId,
              hidden:
                complianceStatus?.enrollments?.[teamMember?.adId as string]
                  ?.hidden ?? false,
            };
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                user => user.userId === value.userId && !user.hidden,
              ),
          ).length,
      );
    }
  }, [complianceStatus, data, isAssignedCoursesSuccess]);

  const completedUsers = Object.values(enrollments)?.filter(
    enrollment =>
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED,
  ).length;
  const allUsersCompleted =
    !!totalTeamMembers && completedUsers === totalTeamMembers;
  const percentComplete =
    totalTeamMembers && (completedUsers / totalTeamMembers) * 100;
  return (
    <>
      {!!course && (
        <>
          <PlanCard
            buttons={
              <StyledPrinterIcon
                onClick={e => {
                  e.preventDefault();
                  onPrintReport({
                    category: Constants.PLAN_CATEGORIES.COMPLIANCE,
                    id: course?.id as string,
                    locations: locations,
                  });
                }}
              />
            }
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            dataTestId={'ReportsCompliancePlanCard'}
            disabled={!course?.enabled}
            link={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${course?.id}?compliancePlan="true"`}
            progress={allUsersCompleted ? null : percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              {allUsersCompleted ? (
                <PlanCardSubHeader
                  className="success"
                  dataTestId="PlanTaskCount"
                >
                  {t('Generic.completedByAll')}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader dataTestId="PlanTaskCount">
                  {totalTeamMembers ? (
                    <>
                      <Tooltip
                        content={t(
                          'TrainingPlans.accountability.outOfCompliance',
                        )}
                        placement="bottom"
                      >
                        <WarningIconAlertTriangleFilled
                          style={{ marginRight: '4px' }}
                        />
                      </Tooltip>
                      {`${completedUsers}/${totalTeamMembers} ${t(
                        'Generic.teamMembersCompleted',
                      )}`}
                    </>
                  ) : (
                    ` ${t('Generic.loading')}`
                  )}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
          </PlanCard>
        </>
      )}
    </>
  );
};

const StyledPrinterIcon = styled(IconPrinter)`
  pointer-events: auto;
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const WarningIconAlertTriangleFilled = styled(IconAlertTriangleFilled)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
  color: ${({ theme }) => theme.semanticColors.warning};
`;

export default ReportsCompliancePlanCard;
