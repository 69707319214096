import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Breadcrumb } from '@/types/types';
import { useAppSelector } from '@/hooks';
import { selectPersistDocumentState } from '@/store/document-persisted/selectors';

export const useBreadcrumbs = (
  goBack: (isSearch?: boolean) => void,
  isFromSearch: boolean,
  resourceIsSuccess: boolean,
) => {
  const { t } = useTranslation();
  const { categoryName, subcategoryName, backUrl } = useAppSelector(
    selectPersistDocumentState,
  );

  const breadcrumbs: Breadcrumb[] = useMemo(
    () => [
      {
        label: t('Generic.explore'),
        to: '/',
      },
      {
        label: categoryName,
        to: backUrl,
        onClick: !backUrl ? () => goBack() : undefined,
      },
      {
        label: subcategoryName,
        to: backUrl,
        onClick: !backUrl ? () => goBack() : undefined,
      },
      // Design wants the chevron right icon to show at the end without a label
      {
        label: '',
      },
    ],
    [categoryName, subcategoryName, goBack, backUrl, t],
  );

  const breadcrumbsFromSearch: Breadcrumb[] = useMemo(
    () => [
      {
        label: t('Generic.search'),
        onClick: () => goBack(true),
      },
      // Design wants the chevron right icon to show at the end without a label
      {
        label: '',
      },
    ],
    [goBack, t],
  );

  const breadcrumbsFromlink: Breadcrumb[] = useMemo(
    () => [
      {
        label: t('Generic.explore'),
        to: '/',
      },
      // Design wants the chevron right icon to show at the end without a label
      {
        label: '',
      },
    ],
    [t],
  );

  return useMemo(
    () =>
      isFromSearch
        ? breadcrumbsFromSearch
        : resourceIsSuccess
        ? breadcrumbsFromlink
        : breadcrumbs,
    [
      breadcrumbs,
      breadcrumbsFromSearch,
      breadcrumbsFromlink,
      isFromSearch,
      resourceIsSuccess,
    ],
  );
};
