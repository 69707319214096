import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import {
  isOperatorConsultant,
  selectUserIsLicenseeStaff,
} from '@/store/user/selectors';
import {
  setTogglePlansLicensee,
  setTogglePlansMyRestaurants,
} from '@/store/licensee/slice';
import { resetManagePlans } from '@/store/managePlansFilter/slice';
import { setShowLicenseeLocationPicker } from '@/store/licenseeLocationPicker/slice';

const LicenseeOptionsMobile = () => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { licensee: licenseeFeatureFlag } = useFlags();
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);

  const toggleMyRestaurants = () => {
    dispatch(setTogglePlansMyRestaurants());
    dispatch(resetManagePlans());
  };

  const toggleLicensee = () => {
    dispatch(setTogglePlansLicensee());
    dispatch(resetManagePlans());
  };

  return (
    <>
      {!!isSmAndDown && isOperatorConsultantUser && licenseeFeatureFlag && (
        <ToggleButtonGroup
          fullWidth
          orientation="horizontal"
          size="sm"
          value={isLicenseePlans}
        >
          <ToggleButton
            aria-label="my-restaurants"
            onClick={toggleMyRestaurants}
            value={false}
          >
            <Typography
              fontWeight={isLicenseePlans ? 'regular' : 'bold'}
              variant="body1"
            >
              {t('Licensee.myRestaurants')}
            </Typography>
          </ToggleButton>
          <ToggleButton
            aria-label="licensees"
            onClick={toggleLicensee}
            value={true}
          >
            <Typography
              fontWeight={isLicenseePlans ? 'bold' : 'regular'}
              variant="body1"
            >
              {t('Licensee.licensees')}
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {!!userIsLicenseeStaff && !!isSmAndDown && licenseeFeatureFlag && (
        <Button
          color="secondary"
          onClick={() => {
            dispatch(setShowLicenseeLocationPicker());
          }}
          size="lg"
          style={{ marginTop: 16 }}
          variant="outlined"
        >
          {activeLicenseeLocation?.number
            ? t('Licensee.changeLicenseeLocation', {
                locationNumber: activeLicenseeLocation?.number,
              })
            : t('Generic.chooseLicenseeLocation')}
        </Button>
      )}
    </>
  );
};

export default LicenseeOptionsMobile;
