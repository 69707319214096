import { getNameFromLanguage } from 'utils/language';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { Typography } from 'cfa-react-components';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import { ChecklistSectionDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import SixDotIcon from './SixDotIcon';
import EditButtons from './EditButtons';
import EditSectionName from './EditSectionName';
import { LanguageObject } from '@/types/types';

export interface SectionTitleProps {
  id: string;
  name: LanguageObject;
}

interface TitleProps {
  deleteSectionPopUp: (sectionId: string, sectionName: string) => void;
  editSectionTitle: any;
  index: number;
  isAllowedToEdit: boolean;
  isPreview?: boolean;
  onCancel: () => void;
  onEdit: (title: string) => void;
  renameSectionPopUp: (name: string) => void | (() => void);
  section: ChecklistSectionDTO;
}

interface SectionTopRowStyleProps {
  $editMode: boolean;
}

const Title: React.FC<TitleProps> = ({
  section,
  isAllowedToEdit,
  editSectionTitle,
  renameSectionPopUp,
  deleteSectionPopUp,
  onCancel,
  onEdit,
  isPreview,
  index,
}) => {
  const threeDotRef = useRef(null);
  const { t } = useTranslation();
  const [updatedSectionTitle, setUpdatedSectionTitle] = useState('');

  return (
    <StyledSectionTopRowWrapper
      $editMode={editSectionTitle && editSectionTitle(section)}
    >
      {!isPreview && (
        <div className="section-drag-handle">
          <SixDotIcon position={editSectionTitle(section) ? 'top' : 'center'} />
        </div>
      )}
      <StyledTitleWrapper>
        <StyledTitle
          data-testid={`Title-${index}`}
          fontWeight="bold"
          variant="body1"
        >
          {(isPreview || !editSectionTitle(section)) &&
            getNameFromLanguage(section?.name as LanguageObject)}
          {!isPreview && isAllowedToEdit && editSectionTitle(section) && (
            <InlineTextEditContainer>
              {editSectionTitle(section) && (
                <EditSectionName
                  onEnter={() => {
                    if (updatedSectionTitle.trim().length > 0) {
                      onEdit(updatedSectionTitle);
                    }
                  }}
                  originalSectionTitle={getNameFromLanguage(
                    section?.name as LanguageObject,
                  )}
                  refSectionTitle={threeDotRef}
                  updateSectionTitle={updatedTitle =>
                    setUpdatedSectionTitle(updatedTitle)
                  }
                />
              )}
              {editSectionTitle(section) && (
                <EditButtons
                  onCancel={onCancel}
                  onEdit={() => onEdit(updatedSectionTitle)}
                  validated={updatedSectionTitle.trim().length > 0}
                />
              )}
            </InlineTextEditContainer>
          )}
        </StyledTitle>
      </StyledTitleWrapper>
      {editSectionTitle && !editSectionTitle(section) && !isPreview && (
        <PopoverMenuButton dataTestId="SectionMenu">
          <PopoverMenuButtonItem
            dataTestId="ThreeDotMenuPlanPopup"
            icon={<IconEdit />}
            onClick={() => {
              renameSectionPopUp(section.id as string);
            }}
            text={t('TrainingPlans.manageThreeDotMenu.editSection')}
          />
          <PopoverMenuButtonItem
            dataTestId="ThreeDotMenuPlanPopup"
            icon={<IconTrash data-testid="SectionDelete" />}
            isDestructive={true}
            onClick={() => {
              deleteSectionPopUp(
                section.id as string,
                getNameFromLanguage(section.name as LanguageObject),
              );
            }}
            text={t('TrainingPlans.manageThreeDotMenu.deleteSection')}
          />
        </PopoverMenuButton>
      )}
    </StyledSectionTopRowWrapper>
  );
};

const StyledSectionTopRowWrapper = styled.div<SectionTopRowStyleProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  align-items: ${({ $editMode }) => ($editMode ? 'flex-start' : 'center')};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledTitle = styled(Typography)`
  color: ${props => props.theme.grayScale.gray6};
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const InlineTextEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export default Title;
