import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';
import { IconPrinter } from '@tabler/icons-react';
import styled from 'styled-components';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCardContent from '../PlanCardContent/PlanCardContent';
import PlanCard from '../PlanCard';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';

export interface Report {
  category: string;
  id: string;
  locations: string[];
}

interface ReportsPlanCardProps {
  assignedUsers: number;
  completedUsers: number;
  locations: string[];
  onPrintReport: (report: Report) => void;
  plan: ChecklistDTO;
}

interface InlineTextStyleProps {
  $isSmAndDown: boolean;
}

const ReportsPlanCard: React.FC<ReportsPlanCardProps> = ({
  assignedUsers = 0,
  completedUsers = 0,
  locations,
  onPrintReport,
  plan,
}) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const percentComplete = (completedUsers / assignedUsers) * 100;

  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            buttons={
              <StyledPrinterIcon
                onClick={e => {
                  e.preventDefault();
                  onPrintReport({
                    category: plan?.category as string,
                    id: plan?.id as string,
                    locations: locations,
                  });
                }}
              />
            }
            category={plan?.category}
            dataTestId={'ReportsPlanCard'}
            link={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${plan?.id}`}
            progress={percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <InlineText $isSmAndDown={isSmAndDown}>
                <PlanCardTitle>
                  {getNameFromLanguage(plan?.name as LanguageObject)}
                </PlanCardTitle>
                <Typography variant="body2">
                  {t('Generic.created', {
                    createdDate: new Date(
                      plan?.created as string,
                    ).toLocaleDateString(),
                  })}
                </Typography>
              </InlineText>
              {!!assignedUsers && completedUsers === assignedUsers ? (
                <PlanCardSubHeader
                  className="success"
                  dataTestId="PlanTaskCount"
                >
                  {t('Generic.completedByAll')}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader dataTestId="PlanTaskCount">
                  {`${completedUsers}/${assignedUsers} ${t(
                    'Generic.teamMembersCompleted',
                  )}`}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
          </PlanCard>
        </>
      )}
    </>
  );
};

const StyledPrinterIcon = styled(IconPrinter)`
  pointer-events: auto;
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const InlineText = styled.div<InlineTextStyleProps>`
  display: flex;
  flex-direction: ${({ $isSmAndDown }) => ($isSmAndDown ? 'column' : 'row')};
  gap: ${({ $isSmAndDown }) => ($isSmAndDown ? '0px' : '8px')};
`;

export default ReportsPlanCard;
