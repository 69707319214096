import Constants from 'constants/index';
import styled from 'styled-components';
import { Card, Typography } from 'cfa-react-components';
import { LanguageObject } from '@/types/types';
import { getNameFromLanguage } from '@/utils/language';

interface CategoryCardProps {
  categoryId: string;
  categoryName: LanguageObject | string;
  categoryIcon: string;
  onClick?: (categoryId: string) => void;
  'data-testid'?: string;
  className?: string;
}

const CategoryCard = ({
  categoryId,
  categoryName,
  categoryIcon,
  onClick,
  'data-testid': dataTestId,
  className,
}: CategoryCardProps) => {
  const name =
    typeof categoryName === 'string'
      ? categoryName
      : getNameFromLanguage(categoryName);
  return (
    <CategoryCardContainer
      className={className}
      data-testid={dataTestId || `${categoryId}-card`}
      elevation={1}
      onClick={() => onClick?.(categoryId)}
    >
      <CategoryIcon
        alt={`${name} icon`}
        src={Constants.PATHWAY_CDN_IMG.CATEGORIES + categoryIcon + '.svg'}
      />
      <CategoryName variant="body1">{name}</CategoryName>
    </CategoryCardContainer>
  );
};

CategoryCard.defaultProps = {
  onClick: () => {},
  className: '',
  'data-testid': '',
};

const CategoryName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CategoryCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border: 1px solid #cccfd0;
`;

const CategoryIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

export default CategoryCard;
