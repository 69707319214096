import Constants from 'constants/index';
import { isApiError } from 'utils/request';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ChecklistDTO,
  Document,
} from '@cfacorp-pathway/xp-api-typescript-client';
import {
  IconArrowLeft,
  IconSquareRoundedCheckFilled,
  IconSquareRoundedPlus,
} from '@tabler/icons-react';
import {
  Button,
  Card,
  IconButton,
  LoadingIndicator,
  Modal,
  ModalBody,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import DocumentPreview from 'components/DocumentPreview/DocumentPreview';
import Searchbar from 'components/Searchbar/Searchbar';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import first from 'lodash/first';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import {
  useGetAdminCategoriesQuery,
  useGetAdminCategoryByIdQuery,
  useGetCategoriesQuery,
  useGetSearchResultsQuery,
  useGetSubcategoriesQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import { useGetSearchResultsQuery as xpAPIUseGetSearchResultsQuery } from 'services/xpApi';
import {
  addSearchResults,
  ReduxSearch,
  resetSearchState,
  setCurrentPage,
  setHasNextPage,
  setNumFound,
  setPageSize,
  setQueryId,
  setResult,
  setSearchResults,
  setShowingResults,
  setStoredQuery,
} from 'store/search/slice';
import { selectUserCountry } from 'store/user/selectors';
import { v4 as uuidv4 } from 'uuid';
import CategorySection from '@/pages/Explore/Operations/components/CategorySection';
import CategoryCard from '@/pages/Explore/Operations/components/CategoryCard';
import {
  getNameFromLanguage,
  itemByLanguage,
  pluralize,
} from '@/utils/language';
import { Error, LanguageObject } from '@/types/types';
import {
  selectCurrentPage,
  selectHasNextPage,
  selectNumFound,
  selectPageSize,
  selectQueryId,
  selectResult,
  selectSearchResults,
  selectShowingResults,
  selectStoredQuery,
} from '@/store/search/selectors';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import GenericError from '@/components/Error/GenericError';
import {
  BaseReferenceDTO,
  IntlCategoryEntity,
} from '@/services/content-api-types';
import { getCurrentLanguage } from '@/i18n/language';
import ScrollModalHeader from '@/components/ScrollModal/ScrollModalHeader';
import ScrollModalFooter from '@/components/ScrollModal/ScrollModalFooter';
import PageHeader from '@/components/PageHeader/PageHeader';
import SubcategoryAccordion from '@/pages/Explore/Operations/components/SubcategoryAccordion';
import SubcategoryDocument from '@/pages/Explore/Operations/components/SubcategoryDocument';

interface PopupDocument {
  id: string;
  type: string;
  reference: {
    id: string;
  };
  name: Record<string, string>;
}

interface AddProcedurePopUpProps {
  isOpen: boolean;
  onClose: () => void;
  plan: ChecklistDTO;
  refetch: () => void;
  isInSection?: boolean;
  sectionId?: string;
  onAdminAddProcedure?: (selectedDocuments: any[]) => void;
  adminAddingProcedure?: boolean;
  selectedCountryCode?: string; // country code value will only exist if imported from Admin component and is Admin user
}

const AddProcedurePopUp: FC<AddProcedurePopUpProps> = ({
  isOpen,
  onClose,
  plan,
  refetch,
  isInSection = false,
  sectionId = '',
  onAdminAddProcedure = () => {},
  adminAddingProcedure = false,
  selectedCountryCode,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const { data: categoriesData, error: categoriesError } =
    useGetCategoriesQuery(undefined, { skip: !!selectedCountryCode });
  const { data: adminCategoriesData, error: adminCategoriesError } =
    useGetAdminCategoriesQuery(
      { countryCode: selectedCountryCode as string },
      { skip: !selectedCountryCode }, // selectedCountryCode will only exist if imported from Admin component and is Admin user
    );

  const data = categoriesData || adminCategoriesData;
  const error = categoriesError || adminCategoriesError;

  const categories: IntlCategoryEntity[] = data?.categories ?? [];
  const featured: IntlCategoryEntity[] = data?.featured ?? [];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const modalBodyRef = useRef<any>();
  const [categorySelected, setCategorySelected] = useState<string | null>(null);
  const [activeIndexStep, setActiveIndexStep] = useState(1);
  const [stepWizard, setStepWizard] =
    useState<StepWizardChildProps<{ state: Record<string, any> }>>();
  const [selectedDocuments, setSelectedDocuments] = useState<PopupDocument[]>(
    [],
  );
  const [searchTermDisplayed, setSearchTermDisplayed] = useState('');
  const [searchTermQuery, setSearchTermQuery] = useState('');
  const [previewDocument, setPreviewDocument] = useState<
    Document | BaseReferenceDTO
  >({});

  const handleAddAdminProcedure = () => {
    onAdminAddProcedure(selectedDocuments);
    setSelectedDocuments([]);
  };

  useEffect(() => {
    dispatch(resetSearchState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTermQuery) {
      stepWizard?.goToStep(3);
    }
  }, [searchTermQuery, stepWizard]);
  const onAddProcedure = () => {
    const mappedUuidToSelectedDocuments = selectedDocuments.map(
      selectedDocument => {
        return { ...selectedDocument, id: uuidv4() };
      },
    );
    const newSteps = [...plan?.steps!, ...mappedUuidToSelectedDocuments];
    const payload = {
      locations: plan.locations,
      checklist: isInSection
        ? {
            ...plan,
            sections: [
              ...plan.sections!.map(section =>
                section.id === sectionId
                  ? {
                      ...section,
                      steps: [
                        ...section.steps!,
                        ...mappedUuidToSelectedDocuments,
                      ],
                    }
                  : section,
              ),
            ],
          }
        : { ...plan, steps: newSteps },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose();
        setSelectedDocuments([]);
      });
  };

  const onClearSearchClick = () => {
    setSearchTermDisplayed('');
    searchInputRef.current?.focus();
  };

  const onSearchInputChange = (e: React.ChangeEvent<TextFieldType>) => {
    const searchTerm = e.target.value;
    searchTerm.length
      ? setSearchTermDisplayed(searchTerm)
      : setSearchTermDisplayed('');
  };

  const onSearchTermSelect = (
    suggestion: string | null,
    spellingSuggestion?: string,
  ) => {
    setActiveIndexStep(2);
    if (spellingSuggestion) {
      setSearchTermDisplayed(spellingSuggestion);
      setSearchTermQuery(spellingSuggestion);
      return;
    }
    if (suggestion) {
      setSearchTermDisplayed(suggestion);
      setSearchTermQuery(suggestion);
      return;
    }
    const searchTerm = searchTermDisplayed?.trim();
    if (searchTerm?.length) {
      setSearchTermDisplayed(searchTerm);
      setSearchTermQuery(searchTerm);
    }
  };

  const currentCount = selectedDocuments.length;

  if (isApiError(error as Error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  const onBackButtonClick = () => {
    if (searchTermQuery && activeIndexStep === 3) {
      setSearchTermQuery('');
      setSearchTermDisplayed('');
      stepWizard?.goToStep(1);
      setActiveIndexStep(1);
      return;
    }
    if (searchTermQuery && activeIndexStep === 4) {
      stepWizard?.goToStep(3);
      setActiveIndexStep(3);
      return;
    }
    if (!searchTermQuery && activeIndexStep === 4) {
      stepWizard?.goToStep(2);
      setActiveIndexStep(2);
      return;
    }
    activeIndexStep > 1 ? stepWizard?.previousStep() : onClose();
    const step = stepWizard?.state?.activeStep + 1;
    setActiveIndexStep(step ?? 0);
  };

  const handleOnClose = () => {
    onClose();
    dispatch(resetSearchState());
    setSearchTermQuery('');
    setSearchTermDisplayed('');
    setSelectedDocuments([]);
  };

  const handleDocumentClick = (doc: Document | BaseReferenceDTO) => {
    setPreviewDocument(doc);
    stepWizard?.goToStep(4);
  };

  const isPreviewResource = activeIndexStep === 4;

  return (
    <Modal
      onClose={handleOnClose}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ScrollModalHeader
        scrollRef={modalBodyRef}
        showCloseButton={!isPreviewResource}
      >
        <ModalHeaderRow>
          {activeIndexStep > 1 ? (
            <IconButton data-testid="BackButton" onClick={onBackButtonClick}>
              <IconArrowLeft />
            </IconButton>
          ) : (
            <HeaderSpacer />
          )}
          {isPreviewResource ? (
            <PreviewHeader>
              <Typography variant="overline2">
                {t('TrainingPlans.preview')}
              </Typography>
              <>{previewDocument?.name}</>
            </PreviewHeader>
          ) : (
            t('Generic.chooseResources')
          )}
          <HeaderSpacer />
        </ModalHeaderRow>
        {!isPreviewResource && (
          <AddProcedureSearchbar
            elevation={1}
            fullWidth
            onChange={onSearchInputChange}
            onClear={onClearSearchClick}
            onSubmit={onSearchTermSelect}
            placeholder={t('TrainingPlans.addResource.placeholder')}
            ref={searchInputRef}
            searchValue={searchTermDisplayed}
            showRecentSearchDropdown
          />
        )}
      </ScrollModalHeader>
      <ModalBody ref={modalBodyRef}>
        <StyledProceduresWrapper $currentStep={activeIndexStep}>
          <StepWizard
            // type is broken in the package
            instance={(instance: any) => setStepWizard(instance)}
            isLazyMount
            onStepChange={({ activeStep }) => setActiveIndexStep(activeStep)}
            transitions={{
              enterRight: '',
              enterLeft: '',
              exitRight: '',
              exitLeft: '',
            }}
          >
            <Step1
              categories={categories}
              featured={featured}
              setCategorySelected={setCategorySelected}
              setSearchTermDisplayed={setSearchTermDisplayed}
              stepWizard={stepWizard}
            />
            <Step2
              categorySelected={categorySelected}
              onDocumentClick={handleDocumentClick}
              selectedCountryCode={selectedCountryCode}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
            <Step3
              handleDocumentClick={handleDocumentClick}
              onSearchTermSelect={onSearchTermSelect}
              searchTermQuery={searchTermQuery}
              selectedCountryCode={selectedCountryCode}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
            <Step4 previewDocument={previewDocument} />
          </StepWizard>
        </StyledProceduresWrapper>
      </ModalBody>
      {!isPreviewResource && (
        <ScrollModalFooter scrollRef={modalBodyRef}>
          <Button
            color="secondary"
            data-testid="addProcedureButton"
            disabled={currentCount === 0}
            onClick={
              adminAddingProcedure ? handleAddAdminProcedure : onAddProcedure
            }
          >
            {t('Button.addXObjects', {
              count: currentCount,
              object:
                currentCount === 1
                  ? t('Generic.resource')
                  : t('Generic.resources'),
            })}
          </Button>
        </ScrollModalFooter>
      )}
    </Modal>
  );
};

interface Step1Props {
  setCategorySelected: (category: string) => void;
  categories: IntlCategoryEntity[];
  featured: IntlCategoryEntity[];
  stepWizard: any;
  setSearchTermDisplayed: (term: string) => void;
}

const Step1: FC<Step1Props> = ({
  setCategorySelected,
  categories,
  featured,
  stepWizard = {},
  setSearchTermDisplayed,
}) => {
  return (
    <>
      {Boolean(featured?.length) && (
        <CategorySection data-testid="featuredWrapper" section="featured">
          {featured.map(item => (
            <CategoryCard
              categoryIcon={item.icon!}
              categoryId={item.id!}
              categoryName={item.name!}
              key={item.id}
              onClick={() => {
                stepWizard.nextStep();
                setCategorySelected(item.id!);
                setSearchTermDisplayed('');
              }}
            />
          ))}
        </CategorySection>
      )}
      {Boolean(categories?.length) && (
        <CategorySection data-testid="featuredWrapper" section="Catgories">
          {categories.map(item => (
            <CategoryCard
              categoryIcon={item.icon!}
              categoryId={item.id!}
              categoryName={item.name!}
              key={item.id}
              onClick={() => {
                stepWizard.nextStep();
                setCategorySelected(item.id!);
                setSearchTermDisplayed('');
              }}
            />
          ))}
        </CategorySection>
      )}
    </>
  );
};

interface Step2Props {
  categorySelected: string | null;
  selectedDocuments: PopupDocument[];
  setSelectedDocuments: (documents: PopupDocument[]) => void;
  onDocumentClick: (document: Document | BaseReferenceDTO) => void;
  selectedCountryCode?: string;
}

const Step2: FC<Step2Props> = ({
  categorySelected = '',
  selectedDocuments,
  setSelectedDocuments,
  onDocumentClick,
  selectedCountryCode,
}) => {
  const { data: categoryData, isFetching: categoryIsFetching } =
    useGetSubcategoriesQuery(categorySelected ? categorySelected : '', {
      skip: !!selectedCountryCode,
    });

  const { data: adminCategoryData, isFetching: adminCategoryIsFetching } =
    useGetAdminCategoryByIdQuery(
      {
        id: categorySelected as string,
        countryCode: selectedCountryCode as string,
      },
      { skip: !selectedCountryCode || !categorySelected },
    );

  const data = categoryData || adminCategoryData;
  const isFetching = categoryIsFetching || adminCategoryIsFetching;

  const categoryName = getNameFromLanguage(data?.name!);

  const subcategories = data?.subcategories ?? [];
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      label: t('Browse.categories'),
    },
    {
      label: categoryName ?? '',
    },
  ];

  useEffect(() => {
    const storedSubcategory = localStorage.getItem(
      'preservedAddProcedureSubcategoryId',
    );
    if (storedSubcategory) {
      setPreservedSubcategoryIds([storedSubcategory]);
    }
    localStorage.setItem('preservedAddProcedureSubcategoryId', '');
  }, []);
  const [preservedSubcategoryIds, setPreservedSubcategoryIds] = useState(['']);
  const handleAccordionClick = (subcategoryId: string) => {
    localStorage.setItem('preservedAddProcedureSubcategoryId', subcategoryId);
    setPreservedSubcategoryIds(
      preservedSubcategoryIds?.includes(subcategoryId)
        ? [...preservedSubcategoryIds.filter(id => id !== subcategoryId)]
        : [...preservedSubcategoryIds, subcategoryId],
    );
  };

  if (isFetching) {
    return 'Loading...';
  }
  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs} title={categoryName} />
      {subcategories?.map((subcategory, index) => (
        <SubcategoryAccordion
          data-testid={`SubcategoryAccordion${index}`}
          defaultActiveItemKey={preservedSubcategoryIds}
          itemKey={subcategory?.id ?? index}
          key={subcategory?.id ?? index}
          name={getNameFromLanguage(subcategory.name as LanguageObject)}
          onClick={() =>
            handleAccordionClick(subcategory?.id ?? index.toString())
          }
        >
          {subcategory?.subtitles?.map(
            // eslint-disable-next-line no-shadow
            ({ name: sectionName, documents }, index) => (
              <div key={index}>
                <Typography variant="overline3">
                  {getNameFromLanguage(sectionName)}
                </Typography>
                {documents?.map(
                  // eslint-disable-next-line no-shadow
                  (document, index) => (
                    <DocumentContainer key={index}>
                      <SubcategoryDocument
                        icon={
                          (getNameFromLanguage(document?.references!) as any)
                            ?.icon || 'Chickfila'
                        }
                        id={
                          (getNameFromLanguage(document?.references!) as any)
                            ?.documentId ?? ''
                        }
                        name={
                          (getNameFromLanguage(document?.references!) as any)
                            ?.name ?? ''
                        }
                        onClick={() => {
                          const ref = itemByLanguage(document.references);
                          if (!ref) {
                            console.error('No reference found for language');
                            return;
                          }
                          onDocumentClick(ref);
                        }}
                      />
                      {(() => {
                        const isSelected = selectedDocuments.find(
                          selectedDocument =>
                            selectedDocument.id === document.id,
                        );
                        const onChangeCheckbox = () => {
                          isSelected
                            ? setSelectedDocuments(
                                selectedDocuments.filter(
                                  selectedDocument =>
                                    selectedDocument.id !== document.id,
                                ),
                              )
                            : setSelectedDocuments([
                                ...selectedDocuments,
                                {
                                  id: document.id!,
                                  type: Constants.STEP_TYPES.DOCUMENT,
                                  reference: {
                                    id: document.id!,
                                  },
                                  name: document.name!,
                                },
                              ]);
                        };

                        return (
                          <StyledDocumentCheckbox
                            isSelected={isSelected}
                            onChangeCheckbox={onChangeCheckbox}
                          />
                        );
                      })()}
                    </DocumentContainer>
                  ),
                )}
              </div>
            ),
          )}
        </SubcategoryAccordion>
      ))}
    </>
  );
};

interface Step3Props {
  handleDocumentClick: (document: Document) => void;
  onSearchTermSelect: SearchResultsProps['onSearchTermSelect'];
  searchTermQuery: string;
  selectedDocuments: PopupDocument[];
  setSelectedDocuments: (documents: PopupDocument[]) => void;
  selectedCountryCode?: string;
}

const Step3: FC<Step3Props> = ({
  handleDocumentClick,
  onSearchTermSelect,
  searchTermQuery,
  selectedDocuments,
  setSelectedDocuments,
  selectedCountryCode,
}) => (
  <SearchResults
    onDocumentClick={handleDocumentClick}
    onSearchTermSelect={onSearchTermSelect}
    searchTermQuery={searchTermQuery}
    selectedCountryCode={selectedCountryCode}
    selectedDocuments={selectedDocuments}
    setSelectedDocuments={setSelectedDocuments}
  />
);

interface Step4Props {
  previewDocument: Document | BaseReferenceDTO;
}

const Step4: FC<Step4Props> = ({ previewDocument }) => {
  return <DocumentPreview previewDocument={previewDocument} />;
};

interface StyledDocumentCheckboxProps {
  isSelected?: PopupDocument;
  onChangeCheckbox: () => void;
}

const StyledDocumentCheckbox: FC<StyledDocumentCheckboxProps> = ({
  isSelected = false,
  onChangeCheckbox,
}) => {
  return (
    <StyledDocumentCheckboxWrapper>
      <StyledProcedureBoxFiled>
        {isSelected ? (
          <StyledIconSquareRoundedCheckFilled
            data-testid="documentCheckboxSelected"
            onClick={onChangeCheckbox}
          />
        ) : (
          <StyledIconSquareRoundedPlus
            data-testid="documentCheckbox"
            onClick={onChangeCheckbox}
          />
        )}
      </StyledProcedureBoxFiled>
    </StyledDocumentCheckboxWrapper>
  );
};

interface SearchResultsProps {
  searchTermQuery: string;
  onDocumentClick: (document: Document) => void;
  onSearchTermSelect: (
    suggestion: string | null,
    spellingSuggestion: string,
  ) => void;
  selectedDocuments: any[];
  setSelectedDocuments: (documents: PopupDocument[]) => void;
  selectedCountryCode?: string;
}

export const SearchResults: FC<SearchResultsProps> = ({
  searchTermQuery,
  onDocumentClick,
  onSearchTermSelect,
  selectedDocuments,
  setSelectedDocuments,
  selectedCountryCode,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const dispatch = useDispatch();
  const userSettingCountry: { id?: string } = useSelector(selectUserCountry);
  const { t } = useTranslation();
  const queryId: ReduxSearch['queryId'] = useSelector(selectQueryId);
  const storedQuery: ReduxSearch['query'] = useSelector(selectStoredQuery);
  const searchResults: ReduxSearch['searchResults'] =
    useSelector(selectSearchResults);
  const result: ReduxSearch['result'] = useSelector(selectResult);
  const hasNextPage: ReduxSearch['hasNextPage'] =
    useSelector(selectHasNextPage);
  const currentPage: ReduxSearch['currentPage'] =
    useSelector(selectCurrentPage);
  const pageSize: ReduxSearch['pageSize'] = useSelector(selectPageSize);
  const numFound: ReduxSearch['numFound'] = useSelector(selectNumFound);
  const showingResults: ReduxSearch['showingResults'] =
    useSelector(selectShowingResults);
  const languageCode = getCurrentLanguage();
  const [misspellingSuggestion, setMisspellingSuggestion] = useState('');

  const maxResults = Constants.MAX_SEARCH_RESULTS;

  if (searchTermQuery !== storedQuery) {
    dispatch(resetSearchState());
    dispatch(setStoredQuery(searchTermQuery));
  }

  const { xpApi: xpApiFeatureFlag } = useFlags();

  const {
    data: xpAPIResult,
    isFetching: xpAPIIsFetching,
    error: xpAPIError,
  } = xpAPIUseGetSearchResultsQuery(
    {
      country: selectedCountryCode || userSettingCountry?.id,
      language: languageCode,
      pageNumber: currentPage,
      query: searchTermQuery ?? '',
    },
    {
      refetchOnMountOrArgChange: false,
      skip:
        !xpApiFeatureFlag || xpApiFeatureFlag === undefined || !searchTermQuery,
    },
  );

  const {
    data: coreResult,
    isFetching: coreIsFetching,
    error: coreError,
  } = useGetSearchResultsQuery(
    {
      language: languageCode,
      page: 1,
      pageSize: pageSize,
      query: searchTermQuery,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: xpApiFeatureFlag || !searchTermQuery,
    },
  );

  const isFetching = xpAPIIsFetching || coreIsFetching;
  const error = xpAPIError ?? coreError;

  useEffect(() => {
    dispatch(setHasNextPage(searchResults?.length < numFound));
  }, [dispatch, numFound, searchResults?.length]);

  useEffect(() => {
    if (
      xpApiFeatureFlag &&
      !!xpAPIResult &&
      xpAPIResult?.results !== searchResults
    ) {
      if (
        xpAPIResult.page === currentPage &&
        currentPage > 1 &&
        xpAPIResult.queryId !== queryId
      ) {
        if (xpAPIResult.page === currentPage) {
          dispatch(setQueryId(xpAPIResult.queryId));
          dispatch(addSearchResults(xpAPIResult.results));
        }
      } else {
        dispatch(setQueryId(xpAPIResult.queryId));
        dispatch(setSearchResults(xpAPIResult.results));
      }
      setMisspellingSuggestion(first(xpAPIResult.spellingSuggestions) ?? '');
      dispatch(setResult(xpAPIResult));
    } else if (!xpApiFeatureFlag && !!coreResult) {
      dispatch(setSearchResults(coreResult.results));
      setMisspellingSuggestion(
        // @ts-ignore (not sure how to resolve this one - similar to SearchResultsPage.tsx line 175)
        first(coreResult.spellCorrectedQueryResults)?.suggestedQueryText,
      );
      dispatch(setResult(coreResult));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreResult, xpAPIResult]);

  useEffect(() => {
    const oldShowingResults = Math.min(
      Math.ceil(pageSize * currentPage),
      maxResults,
    );
    const xpApiShowingResults = Math.min(
      Math.ceil(Constants.DEFAULT_PAGING_SIZE * currentPage),
      maxResults,
    );
    const newShowingResults = !!xpApiFeatureFlag
      ? xpApiShowingResults
      : oldShowingResults;

    dispatch(
      setShowingResults(
        hasNextPage ? newShowingResults : numFound > 0 ? numFound : null,
      ),
    );
  }, [
    currentPage,
    dispatch,
    hasNextPage,
    maxResults,
    numFound,
    pageSize,
    showingResults,
    xpApiFeatureFlag,
  ]);

  useEffect(() => {
    if (result && (!!result.totalResults || !!(result as any).numberFound)) {
      dispatch(
        setNumFound(
          xpApiFeatureFlag
            ? Math.min(result.totalResults, maxResults)
            : Math.min((result as any)?.numberFound!, maxResults),
        ),
      );
    }
  }, [dispatch, maxResults, result, xpApiFeatureFlag]);

  const fetchNextPage = (num: number) => {
    if (!!xpApiFeatureFlag) {
      dispatch(setCurrentPage(num));
    } else {
      dispatch(setPageSize(num * 12 > 100 ? 100 : num * 12));
    }
  };

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  return (
    <>
      <LoadingOverlay isOpen={!!isFetching && !(searchResults.length > 0)} />
      {(showingResults || misspellingSuggestion) && !isFetching && (
        <>
          {misspellingSuggestion && (
            <StyledMisspellingSuggestionWrapper data-testid="MisspellingSuggestionWrapper">
              {t('Search.showingResultsSuggestion')}
              {': '}
              <StyledMisspellingSuggestion
                data-testid="MisspellingSuggestion"
                onClick={() => onSearchTermSelect(null, misspellingSuggestion)}
              >
                {misspellingSuggestion}
              </StyledMisspellingSuggestion>
            </StyledMisspellingSuggestionWrapper>
          )}
        </>
      )}
      <StyledResultsText variant="body1">
        {` ${numFound} ${t('Search.numberOfResultsFor')} `}
        {<strong>{searchTermQuery}</strong>}
      </StyledResultsText>
      <div className="list-results">
        {!searchResults?.length ? (
          <StyledNoResults>{t('Search.noResults')}</StyledNoResults>
        ) : (
          searchResults?.map(document => {
            const newId = document.id ?? '';
            return (
              <StyledCard key={newId} title={document.name}>
                <SubcategoryDocument
                  icon={
                    document.icon === null || document.icon === 'placeholder'
                      ? 'Chickfila'
                      : document.icon
                  }
                  id={newId}
                  name={document.name ?? ''}
                  onClick={() => onDocumentClick(document)}
                />
                {(() => {
                  const isSelected = selectedDocuments.find(
                    selectedDocument => selectedDocument.id === newId,
                  );
                  const onChangeCheckbox = () => {
                    isSelected
                      ? setSelectedDocuments(
                          selectedDocuments.filter(selectedDocument => {
                            return selectedDocument.id !== newId;
                          }),
                        )
                      : setSelectedDocuments([
                          ...selectedDocuments,
                          {
                            id: newId,
                            type: Constants.STEP_TYPES.DOCUMENT,
                            reference: {
                              id: newId,
                            },
                            name: {
                              en: languageCode === 'en' ? document.name : null,
                              es: languageCode === 'es' ? document.name : null,
                            },
                          },
                        ]);
                  };
                  return (
                    <StyledDocumentCheckbox
                      isSelected={isSelected}
                      onChangeCheckbox={onChangeCheckbox}
                    />
                  );
                })()}
              </StyledCard>
            );
          })
        )}
      </div>
      {result && hasNextPage && (
        <LoadMoreButton
          color="secondary"
          data-testid="LoadMore"
          disabled={isFetching}
          onClick={() =>
            fetchNextPage(
              Math.trunc(showingResults / Constants.DEFAULT_PAGING_SIZE) + 1,
            )
          }
          size="lg"
          variant="filled"
        >
          <>
            {(xpAPIIsFetching || coreIsFetching) && (
              <LoadMoreLoadingIndicator disabled size="sm" variant="inline" />
            )}
            {t('Button.loadMore')}
          </>
        </LoadMoreButton>
      )}
      {showingResults && (
        <Typography variant="body1">
          {t('Search.showingResultsFooter', {
            showingResults,
            numberOfResults: numFound <= 100 ? numFound : '100+',
            resultsText: pluralize(
              numFound,
              t('Search.resultText'),
              t('Search.resultsText'),
            ),
          })}
        </Typography>
      )}
    </>
  );
};

const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ModalHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSpacer = styled.div`
  width: 24px;
`;

const StyledIconSquareRoundedCheckFilled = styled(IconSquareRoundedCheckFilled)`
  color: ${props => props.theme.semanticColors.success};
  cursor: pointer;
`;

const StyledIconSquareRoundedPlus = styled(IconSquareRoundedPlus)`
  color: ${props => props.theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LoadMoreButton = styled(Button)`
  margin: 0 auto;
  margin-top: 32px;
  display: block;
`;
const LoadMoreLoadingIndicator = styled(LoadingIndicator)`
  margin-right: 10px;
  margin-top: -5px;
`;

const StyledMisspellingSuggestionWrapper = styled.span`
  display: block;
  font-weight: normal;
  color: ${props => props.theme.grayScale.gray7};
  margin-bottom: 16px;
`;
const StyledMisspellingSuggestion = styled.strong`
  color: ${props => props.theme.primaryPalette.navyBlue};
  font-weight: 700;
  cursor: pointer;
`;

const StyledResultsText = styled(Typography)`
  text-align: left;
`;

const StyledNoResults = styled.div`
  padding: 32px 24px;
`;

const AddProcedureSearchbar = styled(Searchbar)`
  margin-top: 24px;
  margin-bottom: 8px;
  z-index: 5;
`;

const StyledCard = styled(Card)`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledProceduresWrapper = styled.div<any>`
  margin: 16px 0;
  ${({ $currentStep }) =>
    $currentStep === 4 &&
    `height: calc(100% - 32px);
  & div {
    height: 100%;
  }`}
`;

const StyledDocumentCheckboxWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
const StyledProcedureBoxFiled = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0 1em;
`;

export default AddProcedurePopUp;
