import Constants from 'constants/index';
import {
  isAdminPath,
  isComplianceDocumentPath,
  isCompliancePath,
  isDocumentPath,
  isLeadershipPath,
  isReportsPath,
  isReportsPlansViewPath,
  isReportsTeamMemberProgressViewPath,
  isReportsTeamMembersViewPath,
  isSettingsPath,
  isTeamMemberPlan,
  isTrainingAssignedPath,
  isTrainingBuildQuizzesPath,
  isTrainingEditQuizzesPath,
  isTrainingModePath,
  isTrainingPath,
  isTrainingPlanPath,
  isTrainingTeamMemberProgressPath,
  isTrainingTeamMembersPath,
} from 'utils/url';
import { useDeviceInfo } from 'utils/device';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { Surface, useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { useEffect, useRef, useState } from 'react';
import { hasDropShadow } from 'store/subheader/selectors';
import { useIsNative } from 'hooks/useIsNative';
import { selectIsFileNotFound } from 'store/fileNotFound/selectors';
import SearchbarSubheader from './SearchbarSubheader';
import AdminSubheader from './AdminSubheader';
import ReportsSubheader from './ReportsSubheader';
import TrainingSubheader from './TrainingSubheader';
import TridionDocumentSubheader from './TridionDocumentSubheader';
import { useAppSelector } from '@/hooks';
import { selectIsTridion } from '@/store/document-persisted/selectors';

interface SubheaderContentStyleProps {
  $hasChildren: boolean;
  $isTrainingMode: boolean;
  $isTridion: boolean;
}

const Subheader = () => {
  const location = useLocation();
  const subheaderRef = useRef<HTMLDivElement>(null);
  const { isDesktop } = useDeviceInfo();
  const isNative = useIsNative();
  const [hasChildren, setHasChildren] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const showDropShadow = useSelector(hasDropShadow);
  const isFileNotFound = useSelector(selectIsFileNotFound);
  const breakpoints = useBreakpoints();
  const isMdAndUp = useMediaQuery(breakpoints.up('md'));

  useEffect(() => {
    const updateScrollStatus = () => {
      const y = subheaderRef.current?.getBoundingClientRect().y;
      const isHidden =
        subheaderRef.current?.getBoundingClientRect().height === 0;

      if ((isDesktop && y === 0) || (isNative && y === 0)) {
        setIsScrolled(true);
      } else if (!isDesktop && !isNative && y === 58) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (isHidden) {
        setIsScrolled(false);
      }
    };

    window?.addEventListener('scroll', updateScrollStatus);

    return () => {
      window?.removeEventListener('scroll', updateScrollStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setIsScrolled(false);
    };
  }, [location.pathname]);

  const isAtLeastTrainerLocations = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const isAtLeastTrainer = isAtLeastTrainerLocations.length > 0;
  const isPrinting = useAppSelector(
    state => state.documentTransient.isPrinting,
  );
  const isTrainingSubheader =
    !isAtLeastTrainer || isTrainingAssignedPath(location)
      ? null
      : isTrainingPath(location) &&
        !isTrainingPlanPath(location) &&
        !isTrainingTeamMembersPath(location) &&
        !isTrainingTeamMemberProgressPath(location) &&
        !isTrainingModePath(location) &&
        !isTeamMemberPlan(location) &&
        !isTrainingBuildQuizzesPath(location) &&
        !isTrainingEditQuizzesPath(location);

  const isAdminSubheader = isAdminPath(location);

  const isReportsSubheader =
    isReportsPath(location) &&
    !isReportsPlansViewPath(location) &&
    !isReportsTeamMembersViewPath(location) &&
    !isReportsTeamMemberProgressViewPath(location);

  const isTrainingModeSubheader = isTrainingModePath(location);

  const isDocument = isDocumentPath(location);
  const isTridion = useAppSelector(selectIsTridion) && isDocument;

  const isSearchSubheader = !(
    isTridion ||
    isReportsPath(location) ||
    isSettingsPath(location) ||
    isTrainingPath(location) ||
    isLeadershipPath(location) ||
    isAdminPath(location) ||
    isCompliancePath(location)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // this adds padding if there is a subheader showing and removes if there is not
    if (subheaderRef?.current) {
      const children = Array.from(subheaderRef?.current?.children);
      children.length > 0 ? setHasChildren(true) : setHasChildren(false);
    }
  });

  const hideSubHeader =
    isLeadershipPath(location) ||
    isComplianceDocumentPath(location) ||
    !!isFileNotFound;

  return (
    <>
      {!hideSubHeader && (
        <SubheaderContainer
          $isDesktop={isDesktop}
          $isNative={isNative}
          elevation={isScrolled && showDropShadow && !isDocument ? 16 : 0}
        >
          <SubheaderContent
            $hasChildren={hasChildren}
            $isTrainingMode={isTrainingModeSubheader as boolean}
            $isTridion={isTridion as boolean}
            ref={subheaderRef}
          >
            {/** We hide the navbar when printing a Tridion document */}
            {isSearchSubheader && <SearchbarSubheader />}
            {isTridion && isMdAndUp && !isPrinting && (
              <TridionDocumentSubheader />
            )}
            {isTrainingSubheader && <TrainingSubheader />}
            {isAdminSubheader && <AdminSubheader />}
            {isReportsSubheader && <ReportsSubheader />}
          </SubheaderContent>
        </SubheaderContainer>
      )}
    </>
  );
};

const SubheaderContainer = styled(Surface)`
  position: sticky;
  top: ${({ $isDesktop, $isNative }) =>
    $isDesktop || $isNative ? '0' : Constants.HEIGHT.MOBILE_TOP_NAV};
  z-index: 3;
  width: 100%;
  overflow: visible;
  background-color: ${({ theme }) => theme.primaryPalette.white};
`;

const SubheaderContent = styled.div<SubheaderContentStyleProps>`
  display: ${({ $isTrainingMode }) => $isTrainingMode && 'flex'};
  justify-content: ${({ $isTrainingMode }) => $isTrainingMode && 'center'};
  transform: ${({ $isTrainingMode }) =>
    $isTrainingMode && 'translateY(-1.5em)'};
  margin: 0 auto;
  width: 100%;
  padding: ${({ $hasChildren, $isTridion }) =>
    $hasChildren && !$isTridion ? '15px' : '0'};
  padding-bottom: 0;
  max-width: ${({ $isTridion }) => !$isTridion && '1152px'};
`;

export default Subheader;
