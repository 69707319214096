import Constants from 'constants/index';
import Bugsnag from '@bugsnag/browser';
import { isUserInternational } from 'store/user/selectors';
import { useSelector } from 'react-redux';
import { formatBugsnagErrorMessage } from '@/utils/bugsnag';
import { selectBugsnagInitialized } from '@/store/app/selectors';

const useBugsnagNotify = () => {
  const isInternationalUser = useSelector(isUserInternational);
  const bugsnagInitialized = useSelector(selectBugsnagInitialized);

  const notifyBugsnag = (err: any): void => {
    const error = typeof err === 'string' ? new Error(err) : err;

    if (
      !isInternationalUser &&
      bugsnagInitialized &&
      Constants.BUGSNAG_ENABLED
    ) {
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    } else {
      console.error(error);
    }
  };

  return { notifyBugsnag };
};

export default useBugsnagNotify;
