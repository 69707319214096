import Constants from 'constants/index';
import { useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetReportsTrainingPlanStatusesQuery } from 'services/xpApi';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import { setAssignedChecklistStatus } from 'store/trainingMode/slice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useGetReportsAssignedProgressByPlanQuery,
  useGetCourseReportQuery,
  useGetOperatorsQuery,
  useGetReportsTeamMembersQuery,
  useGetAssignedCoursesQuery,
} from 'services/pathwayApi';
import {
  selectUserLicenseeLocations,
  selectAllLocationsWithAtLeastTrainer,
} from 'store/user/selectors';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import {
  selectAllTeamMembers,
  selectFilters,
  selectPagination,
  selectSort,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from 'store/teamMembersFilter/selector';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import ClearFiltersHeader from 'components/ClearFiltersHeader/ClearFiltersHeader';
import {
  addTeamMembersStatusFilter,
  addTeamMembersLocationFilter,
  removeTeamMembersFilter,
  setTeamMembers,
  setTeamMembersSort,
  clearTeamMembersCheckboxFilters,
  loadMoreTeamMembers,
  clearTeamMembersSearchFilter,
} from 'store/teamMembersFilter/slice';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import { printReport } from 'store/printReport/selectors';
import { hidePrintReport } from 'store/printReport/slice';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import { CourseReportDTO } from '@cfacorp-pathway/xp-api-typescript-client';
// @ts-ignore
import { generateReport, printToNative } from 'utils/generateReport';
import StickyFilterCard from '@/components/Cards/StickyFilterCard/StickyFilterCard';
import CheckboxFilterSection from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import GenericError from '@/components/Error/GenericError';
import { isReportsPath } from '@/utils/url';
import { getCompletionDate } from '@/utils/reports';
import { convertDateForReports } from '@/utils/date';
import { messageReactNative } from '@/utils/messageReactNative';
import { removeUnderscoreAndCapitalizeString } from '@/utils/removeUnderscoreAndCapitalizeString';
import {
  generateTotalTime,
  generateTotalTimeFromSteps,
  getTimeSpentOnPlan,
} from '@/utils/time';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';
import { getNameFromLanguage } from '@/utils/language';
import { isApiError } from '@/utils/request';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { arrayToCommaString } from '@/utils/arrayToCommaString';
// @ts-ignore until this file is converted in a separate ticket (not created yet)
import TeamMemberCard from '@/pages/TrainingPlans/ManagePlans/TeamMemberCard/TeamMemberCard';
import NoMessage from '@/pages/TrainingPlans/SharedComponents/NoMessage';
import PrintReportModal from '@/components/popups/PrintReportModal';
import CheckboxList from '@/components/Checkbox/CheckboxList';
import { useSortOptions } from '@/hooks/useSortOptions';
import { LanguageObject } from '@/types/types';

interface AssignedTeamMembersCountProps {
  count: number;
  inNavbar: boolean;
}

interface StyledAssignedTeamMembersCountProps {
  $inNavbar: boolean;
}

interface TeamMembers {
  completionDate: string;
  locations: string[];
  name: string;
  status: string;
  steps: any[];
  stepsComplete: number;
  userId: string;
}

export const AssignedTeamMembersCount: React.FC<
  AssignedTeamMembersCountProps
> = ({ count, inNavbar }) => {
  const { t } = useTranslation();
  return (
    <StyledAssignedTeamMembers $inNavbar={inNavbar}>
      {inNavbar ? t('Generic.teamMembers') : t('TrainingPlans.assigned')}{' '}
      {`(${count})`}
    </StyledAssignedTeamMembers>
  );
};

const ReportsPlanView = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const location = useLocation();
  const sortOptions = useSortOptions({ reportsPlansView: true });
  const { search, state } = location;
  const isReportsView = isReportsPath(location);
  const params = useParams();
  const { id: planId } = params as { id: string };
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const isCompliance = search.includes('compliancePlan');
  const [isFoodSafety, setIsFoodSafety] = useState(false);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [selectedReportLocations, setSelectedReportLocations] =
    useState<string[]>();
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const [filteredReportTeamMembers, setFilteredReportTeamMembers] =
    useState<TeamMembers[]>();
  const [stepCount, setStepCount] = useState(0);
  const [statusReport, setStatusReport] = useState<CourseReportDTO>();

  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);

  const currentFilters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const { showing, total } = useSelector(selectPagination);
  const statusLabels = !isCompliance
    ? {
        [Constants.TRAINING_PLANS.COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.completed'),
          value: !!(currentFilters as string[]).includes(
            Constants.TRAINING_PLANS.COMPLETED,
          ),
        },
        [Constants.TRAINING_PLANS.NOT_COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.notCompleted'),
          value: !!(currentFilters as string[]).includes(
            Constants.TRAINING_PLANS.NOT_COMPLETED,
          ),
        },
      }
    : {
        [Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.completed'),
          value: !!(currentFilters as string[]).includes(
            Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED,
          ),
        },
        [Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.notCompleted'),
          value: !!(currentFilters as string[]).includes(
            Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED,
          ),
        },
      };
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const allTeamMembers = useSelector(selectAllTeamMembers);
  const teamMembers = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const isPrintReportOpen = useSelector(printReport);

  const skipPlanAssignedProgressWithoutPlanId =
    !isReportsView || !!isCompliance;

  const {
    data: assignedTeamMembersList,
    isFetching: isFetchingAssignedTeamMembers,
    refetch: refetchAssignedTeamMembers,
    error: assignedTeamMemberError,
    isSuccess,
  } = useGetReportsAssignedProgressByPlanQuery(
    {
      planId: planId,
    },
    {
      skip: !planId || skipPlanAssignedProgressWithoutPlanId,
    },
  );

  const plan = useMemo(
    () => assignedTeamMembersList?.checklist ?? {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedTeamMembersList?.checklist?.id],
  );

  const planLocationId = plan?.ownerId;

  // check current plan is licensee plan or not, if is licensee plan use licensee location to pull team members
  const reportsPlanLocations = useMemo(() => {
    if (skipPlanAssignedProgressWithoutPlanId) {
      return locationsWithAtLeastTrainer;
    }
    if (!planLocationId) {
      return [];
    }
    const licenseeLocationIds = userLicenseeLocations.map(
      ({ number }) => number,
    );
    return licenseeLocationIds.includes(planLocationId)
      ? [planLocationId]
      : locationsWithAtLeastTrainer;
  }, [
    locationsWithAtLeastTrainer,
    userLicenseeLocations,
    planLocationId,
    skipPlanAssignedProgressWithoutPlanId,
  ]);

  /**
   * if is not in report review or is compliance,
   *    need to call report team members without success from useGetReportsAssignedProgressByPlanQuery;
   * else need to wait above query success to call report team members.
   */
  const skipReportsTeamMember =
    !skipPlanAssignedProgressWithoutPlanId && !isSuccess;

  // Team Members
  const {
    data: oldReportsTeamMembersData,
    isFetching: oldReportsTeamMembersIsFetching,
    error: oldReportsTeamMembersError,
  } = useGetReportsTeamMembersQuery(
    { locations: reportsPlanLocations },
    {
      skip: !!xpApiFeatureFlag || skipReportsTeamMember,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpReportsTeamMemberStatuses,
    isFetching: xpReportsTeamMembersStatusesIsFetching,
    error: xpReportsTeamMembersError,
  } = useGetReportsTrainingPlanStatusesQuery(
    {
      locations: reportsPlanLocations,
    },
    {
      skip: !xpApiFeatureFlag || skipReportsTeamMember,
      refetchOnMountOrArgChange: true,
    },
  );

  const data = useMemo(() => {
    return (
      oldReportsTeamMembersData || xpReportsTeamMemberStatuses
    )?.users?.map(({ user }) => user);
  }, [oldReportsTeamMembersData, xpReportsTeamMemberStatuses]);
  const isFetching =
    oldReportsTeamMembersIsFetching || xpReportsTeamMembersStatusesIsFetching;
  const error = oldReportsTeamMembersError || xpReportsTeamMembersError;

  const { data: complianceStatus, isSuccess: isAssignedCoursesSuccess } =
    useGetAssignedCoursesQuery(
      {
        courseId: planId,
        location: locationsWithAtLeastTrainer,
      },
      { skip: !planId || !isCompliance },
    );

  const { data: operators } = useGetOperatorsQuery();
  const operatorLocations = operators?.find(
    operator => operator.id === assignedTeamMembersList?.checklist?.ownerId,
  )?.locations;

  const filterLocations = useMemo(() => {
    if (isCompliance) {
      return locationsWithAtLeastTrainer;
    } else {
      return (
        arrayIntersect(locationsWithAtLeastTrainer, operatorLocations ?? []) ??
        []
      );
    }
  }, [isCompliance, locationsWithAtLeastTrainer, operatorLocations]);

  const { data: statusReportData, isSuccess: isStatusReportSuccess } =
    useGetCourseReportQuery(
      {
        courseId: planId,
        location: selectedReportLocations as string[],
      },
      {
        skip: !isCompliance || !selectedReportLocations?.length,
      },
    );

  const handleCurrentFilteredLocations = () => {
    if (!currentFilters.length) {
      setSelectedReportLocations(filterLocations);
    } else {
      const currentFilteredLocations = currentFilters.filter(filter => {
        const isNumber = /^\d+$/.test(filter);
        return isNumber;
      });
      if (!currentFilteredLocations.length) {
        setSelectedReportLocations(filterLocations);
      } else {
        setSelectedReportLocations(currentFilteredLocations);
      }
    }
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  };

  useEffect(() => {
    handleCurrentFilteredLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters, filterLocations]);

  useEffect(() => {
    if (!!complianceStatus?.courseName) {
      setIsFoodSafety(
        getNameFromLanguage(complianceStatus.courseName).includes(
          Constants.FOOD_SAFETY_COURSE_NAME,
        ) && search.includes('compliancePlan'),
      );
    }
  }, [complianceStatus?.courseName, search]);

  // Clear Team Members Filters
  useEffect(() => {
    dispatch(clearTeamMembersCheckboxFilters());
    dispatch(clearTeamMembersSearchFilter());
  }, [dispatch]);

  //filter team members for reports
  useEffect(() => {
    if (selectedReportLocations?.length) {
      const filteredTeamMembers = allTeamMembers?.filter(teamMember => {
        const { locations } = teamMember;
        const hasLocation = (locations as [])?.some(loc =>
          selectedReportLocations.includes(loc),
        );
        return hasLocation;
      });
      setFilteredReportTeamMembers(filteredTeamMembers);
    } else {
      setFilteredReportTeamMembers(allTeamMembers);
    }
  }, [allTeamMembers, selectedReportLocations]);

  useEffect(() => {
    if (assignedTeamMembersList) {
      const assignedChecklistStatus = !assignedTeamMembersList?.status
        ?.filter(it => it.status !== Constants.TRAINING_PLANS.UNASSIGNED)
        .every(user => user.status === Constants.TRAINING_PLANS.COMPLETED);
      dispatch(setAssignedChecklistStatus(assignedChecklistStatus));
    }
  }, [assignedTeamMembersList, dispatch]);

  useEffect(() => {
    if (plan?.name) {
      dispatch(setHeader(getNameFromLanguage(plan?.name)));
    }
    if (plan?.category) {
      dispatch(
        setHeaderLabel(t(mapCategoryToAliasTranslation(plan?.category))),
      );
    }
    if (isCompliance) {
      dispatch(
        setHeader(
          getNameFromLanguage(complianceStatus?.courseName as LanguageObject),
        ),
      );
      dispatch(
        setHeaderLabel(
          t(
            mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
          ),
        ),
      );
    }
    if (plan?.estimatedMinutes) {
      dispatch(
        setHeaderSubtext(
          t('TrainingPlans.trainingMode.estimatedCompletionTime', {
            estimatedTime: generateTotalTime(
              plan?.estimatedMinutes,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          }),
        ),
      );
    }
    return () => {
      dispatch(setHeaderLabel(''));
      dispatch(setHeader(''));
      dispatch(setHeaderSubtext(''));
    };
  }, [complianceStatus, dispatch, isCompliance, plan, t]);

  useEffect(() => {
    if (isSuccess && !!assignedTeamMembersList && !!data) {
      setStepCount(assignedTeamMembersList?.checklist?.stepsTotal as number);
      dispatch(
        setTeamMembers({
          teamMembers: assignedTeamMembersList?.status
            ?.map(teamMember => {
              return {
                ...teamMember,
                completionDate: teamMember.completionDate ?? null,
                name: data?.find(({ adId }: any) => teamMember.userId === adId)
                  ?.name,
                locations: data?.find(
                  ({ adId }: any) => teamMember.userId === adId,
                )?.locations,
              };
            })
            .filter(
              teamMember =>
                teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
            ),
        }),
      );
    }
  }, [assignedTeamMembersList, data, dispatch, isSuccess]);

  // Compliance plans
  useEffect(() => {
    if (
      isAssignedCoursesSuccess &&
      complianceStatus?.enrollments !== undefined
    ) {
      dispatch(
        setTeamMembers({
          teamMembers: data
            ?.map((teamMember: any) => {
              return {
                userId:
                  (complianceStatus?.enrollments?.[teamMember?.adId] as any)
                    ?.userId ?? teamMember?.adId,
                completedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.completedDate ?? null,
                courseId:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.courseId ??
                  '',
                dueDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.dueDate ??
                  null,
                duration:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.duration ??
                  null,
                enrolledDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.enrolledDate ?? '',
                enrollmentId:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.enrollmentId ?? '',
                expirationDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.certificate
                    ?.expiration ?? '',
                finalScore:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.finalScore ?? 0,
                hidden:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.hidden ??
                  false,
                locations:
                  (complianceStatus?.enrollments?.[teamMember?.adId] as any)
                    ?.locations ?? teamMember?.locations,
                mostRecentCompletedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.mostRecentCompletedDate ?? null,
                name: teamMember?.name,
                pathwayCourseId:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.pathwayCourseId ?? '',
                percentComplete:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.percentComplete ?? 0,
                startedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.startedDate ?? '',
                status:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.status ??
                  '',
              };
            })
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  user => user.userId === value.userId && !user.hidden,
                ),
            ),
        }),
      );
    }
  }, [complianceStatus, data, dispatch, isAssignedCoursesSuccess]);

  useEffect(() => {
    // We can't call `refetchAssignedTeamMembers` in compliance plans because we don't call `useGetAssignedStatusQuery` on component mount
    if (isCompliance) {
      return;
    }
    isSuccess && refetchAssignedTeamMembers();
    // After we refetch the team members, we set the state back to false
    history.replace({
      state: { refetch: false },
      search: history.location.search,
    });
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.refetch, isCompliance]);

  // Status Report
  useEffect(() => {
    if (isStatusReportSuccess) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, isSuccess, statusReportData]);

  // Print Report
  useEffect(() => {
    if (isPrintReportOpen) {
      onPrintReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintReportOpen]);

  const getComplianceReportToPrint = () => {
    return [...((statusReport?.userData as any) ?? [])]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA?.firstName!.localeCompare(teamMemberB?.firstName!);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE
            .NAME]: `${teamMember.firstName} ${teamMember.lastName}`,
          [Constants.REPORT_TABLE.DATE_OF_BIRTH]: teamMember.birthDate
            ? teamMember.birthDate
            : t('Generic.na'),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            getCompletionDate(teamMember),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            teamMember?.certificate?.expiration &&
            teamMember?.courseStatus !==
              Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
              ? convertDateForReports(teamMember?.certificate?.expiration)
              : t('Generic.na'),
        };
      });
  };

  const getStoreReportToPrint = () => {
    return [...(filteredReportTeamMembers ?? [])]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA?.name!.localeCompare(teamMemberB?.name!);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE.NAME]: teamMember.name,
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]: `${
            assignedTeamMembersList?.status?.find(
              userStatus => userStatus.userId === teamMember.userId,
            )?.stepsComplete || 0
          }/${stepCount}`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]:
            generateTotalTimeFromSteps(
              teamMember.steps,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          [Constants.REPORT_TABLE.STATUS]: t(
            `TrainingPlans.statusOptions.${
              (Constants.TRAINING_PLANS_STATUSES as any)[teamMember.status]
            }`,
          ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: teamMember.completionDate
            ? convertDateForReports(teamMember.completionDate)
            : t('Generic.na'),
        };
      });
  };

  const tableHead = isCompliance
    ? [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.COMPLETION_DATE,
          Constants.REPORT_TABLE.EXPIRATION_DATE,
        ],
      ]
    : [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.ITEMS_COMPLETED,
          Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
          Constants.REPORT_TABLE.STATUS,
          Constants.REPORT_TABLE.COMPLETION_DATE,
        ],
      ];
  if (showDoBChecked) {
    tableHead?.[0]?.splice(1, 0, Constants.REPORT_TABLE.DATE_OF_BIRTH);
  }

  const reportToGenerate = {
    category: isCompliance
      ? t(mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE))
      : t(mapCategoryToAliasTranslation(plan?.category ?? '')),
    foodSafetyImage: (statusReport?.foodSafetyLetters?.[0] as any)?.content,
    location: `${t('Generic.at')} ${
      selectedReportLocations?.length === 0
        ? filterLocations
        : !selectedReportLocations?.length
        ? selectedReportLocations?.toString()
        : arrayToCommaString(selectedReportLocations, t('Generic.and'))
    }`,
    name: isCompliance
      ? removeUnderscoreAndCapitalizeString(statusReport?.courseName ?? '')
      : getNameFromLanguage(plan?.name as LanguageObject),
    reportToPrint: isCompliance
      ? getComplianceReportToPrint().map(teamMember =>
          tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
        )
      : getStoreReportToPrint().map(teamMember =>
          tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
        ),
    tableHead,
  };

  const messageToSend = {
    category: reportToGenerate.category,
    location: reportToGenerate.location,
    name: reportToGenerate.name,
    reportToPrint: isCompliance
      ? getComplianceReportToPrint()
      : getStoreReportToPrint(),
    tableHead,
  };

  // GET Team Members By Location Fetch error
  if (isApiError(error as Error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  // GET Assigned Team Members Fetch error
  if (isApiError(assignedTeamMemberError)) {
    notifyBugsnag(assignedTeamMemberError);
    return <GenericError />;
  }

  const onPrintReport = () => {
    dispatch(hidePrintReport());
    filterLocations?.length > 1 || isFoodSafety
      ? setShowChooseReportLocationsPopup(true)
      : handlePrintReport();
  };

  const handlePrintReport = () => {
    if (!selectedReportLocations?.length && !!filterLocations.length) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      handleCurrentFilteredLocations();
    }
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
  };

  const handleShowDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  return (
    <>
      <StyledContent>
        <LoadingOverlay isOpen={isFetching || isFetchingAssignedTeamMembers} />
        {!!isSmAndDown && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setTeamMembersSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              teamMembers?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
            top={Constants.HEIGHT.MOBILE_TOP_NAV}
          >
            {!!filterLocations && filterLocations?.length > 1 && (
              <CheckboxFilterSection
                labels={filterLocations.reduce(
                  (acc, loc) => ({
                    ...acc,
                    [loc]: {
                      translationString: loc,
                      value: !!(currentFilters as string[]).includes(loc),
                    },
                  }),
                  {},
                )}
                onChange={value => {
                  if (!!(currentFilters as string[]).includes(value)) {
                    dispatch(removeTeamMembersFilter({ filter: value }));
                  } else {
                    dispatch(addTeamMembersLocationFilter({ filter: value }));
                  }
                }}
                title={t('Generic.locations')}
              />
            )}
            <CheckboxFilterSection
              labels={statusLabels}
              onChange={value => {
                if (!!(currentFilters as string[]).includes(value)) {
                  dispatch(removeTeamMembersFilter({ filter: value }));
                } else {
                  dispatch(addTeamMembersStatusFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlanCardList>
          {!isSmAndDown && (
            <StickyFilterCard>
              {!!filterLocations && filterLocations?.length > 1 && (
                <CheckboxFilterSection
                  labels={filterLocations.reduce(
                    (acc, loc) => ({
                      ...acc,
                      [loc]: {
                        translationString: loc,
                        value: !!(currentFilters as string[]).includes(loc),
                      },
                    }),
                    {},
                  )}
                  onChange={value => {
                    if (!!(currentFilters as string[]).includes(value)) {
                      dispatch(removeTeamMembersFilter({ filter: value }));
                    } else {
                      dispatch(addTeamMembersLocationFilter({ filter: value }));
                    }
                  }}
                  title={t('Generic.locations')}
                />
              )}
              <CheckboxFilterSection
                labels={statusLabels}
                onChange={value => {
                  if (!!(currentFilters as string[]).includes(value)) {
                    dispatch(removeTeamMembersFilter({ filter: value }));
                  } else {
                    dispatch(addTeamMembersStatusFilter({ filter: value }));
                  }
                }}
                title={t('Generic.status')}
              />
            </StickyFilterCard>
          )}
          <PlanCardsContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setTeamMembersSort({ sort: option?.value }));
              }}
              onClear={() => {
                dispatch(clearTeamMembersCheckboxFilters());
              }}
              options={sortOptions}
              showClear={false}
              showCompletedPlansOption={false}
              showMyCompletedPlansOnlySwitchValue={false}
              text={`${total ?? 0} ${t('Generic.teamMembers')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />

            <ClearFiltersHeader
              aliases={statusLabels}
              clearAllFilters={() => {
                dispatch(clearTeamMembersCheckboxFilters());
              }}
              clearFilter={value => {
                dispatch(removeTeamMembersFilter({ filter: value }));
              }}
              filters={currentFilters}
            />
            <PlanCardsList>
              {!!teamMembers?.length && (
                <>
                  {teamMembers &&
                    (teamMembers as any).map((member: any, index: number) => {
                      return (
                        <TeamMemberCard
                          completedStepCount={
                            assignedTeamMembersList?.status?.find(
                              userStatus => userStatus.userId === member.userId,
                            )?.stepsComplete || 0
                          }
                          completionDate={
                            isCompliance
                              ? member.completedDate
                              : member.completionDate
                          }
                          expirationDate={member.expirationDate}
                          finalScore={member.finalScore}
                          id={member.userId ?? ''}
                          isComplete={
                            member?.status ===
                              Constants.TRAINING_PLANS.COMPLETED ||
                            member?.status ===
                              Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
                            member?.status ===
                              Constants.LEARN_UPON_TRAINING_PLANS.PASSED
                          }
                          isCompliance={isCompliance}
                          isFailed={
                            member?.status ===
                            Constants.LEARN_UPON_TRAINING_PLANS.FAILED
                          }
                          isFoodSafety={isFoodSafety}
                          isReportsPlansView={true}
                          key={index}
                          name={member.name ?? ''}
                          onRefetch={() => {
                            refetchAssignedTeamMembers();
                          }}
                          percentComplete={member?.percentComplete}
                          planId={planId}
                          planName={
                            getNameFromLanguage(plan?.name as LanguageObject) ??
                            ''
                          }
                          selectedDueDate={new Date(member.dueDate).getTime()}
                          stepCount={stepCount}
                          timeSpentOnPlan={getTimeSpentOnPlan(
                            member,
                            isCompliance,
                          )}
                        />
                      );
                    })}
                  <LoadMorePaginator
                    onClick={() => dispatch(loadMoreTeamMembers())}
                    showing={showing}
                    showingText={t('TrainingPlans.showingXOfYTeamMembers', {
                      showing: showing ?? 0,
                      total: total ?? 0,
                    })}
                    total={total ?? 0}
                  />
                </>
              )}
              {!teamMembers?.length && !currentFilters?.length && (
                <NoMessage message={t('TrainingPlans.noTeamMembersAssigned')} />
              )}
              {!!currentFilters?.length && !total && (
                <NoMessage message={t('TrainingPlans.noTeamMembersResults')} />
              )}
            </PlanCardsList>
          </PlanCardsContainer>
        </PlanCardList>
      </StyledContent>
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        children={
          filterLocations.length > 1
            ? filterLocations.map((id, idx) => (
                <StyledCheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations as string[]}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : null
        }
        handleShowDoBCheckbox={handleShowDoBCheckbox}
        headerText={t('Generic.printReport')}
        isFoodSafety={isFoodSafety}
        isOpen={showChooseReportLocationsPopup}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations ?? []}
        showDoBChecked={showDoBChecked}
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;
const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;
const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;
const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;
const StyledAssignedTeamMembers = styled.div<StyledAssignedTeamMembersCountProps>`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-weight: 500;
  margin-bottom: ${({ $inNavbar }) => ($inNavbar ? '0' : '10px')};
`;
const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default withRoles(
  ReportsPlanView,
  [
    Constants.USER_PERMISSIONS.LEADER,
    Constants.USER_PERMISSIONS.OPERATOR,
    Constants.USER_PERMISSIONS.TRAINER,
    Constants.USER_PERMISSIONS.STAKEHOLDER,
    Constants.USER_PERMISSIONS.LICENSEESTAFF,
  ],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
