import Constants from 'constants/index';
import { setWindowObjectToConnectAppToCypress } from 'utils/environment';
import { ApiError } from '@cfacorp-pathway/xp-api-typescript-client';
import oktaAuth from 'okta/oktaConfig';
import { Error } from '@/types/types';

export const getRefreshTokenWithRedirect = () => {
  if (Constants.IS_IN_CYPRESS_TEST) {
    setWindowObjectToConnectAppToCypress('signInWithRedirect', 'true');
    return;
  }
  return oktaAuth.signInWithRedirect();
};

export const isUnAuthorizedError = (error: Error | ApiError | undefined) =>
  error && error?.status === 401;
