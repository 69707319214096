import Constants from 'constants/index';
// @ts-ignore
import history from 'utils/history';
import { OktaAuth, OktaAuthOptions, toRelativeUrl } from '@okta/okta-auth-js';

export const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
  if (originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  } else {
    history.push('/');
  }
};

const transformAuthState: OktaAuthOptions['transformAuthState'] = async (
  _,
  authState,
) => {
  if (Constants.IS_IN_CYPRESS_TEST) {
    // eslint-disable-next-line no-param-reassign
    authState.isAuthenticated = true;
    // eslint-disable-next-line no-param-reassign
    (authState as any).idToken = 'dev';
    // eslint-disable-next-line no-param-reassign
    (authState as any).accessToken = 'dev';
    return authState;
  }
  // below from: https://github.com/okta/okta-auth-js
  // extra requirement: user must have valid Okta SSO session
  // const user = await oktaAuth.token.getUserInfo();
  // authState.isAuthenticated = true; // convert to boolean
  // authState.users = user; // also store user object on authState
  return authState;
};

const oktaConfig: OktaAuthOptions = {
  issuer: Constants.OKTA_ISSUER || 'http://bogus.jest',
  clientId: Constants.OKTA_CLIENT_ID || 'jest',
  redirectUri: window.location.origin + '/login/callback',
  pkce: true,
  scopes: ['openid', 'email', 'profile', 'location_info', 'job_info'],
  transformAuthState,
};
const oktaAuth = new OktaAuth(oktaConfig);

// oktaAuth.authStateManager.subscribe(e => debugger)

export default oktaAuth;
