import Constants from 'constants/index';
import {
  ChecklistSectionStepDTO,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'cfa-react-components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import PlanItemProcedure from '../SharedComponents/PlansItemCards/PlanItemProcedure';
// @ts-ignore
import PlanItemTask from '../SharedComponents/PlansItemCards/PlanItemTask';
// @ts-ignore
import PlanItemQuiz from '../SharedComponents/PlansItemCards/PlanItemQuiz';
// @ts-ignore
import PlanItemCard from '../SharedComponents/PlansItemCards/PlanItemCard';
// @ts-ignore
import PlanItemBrightcoveVideo from '../SharedComponents/PlansItemCards/PlanItemBrightcoveVideo';
import useTrainingPlanTimer from '@/hooks/useTrainingPlanTimer';
import { selectTrainingModeData } from '@/store/trainingMode/selectors';
import { TeamMemberWithAdId } from '@/components/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import {
  useGetAssignedStatusQuery,
  useUpdateChecklistStatusMutation,
  useUpdateChecklistStepStatusMutation,
} from '@/services/pathwayApi';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { selectAllLocationsWithAtLeastTrainer } from '@/store/user/selectors';
import GenericError from '@/components/Error/GenericError';
import { isApiError } from '@/utils/request';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';
import { generateTotalTime } from '@/utils/time';

interface SectionsAndStepsProps {
  showPlanCompleteModal: () => void;
}

const SectionsAndSteps: React.FC<SectionsAndStepsProps> = ({
  showPlanCompleteModal,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [updateChecklistStepStatus] = useUpdateChecklistStepStatusMutation();
  const [updateChecklistStatus] = useUpdateChecklistStatusMutation();
  const { notifyBugsnag } = useBugsnagNotify();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const {
    assignedTeamMembersStatus,
    estimatedMinutes,
    languageSelected,
    planId,
    planLabel,
    planName,
    selectedTeamMembers,
  } = useSelector(selectTrainingModeData);
  const assignedUserIds = (selectedTeamMembers as TeamMemberWithAdId[]).map(
    member => member.adId,
  );
  const [activeTask, setActiveTask] = useState('');
  const [activeQuizStepId, setActiveQuizStepId] = useState('');
  const [assignedPlan, setAssignedPlan] = useState<UserChecklistResultDTO>();

  const {
    data: plan,
    error,
    refetch: refetchAssignedStatus,
  } = useGetAssignedStatusQuery(
    {
      checklist: planId,
      location: locationsWithAtLeastTrainer,
    },
    {
      skip: !planId,
    },
  );

  useEffect(() => {
    // We route the user back to the Team Members page if they did not access this page through the Training Mode flow
    if (!planName) {
      history.push(`${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`);
    } else if (planName) {
      dispatch(setHeader(planName));
      dispatch(setHeaderLabel(planLabel));
      dispatch(
        setHeaderSubtext(
          t('TrainingPlans.trainingMode.estimatedCompletionTime', {
            estimatedTime: generateTotalTime(
              estimatedMinutes,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          }),
        ),
      );
    }
  }, [dispatch, history, t, planName, planLabel, estimatedMinutes]);

  useEffect(() => {
    if (plan) {
      setAssignedPlan(plan);
      refetchAssignedStatus();
    }
  }, [plan, refetchAssignedStatus]);

  useEffect(() => {
    if (assignedPlan?.checklist?.name) {
      dispatch(
        setHeader(
          getNameFromLanguage(assignedPlan.checklist.name, languageSelected),
        ),
      );
    }
  }, [assignedPlan, dispatch, languageSelected]);

  const isStepCompleted = (stepId: string) => {
    return assignedPlan?.status
      ?.filter(status => assignedUserIds.find(id => status.userId === id))
      ?.every(
        planAssigned =>
          planAssigned?.steps?.find(step => step.stepId === stepId)?.status ===
          Constants.TRAINING_PLANS.COMPLETE,
      );
  };

  const getStepStatus = (status: boolean) => {
    return status
      ? Constants.TRAINING_PLANS.COMPLETE
      : Constants.TRAINING_PLANS.INCOMPLETE;
  };

  const [startTaskTimer, stopTaskTimer] = useTrainingPlanTimer(
    assignedPlan?.checklist?.id as string,
    activeTask,
    assignedUserIds,
    getStepStatus(isStepCompleted(activeTask) as boolean),
  );

  const [startQuizTimer, stopQuizTimer] = useTrainingPlanTimer(
    planId,
    activeQuizStepId,
    assignedUserIds,
    getStepStatus(isStepCompleted(activeQuizStepId) as boolean),
  );

  useEffect(() => {
    if (activeQuizStepId !== '' && isStepCompleted(activeQuizStepId)) {
      setActiveQuizStepId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId, assignedPlan]);

  // timer for tasks
  useEffect(() => {
    if (activeTask !== '') {
      startTaskTimer?.();
    } else {
      stopTaskTimer?.();
    }
    return () => {
      stopTaskTimer?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTask]);

  // quiz timer
  useEffect(() => {
    if (activeQuizStepId !== '') {
      startQuizTimer?.();
    } else {
      stopQuizTimer?.();
    }
    return () => {
      stopQuizTimer?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId]);

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  const onCardClick = (step: ChecklistSectionStepDTO, id?: string) => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/assigned/toComplete`,
      state: {
        planId: planId,
        step,
        id,
        isCompleted: isStepCompleted(step.id as string),
        userIds: assignedUserIds,
        stepStatus: getStepStatus(
          isStepCompleted(step.id as string) as boolean,
        ),
        isViewingFromTrainingPlan: true,
      },
    });
  };

  const handleSetActiveTask = (step: ChecklistSectionStepDTO) => {
    activeTask === step.id
      ? setActiveTask('')
      : setActiveTask(step.id as string);
  };

  const handleSetActiveQuiz = (step: ChecklistSectionStepDTO) => {
    activeQuizStepId === step.id
      ? setActiveQuizStepId('')
      : setActiveQuizStepId(step.id as string);
  };

  const handleSetActiveVideo = (step: ChecklistSectionStepDTO) => {
    activeTask === step.id
      ? setActiveTask('')
      : setActiveTask(step.id as string);
  };

  const onOvalIconToggle = (id: string) => {
    if (id === activeTask) {
      // sibling lift?
      // setActiveTask('');
    }
    updateChecklistStepStatus({
      checklistId: planId,
      stepId: id,
      // @ts-ignore this error needs to be solved on the BE
      status: isStepCompleted(id)
        ? Constants.TRAINING_PLANS.INCOMPLETE
        : Constants.TRAINING_PLANS.COMPLETE,
      // @ts-ignore this error needs to be solved on the BE
      userIds: assignedUserIds,
    })
      .unwrap()
      .then(() => {
        refetchAssignedStatus();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const isPlanComplete = (
    assignedTeamMembersStatus as TeamMemberWithAdId[]
  ).every(
    teamMember => teamMember.status === Constants.TRAINING_PLANS.COMPLETE,
  );

  const onCompleteButtonClick = () => {
    updateChecklistStatus({
      checklist: planId,
      // @ts-ignore this error needs to be solved on the BE
      status: Constants.TRAINING_PLANS.COMPLETED,
      // @ts-ignore this error needs to be solved on the BE
      userIds: assignedUserIds,
    })
      .unwrap()
      .then(() => {
        showPlanCompleteModal();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const areAllStepsComplete = () => {
    const availableResourceCount = plan?.checklist?.sections
      ? plan?.checklist.sections
          .flatMap(section => section?.steps?.filter(step => step.available))
          .concat(
            plan?.checklist?.steps &&
              plan?.checklist?.steps.filter(step => step.available),
          )?.length
      : 0;

    return (
      assignedPlan?.checklist?.stepsTotal !== 0 &&
      assignedPlan?.status
        ?.filter(assignedUser =>
          assignedUserIds?.find(id => assignedUser?.userId === id),
        )
        .map(status => status.stepsComplete)
        .every(step => step === availableResourceCount)
    );
  };

  const renderCompleteButton = () => (
    <StyledButtonWrapper>
      <Button
        color="secondary"
        data-testid="CompleteButton"
        disabled={!areAllStepsComplete() || isPlanComplete}
        onClick={() => onCompleteButtonClick()}
      >
        {t('Button.complete')}
      </Button>
    </StyledButtonWrapper>
  );

  return (
    <>
      {assignedPlan?.checklist?.steps?.map((step, index) => {
        switch (step?.type) {
          case Constants.STEP_TYPES.QUIZ:
            return (
              <PlanItemCard key={index}>
                <PlanItemQuiz
                  isActive={activeQuizStepId === step.id}
                  isComplete={isStepCompleted(step.id as string)}
                  language={languageSelected}
                  onClick={() => handleSetActiveQuiz(step)}
                  onRefetch={refetchAssignedStatus}
                  planId={planId}
                  statuses={assignedPlan?.status}
                  step={step}
                  users={assignedUserIds}
                />
              </PlanItemCard>
            );
          case Constants.STEP_TYPES.TASK:
            return (
              <PlanItemCard key={index}>
                <PlanItemTask
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id as string)}
                  language={languageSelected}
                  onClick={() => handleSetActiveTask(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id as string)}
                  statuses={plan?.status}
                  step={step}
                />
              </PlanItemCard>
            );
          case Constants.STEP_TYPES.DOCUMENT:
            return step?.reference?.type?.toLowerCase() ===
              Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
              <PlanItemCard key={index}>
                <PlanItemBrightcoveVideo
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id as string)}
                  language={languageSelected}
                  onClick={() => handleSetActiveVideo(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id as string)}
                  planId={plan?.checklist?.id}
                  statuses={plan?.status}
                  step={step}
                  users={[]}
                />
              </PlanItemCard>
            ) : (
              <PlanItemCard key={index}>
                <PlanItemProcedure
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id as string)}
                  isMissing={!step.available}
                  language={languageSelected}
                  onClick={() => onCardClick(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id as string)}
                  statuses={plan?.status}
                  step={step}
                />
              </PlanItemCard>
            );
          default:
            return null;
        }
      })}
      {assignedPlan?.checklist?.sections?.map(section => {
        return (
          !!section.steps &&
          !!section.steps.length && (
            <PlanItemCard
              disabled
              key={section?.id}
              sectionName={getNameFromLanguage(
                section.name as LanguageObject,
                languageSelected,
              )}
            >
              {section.steps.map((step, stepIndex) => {
                switch (step?.type) {
                  case Constants.STEP_TYPES.QUIZ:
                    return (
                      <PlanItemQuiz
                        isActive={activeQuizStepId === step.id}
                        isComplete={isStepCompleted(step.id as string)}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => handleSetActiveQuiz(step)}
                        onRefetch={refetchAssignedStatus}
                        planId={planId}
                        statuses={assignedPlan?.status}
                        step={step}
                        users={assignedUserIds}
                      />
                    );
                  case Constants.STEP_TYPES.TASK:
                    return (
                      <PlanItemTask
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id as string)}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => handleSetActiveTask(step)}
                        onCompleteToggle={() =>
                          onOvalIconToggle(step.id as string)
                        }
                        statuses={plan?.status}
                        step={step}
                      />
                    );
                  case Constants.STEP_TYPES.DOCUMENT:
                    return step?.reference?.type?.toLowerCase() ===
                      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
                      <PlanItemBrightcoveVideo
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id as string)}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => handleSetActiveVideo(step)}
                        onCompleteToggle={() =>
                          onOvalIconToggle(step.id as string)
                        }
                        planId={plan?.checklist?.id}
                        statuses={plan?.status}
                        step={step}
                        users={[]}
                      />
                    ) : (
                      <PlanItemProcedure
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id as string)}
                        isMissing={!step.available}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => onCardClick(step)}
                        onCompleteToggle={() =>
                          onOvalIconToggle(step.id as string)
                        }
                        statuses={plan?.status}
                        step={step}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </PlanItemCard>
          )
        );
      })}
      {assignedPlan && renderCompleteButton()}
    </>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
`;

export default SectionsAndSteps;
