import { FC } from 'react';
import { CopyrightFooter } from '../common';
import { MobileWrapper } from '../wrappers';
import TableOfContents from './TableOfContents';
import { TridionTitle } from './TridionTitle';
import { Breadcrumb } from '@/types/types';
import { useAppSelector } from '@/hooks';
import { HeadingItem } from '@/utils/document-utils';

interface MobileViewProps {
  lastModified?: string;
  breadcrumbs: Breadcrumb[];
  styles: Record<string, string>;
  tags: string[];
  icon?: string | null;
  documentContent: React.ReactNode;
  tocItems: HeadingItem[];
  handleTocItemClick: () => void;
  title: string;
}

/** Tridion document mobile view. */
const MobileView: FC<MobileViewProps> = ({
  lastModified,
  breadcrumbs,
  styles,
  tags,
  icon,
  documentContent,
  tocItems,
  handleTocItemClick,
  title,
}) => {
  const isPrinting = useAppSelector(
    state => state.documentTransient.isPrinting,
  );

  return (
    <MobileWrapper>
      <TridionTitle icon={icon} tags={tags} title={title} />
      <div className={styles.mobileTableOfContents}>
        <TableOfContents
          handleClick={handleTocItemClick}
          isMobile={true}
          isPrinting={isPrinting}
          tocItems={tocItems}
        />
      </div>
      <div className={`${styles.tridionDocument} ${styles.mobileView}`}>
        <div>{documentContent}</div>
      </div>
      <CopyrightFooter
        breadcrumbs={breadcrumbs}
        isPrinting={isPrinting}
        lastPublishedDate={lastModified ?? ''}
      />
    </MobileWrapper>
  );
};

export default MobileView;
