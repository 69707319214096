import Constants from 'constants/index';
import { PropsWithChildren, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AssignedPlanView from '../pages/TrainingPlans/MyPlans/AssignedPlanView';
import lazyComponent from '@/utils/lazyComponent';
// @ts-ignore
import Admin from '@/pages/Admin/Admin';
// @ts-ignore
import SignalDocument from '@/pages/Document/SignalDocument';
import PrintView from '@/pages/PrintView/PrintView';
import ReportsCompliancePlansTab from '@/pages/Reports/CompliancePlans/ReportsCompliancePlansTab';
import ReportsPlansTab from '@/pages/Reports/Plans/ReportsPlansTab';
// @ts-ignore
import ReportsPlansView from '@/pages/Reports/Plans/ReportsPlansView';
import ReportsTeamMembersTab from '@/pages/Reports/TeamMembers/ReportsTeamMembersTab';
// @ts-ignore
import ReportsTeamMembersView from '@/pages/Reports/TeamMembers/ReportsTeamMembersView';
import SearchResultsPage from '@/pages/SearchResults/SearchResultsPage';
// @ts-ignore
import BuildPlansTab from '@/pages/TrainingPlans/BuildPlans/BuildPlansTab';
import EditPlanView from '@/pages/TrainingPlans/BuildPlans/EditPlanView';
// @ts-ignore
import BuildQuizzesTab from '@/pages/TrainingPlans/BuildQuizzes/BuildQuizzesTab';
// @ts-ignore
import BuildQuizzesView from '@/pages/TrainingPlans/BuildQuizzes/BuildQuizzesView';
// @ts-ignore
import ManagePlansTab from '@/pages/TrainingPlans/ManagePlans/ManagePlansTab';
// @ts-ignore
import ManagePlanView from '@/pages/TrainingPlans/ManagePlans/ManagePlanView';
// @ts-ignore
import TeamMemberProgressView from '@/pages/TrainingPlans/ManagePlans/TeamMemberProgressView';
// @ts-ignore
import DocumentProxy from '@/pages/TrainingPlans/MyPlans/DocumentProxy';
// @ts-ignore
import MyPlansTab from '@/pages/TrainingPlans/MyPlans/MyPlansTab';
// @ts-ignore
import { TrainingMode } from '@/pages/TrainingPlans/TrainingMode/TrainingMode';
import { useIsNative } from '@/hooks/useIsNative';

const Placeholder: React.FC = () => <span />;

// App.js has Bugsnag boundary that shows Generic error popup
const NotFoundSwitch: React.FC<PropsWithChildren> = ({ children }) => (
  <Switch>
    {children}
    <Route path="*">
      {lazyComponent(
        () =>
          import(/* webpackChunkName: "route.location" */ '../pages/404/404'),
      )}
    </Route>
  </Switch>
);

// Native does not use the okta <Security> wrapper but checks for auth via useOktaAuth hook
const SecureRoutesHandledByNative: React.FC = () => {
  const { ascendNav: featureflagAscendNav } = useFlags();
  return (
    <Suspense fallback={<Placeholder />}>
      <NotFoundSwitch>
        <Route exact path="/category/:categoryId">
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.subcategories" */ '../pages/Explore/Operations/SubcategoryPage'
              ),
          )}
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`}>
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.settings" */ '../pages/Settings/SettingsPage'
              ),
          )}
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`}>
          <SearchResultsPage />
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}>
          <MyPlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildPlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/:planId`}
        >
          <EditPlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TO_COMPLETE_PATH_NAME}`}
        >
          <DocumentProxy />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/:planId`}
        >
          <AssignedPlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
        >
          <ManagePlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
        >
          <BuildQuizzesTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildQuizzesView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZ_PATH_NAME}/:quizId`}
        >
          <BuildQuizzesView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </Route>
        <Route
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/:compliancePlan?`}
        >
          <ManagePlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`}
        >
          <TrainingMode />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.HEADLESS_PATH_NAME}/:documentId`}
        >
          <SignalDocument />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
        >
          <ReportsCompliancePlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
        >
          <ReportsPlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id/:compliancePlan?`}
        >
          <ReportsPlansView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ReportsTeamMembersTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id`}
        >
          <ReportsTeamMembersView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId`}
        >
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.doc/id" */ '../pages/Document/DocumentRouteHandler'
              ),
          )}
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.doc/id" */ '../pages/Document/DocumentRouteHandler'
              ),
          )}
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.doc/id" */ '../pages/Document/DocumentRouteHandler'
              ),
          )}
        </Route>
        {featureflagAscendNav && (
          <Route
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`}
          >
            {lazyComponent(
              () =>
                import(
                  // @ts-ignore
                  /* webpackChunkName: "route.leadership" */ '../pages/Explore/Leadership/LeadershipTab'
                ),
            )}
          </Route>
        )}
        <Route exact path="/">
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.categories" */ '../pages/Explore/Operations/OperationsTab'
              ),
          )}
        </Route>
      </NotFoundSwitch>
    </Suspense>
  );
};

// Web uses the okta <Security> wrapper to check for auth
const SecureRouteHandledByWeb: React.FC = () => {
  const { ascendNav: featureflagAscendNav } = useFlags();

  return (
    <Suspense fallback={<Placeholder />}>
      <NotFoundSwitch>
        <SecureRoute exact path="/category/:categoryId">
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.subcategories" */ '../pages/Explore/Operations/SubcategoryPage'
              ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`}
        >
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.settings" */ '../pages/Settings/SettingsPage'
              ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`}
        >
          <SearchResultsPage />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.BINARY_PATH_NAME}/:documentId`}
        >
          <PrintView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}
        >
          <MyPlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildPlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/:planId`}
        >
          <EditPlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TO_COMPLETE_PATH_NAME}`}
        >
          <DocumentProxy />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/:planId`}
        >
          <AssignedPlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
        >
          <ManagePlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
        >
          <BuildQuizzesTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildQuizzesView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZ_PATH_NAME}/:quizId`}
        >
          <BuildQuizzesView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </SecureRoute>
        <SecureRoute
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ManagePlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`}
        >
          <TrainingMode />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.HEADLESS_PATH_NAME}/:documentId`}
        >
          <SignalDocument />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
        >
          <ReportsCompliancePlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
        >
          <ReportsPlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id/:compliancePlan?`}
        >
          <ReportsPlansView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ReportsTeamMembersTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id`}
        >
          <ReportsTeamMembersView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId`}
        >
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.doc/id" */ '../pages/Document/DocumentRouteHandler'
              ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.doc/id" */ '../pages/Document/DocumentRouteHandler'
              ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.doc/id" */ '../pages/Document/DocumentRouteHandler'
              ),
          )}
        </SecureRoute>
        {featureflagAscendNav && (
          <SecureRoute
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`}
          >
            {lazyComponent(
              () =>
                import(
                  // @ts-ignore
                  /* webpackChunkName: "route.leadership" */ '../pages/Explore/Leadership/LeadershipTab'
                ),
            )}
          </SecureRoute>
        )}
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`}
        >
          <Admin />
        </SecureRoute>
        <SecureRoute exact path="/">
          {lazyComponent(
            () =>
              import(
                /* webpackChunkName: "route.categories" */ '../pages/Explore/Operations/OperationsTab'
              ),
          )}
        </SecureRoute>
      </NotFoundSwitch>
    </Suspense>
  );
};

const WhichRoutes: React.FC = () => {
  const isNative = useIsNative();

  return isNative ? (
    <SecureRoutesHandledByNative />
  ) : (
    <SecureRouteHandledByWeb />
  );
};

export default WhichRoutes;
