import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import { messageReactNative } from 'utils/messageReactNative';
// @ts-ignore
import { generateReport, printToNative } from 'utils/generateReport';
import { convertDateForReports } from 'utils/date';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setHeader } from 'store/header/slice';
import { hidePrintReport } from 'store/printReport/slice';
import {
  clearPlansCategoryFilter,
  clearPlansSearchFilter,
  clearPlansStatusFilter,
  loadMorePlans,
  setPlans,
  setPlansCategoryFilter,
  setPlansSearchFilter,
  setPlansSort,
  setPlansStatusFilter,
} from 'store/reports/slice';
import {
  selectSearchFilter,
  selectFilters,
  selectSort,
  selectPlansPagination,
  selectSortedPaginatedAndFilteredPlans,
  selectSortedAndFilteredPlans,
} from 'store/reports/selector';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components/dist/cjs';
import { Link, useParams } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import { useGetReportsPlansByUserQuery } from 'services/pathwayApi';
import { printReport } from 'store/printReport/selectors';
import { isUserLicensee } from 'store/user/selectors';
import {
  ComplianceCourseEnrollmentDTO,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import CheckboxFilterSection from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import StickyFilterCard from '@/components/Cards/StickyFilterCard/StickyFilterCard';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import NoMessage from '@/pages/TrainingPlans/SharedComponents/NoMessage';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';
import { useSortOptions } from '@/hooks/useSortOptions';
import { ComplianceCourse, LanguageObject } from '@/types/types';
import ReportsTeamMemberPlanCard from '@/components/Cards/PlanCard/PlanCards/ReportsTeamMemberPlanCard';
import ReportsTeamMemberCompliancePlanCard from '@/components/Cards/PlanCard/PlanCards/ReportsTeamMemberCompliancePlanCard';

const PlansView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const breakpoints = useBreakpoints();
  const sortOptions = useSortOptions({ reportsTeamMembersView: true });
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const searchFilter = useSelector(selectSearchFilter);
  const currentFilters = useSelector(selectFilters);
  const categoryLabels = useCategoryLabels(currentFilters);
  const sort = useSelector(selectSort);
  const isLicenseeUser = useSelector(isUserLicensee);
  const { showing, total } = useSelector(selectPlansPagination);
  const plans = useSelector(selectSortedPaginatedAndFilteredPlans);
  const plansToPrint = useSelector(selectSortedAndFilteredPlans);
  const printReportStatus = useSelector(printReport);

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  const { data, isFetching, isSuccess } = useGetReportsPlansByUserQuery(
    {
      userId: id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  useEffect(() => {
    let plansData = [] as UserChecklistResultDTO[];
    let courses = [] as ComplianceCourseEnrollmentDTO[];

    // Non-Compliance Plans
    if (isSuccess && data.checklistResults) {
      plansData = data.checklistResults;
    }
    // Compliance Plans
    if (isSuccess && data.coursesResults?.[0]?.enrollments) {
      courses =
        data?.coursesResults?.[0]?.enrollments &&
        (Object.keys(data?.coursesResults?.[0]?.enrollments).map(
          key => data?.coursesResults?.[0]?.enrollments?.[key],
        ) as ComplianceCourseEnrollmentDTO[]);
    }

    const combinedPlans = plansData.concat(courses as any);

    if (isLicenseeUser) {
      dispatch(setPlans({ plans: plansData }));
    } else {
      dispatch(
        setPlans({
          plans: combinedPlans,
        }),
      );
    }
  }, [dispatch, isSuccess, data, isLicenseeUser]);

  useEffect(() => {
    if (plansToPrint && printReportStatus) {
      onPrintReport(plansToPrint);
      dispatch(hidePrintReport());
    }
    //eslint-disable-next-line
  }, [dispatch, plansToPrint, printReportStatus]);

  const getTimeString = (time: number) => {
    const timeSpentHrs = Math.floor(time / 60);
    const timeSpentMins = time % 60;
    return `${
      timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
    } ${timeSpentMins} ${t('Generic.mins')}`;
  };

  const onPrintReport = (plansData: UserChecklistResultDTO[]) => {
    const statusReportToPrint = [...plansData]
      .sort((planA, planB) => {
        return planA?.checklist
          ? getNameFromLanguage(
              planA?.checklist?.name as LanguageObject,
            ).localeCompare(
              getNameFromLanguage(planB?.checklist?.name as LanguageObject),
            )
          : getNameFromLanguage(
              (planA as ComplianceCourse)?.courseName,
            ).localeCompare(
              getNameFromLanguage((planB as ComplianceCourse)?.courseName),
            );
      })
      .map(plan => {
        return {
          [Constants.REPORT_TABLE.PLAN]: plan.checklist
            ? getNameFromLanguage(plan.checklist.name as LanguageObject)
            : getNameFromLanguage((plan as ComplianceCourse).courseName),
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]:
            plan.checklist && plan.status && plan.status[0]
              ? `${plan.status[0].stepsComplete}/${plan.checklist.stepsTotal}`
              : `${(plan as ComplianceCourse).percentComplete}%`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]: (
            plan as ComplianceCourse
          ).courseName
            ? getTimeString(
                Math.ceil(
                  ((plan as ComplianceCourse)?.duration as number) / 60,
                ),
              )
            : plan.checklist && plan.status
            ? `${getTimeString(
                plan.status[0]?.steps?.reduce(
                  (acc, step) =>
                    acc + Math.round((step?.stepDuration ?? 0) / 60),
                  0,
                ) ?? 0,
              )}`
            : t('Generic.na'),
          [Constants.REPORT_TABLE.STATUS]:
            plan.checklist && plan.status && plan.status[0]
              ? t(
                  `TrainingPlans.statusOptions.${
                    // @ts-ignore until P-1030 is finished
                    Constants.TRAINING_PLANS_STATUSES[plan.status[0].status]
                  }`,
                )
              : t(
                  `TrainingPlans.statusOptions.${
                    // @ts-ignore until P-1030 is finished
                    Constants.LEARN_UPON_TRAINING_PLAN_STATUSES[plan.status]
                  }`,
                ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            plan.checklist && plan?.status?.[0] && plan.status[0].completionDate
              ? convertDateForReports(plan.status[0].completionDate)
              : getNameFromLanguage(
                  (plan as ComplianceCourse)?.courseName,
                )?.includes(Constants.FOOD_SAFETY_COURSE_NAME) &&
                // @ts-ignore until P-1030 is finished
                plan.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED
              ? convertDateForReports(
                  (plan as ComplianceCourse).completedDate as string,
                )
              : (plan as ComplianceCourse).courseName &&
                (plan as ComplianceCourse).completedDate &&
                !getNameFromLanguage(
                  (plan as ComplianceCourse)?.courseName,
                )?.includes(Constants.FOOD_SAFETY_COURSE_NAME)
              ? convertDateForReports(
                  (plan as ComplianceCourse).completedDate as string,
                )
              : t('Generic.na'),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            (plan as ComplianceCourse)?.certificate?.expiration &&
            // @ts-ignore until P-1030 is finished
            plan?.status !== Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
              ? convertDateForReports(
                  (plan as ComplianceCourse)?.certificate?.expiration as string,
                )
              : t('Generic.na'),
        };
      });

    const complianceReport = statusReportToPrint
      .filter(teamMember =>
        (teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED] as string).includes(
          '%',
        ),
      )
      .map(user => {
        return {
          ...user,
          [Constants.REPORT_TABLE.COMPLIANCE_PLAN_NAME]:
            user[Constants.REPORT_TABLE.PLAN],
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            user[Constants.REPORT_TABLE.EXPIRATION_DATE],
        };
      });

    const storeReport = statusReportToPrint.filter(
      teamMember =>
        !(
          teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED] as string
        ).includes('%'),
    );

    const reportToGenerate = {
      category: t('Generic.teamMember'),
      isTeamMember: true,
      name: data?.user?.name,
      complianceReport: complianceReport.map(teamMember => [
        teamMember['Compliance Plan Name'],
        teamMember[Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN],
        teamMember[Constants.REPORT_TABLE.COMPLETION_DATE],
        teamMember[Constants.REPORT_TABLE.EXPIRATION_DATE],
      ]),
      complianceTableHead: Constants.COMPLIANCE_TABLE_HEAD,
      reportToPrint: storeReport.map(teamMember => [
        teamMember[Constants.REPORT_TABLE.PLAN],
        teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED],
        teamMember[Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN],
        teamMember[Constants.REPORT_TABLE.STATUS],
        teamMember[Constants.REPORT_TABLE.COMPLETION_DATE],
      ]),
      tableHead: Constants.TABLE_HEAD,
    };

    const messageToSend = {
      category: reportToGenerate.category,
      name: reportToGenerate.name,
      reportToPrint: statusReportToPrint,
      tableHead: Constants.TABLE_HEAD,
    };

    generateReport(reportToGenerate);
    messageReactNative(
      Constants.RN_MESSAGE_TYPES.PRINT,
      printToNative(messageToSend),
    );
  };

  if (isLicenseeUser) {
    delete categoryLabels?.[Constants.PLAN_CATEGORIES.COMPLIANCE];
  }

  const statusLabels = {
    [Constants.TRAINING_PLANS.COMPLETED]: {
      translationString: t('TrainingPlans.statusOptions.completed'),
      value: !!(currentFilters as string[]).includes(
        Constants.TRAINING_PLANS.COMPLETED,
      ),
    },
    [Constants.TRAINING_PLANS.NOT_COMPLETED]: {
      translationString: t('TrainingPlans.statusOptions.notCompleted'),
      value: !!(currentFilters as string[]).includes(
        Constants.TRAINING_PLANS.NOT_COMPLETED,
      ),
    },
  };

  return (
    <>
      <StyledContent>
        <StyledLinkWrapper
          to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <StyledBackArrow />
          <StyledBackArrowText variant="body2">
            {t('Reports.backToTeamMembers')}
          </StyledBackArrowText>
        </StyledLinkWrapper>
        <SearchFilterHeader
          onChange={e =>
            dispatch(setPlansSearchFilter({ searchFilter: e.target.value }))
          }
          onClear={() => dispatch(clearPlansSearchFilter())}
          searchPlaceholder={t('Reports.filtering.searchForPlans')}
          searchValue={searchFilter}
          title={data?.user?.name ?? ''}
        />
        {!!isSmAndDown && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setPlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              plans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
            top={Constants.HEIGHT.MOBILE_TOP_NAV}
          >
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!(currentFilters as string[]).includes(value)) {
                  dispatch(clearPlansCategoryFilter({ filter: value }));
                } else {
                  dispatch(setPlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
            <CheckboxFilterSection
              labels={statusLabels}
              onChange={value => {
                if (!!(currentFilters as string[]).includes(value)) {
                  dispatch(clearPlansStatusFilter({ filter: value }));
                } else {
                  dispatch(setPlansStatusFilter({ filter: value }));
                }
              }}
              title={t('Generic.status')}
            />
          </FilterAndSortButton>
        )}
        <PlansList>
          {!isSmAndDown && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!(currentFilters as string[]).includes(value)) {
                    dispatch(clearPlansCategoryFilter({ filter: value }));
                  } else {
                    dispatch(setPlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
              <CheckboxFilterSection
                labels={statusLabels}
                onChange={value => {
                  if (!!(currentFilters as string[]).includes(value)) {
                    dispatch(clearPlansStatusFilter({ filter: value }));
                  } else {
                    dispatch(setPlansStatusFilter({ filter: value }));
                  }
                }}
                title={t('Generic.status')}
              />
            </StickyFilterCard>
          )}
          <PlansContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setPlansSort({ sort: option?.value }));
              }}
              onClear={() => {
                // @ts-ignore
                dispatch(clearPlansCategoryFilter());
              }}
              options={sortOptions}
              showClear={false}
              showCompletedPlansOption={false}
              showMyCompletedPlansOnlySwitchValue={false}
              text={`${total ?? 0} ${t('Reports.tabPlans')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />
            <PlansCardsList>
              <LoadingOverlay isOpen={isFetching} />
              {(plans as any[])
                .filter(enrolledCourse => !enrolledCourse.operatorDisabled)
                .map((plan, index) =>
                  !!plan.checklist ? (
                    <ReportsTeamMemberPlanCard
                      key={plan?.checklist?.id}
                      plan={plan?.checklist}
                      status={plan?.status}
                      teamMemberId={data?.user?.adId as string}
                    />
                  ) : (
                    <ReportsTeamMemberCompliancePlanCard
                      course={plan as ComplianceCourse}
                      key={index}
                    />
                  ),
                )}
              {!plans?.length && !currentFilters?.length && !searchFilter && (
                <NoMessage message={t('TrainingPlans.noTrainingPlans')} />
              )}
              {(!!currentFilters?.length || !!searchFilter) && !total && (
                <NoMessage message={t('TrainingPlans.noPlansResults')} />
              )}
            </PlansCardsList>
            <LoadMorePaginator
              onClick={() => dispatch(loadMorePlans())}
              showing={showing}
              showingText={t('TrainingPlans.showingXOfYPlans', {
                showing,
                total: total ?? 0,
              })}
              total={total ?? 0}
            />
          </PlansContainer>
        </PlansList>
      </StyledContent>
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const StyledLinkWrapper = styled(Link)`
  display: flex;
  flexdirection: row;
  cursor: pointer;
  max-width: 250px;
`;

const StyledBackArrow = styled(IconArrowLeft)`
  margin-right: 0.5em;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
`;

const StyledBackArrowText = styled(Typography)`
  transform: translateY(1px);
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
`;

const PlansList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlansContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const PlansCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  gap: 8px;
`;

export default withRoles(
  PlansView,
  [],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
