import Constants from 'constants/index';
import { isApiError } from 'utils/request';
import { useDeviceInfo } from 'utils/device';
import { getNameFromLanguage } from 'utils/language';
import { getAdminCategoryDetails } from 'utils/getAdminCategoryDetails';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Dropdown,
  Icon,
  Row,
  Typography,
} from 'cfa-react-components';
import { arrayMoveImmutable } from 'array-move';
import {
  Container as DragAndDropContainer,
  Draggable,
  DropResult,
} from 'react-smooth-dnd';
import { useTranslation } from 'react-i18next';
import {
  isUserInternational,
  selectUsersHighestPermissionLevel,
} from 'store/user/selectors';
import GenericError from 'components/Error/GenericError';
import {
  managingCountry,
  selectIsLoadingAfterEdit,
  selectSelectedAdminCategoryId,
  selectSelectedAdminSubcategoryIndex,
  selectSelectedAdminSubtitleIndex,
  selectShowAddAdminAddProcedureToSubtitle,
  selectTemporaryCategory,
  supportedCountries,
} from 'store/admin/selectors';
import {
  setIsLoadingAfterEdit,
  setIsRecommendCategory,
  setManagingCountry,
  setSelectedCategory,
  setSelectedCategoryId,
  setSelectedCategoryIndex,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setShowAddAdminAddProcedureToSubtitle,
  setTemporaryCategory,
} from 'store/admin/slice';
import {
  useDeleteCategoryMutation,
  useEditCategoriesMutation,
  useEditCategoryMutation,
  useGetAdminCategoriesQuery,
} from 'services/pathwayApi';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { useOktaAuth } from '@okta/okta-react';
import AddProcedurePopUp from 'components/popups/AddProcedurePopUp';
import cloneDeep from 'lodash/cloneDeep';
import { setHeader } from 'store/header/slice';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import { IconPlus } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import ToastMessageBlock from 'components/Toasts/SuccessToast';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import { selectBugsnagInitialized } from 'store/app/selectors';
import AddCategory from './AddCategory';
import EditCategory, { CategoryWithIconAndId } from './EditCategory';
import CardCategory from './CardCategory';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import {
  IntlCategoryEntity,
  IntlSubcategoryEntity,
} from '@/services/content-api-types';
import { LanguageObject } from '@/types/types';
import { Country } from '@/store/user/slice';

interface HrefStyleProps {
  $isDesktop: boolean;
}

const Admin = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bugsnagInitialized = useSelector(selectBugsnagInitialized);
  const isInternationalUser = useSelector(isUserInternational);
  const selectedCountry = useSelector(managingCountry);
  const selectableCountries = useSelector(supportedCountries);
  const showAdminFunctionality = true;
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const selectedCategoryId = useSelector(selectSelectedAdminCategoryId);
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  const isLoadingAfterEdit = useSelector(selectIsLoadingAfterEdit);
  const showAddAdminAddProcedureToSubtitle = useSelector(
    selectShowAddAdminAddProcedureToSubtitle,
  );
  const adminSelectedSubcategoryIndexForAddProcedurePopup = useSelector(
    selectSelectedAdminSubcategoryIndex,
  );
  const adminSelectedSubtitleIndexForAddProcedurePopup = useSelector(
    selectSelectedAdminSubtitleIndex,
  );
  const temporaryCategoryForAddingProcedure = useSelector(
    selectTemporaryCategory,
  );
  const userIsAdmin = Constants.USER_PERMISSIONS.ADMIN === userPermissionLevel;

  const { authState } = useOktaAuth();
  const {
    data: categoryData,
    isFetching,
    error,
    refetch: refetchAdminCategories,
  } = useGetAdminCategoriesQuery(
    { countryCode: selectedCountry.LABEL_KEY },
    {
      skip: !userIsAdmin,
      refetchOnMountOrArgChange: true,
    },
  );
  const [editCategories] = useEditCategoriesMutation();
  const [editCategory] = useEditCategoryMutation();
  const [categories, setCategories] = useState<IntlCategoryEntity[]>();
  const [recommended, setRecommended] = useState<IntlCategoryEntity[]>();
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddRecommendedCategory, setShowAddRecommendedCategory] =
    useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [showConfirmationVisibilityPopUp, setShowConfirmationVisibilityPopUp] =
    useState(false);
  const [category, setCategory] = useState<CategoryWithIconAndId>({
    id: '',
    displayIndex: 0,
    enabled: false,
    featured: false,
    icon: '',
    // @ts-ignore the BE has a string as a type but it should be a LanguageObject
    name: { en: '', es: '' },
    subcategories: [],
  });
  const [categoryForVisibility, setCategoryForVisibility] = useState({
    enabled: false,
    featured: false,
    index: '',
    name: '',
  });

  const [deleteCategory] = useDeleteCategoryMutation();

  useEffect(() => {
    dispatch(setHeader(t('Generic.admin')));
  }, [dispatch, t]);

  useEffect(() => {
    if (categoryData?.categories) {
      setCategories(categoryData.categories);
    }

    if (categoryData?.featured) {
      setRecommended(categoryData.featured);
    }
  }, [categoryData]);

  if (!userIsAdmin) {
    return <GenericError />;
  }

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  const onCategoryEdit = (
    selectedCategory: IntlCategoryEntity,
    categoryIndex: any,
    isRecommended: boolean,
  ) => {
    setCategory({
      displayIndex: selectedCategory.displayIndex,
      enabled: selectedCategory.enabled as boolean,
      featured: selectedCategory.featured as boolean,
      icon: selectedCategory.icon as string,
      id: selectedCategory.id as string,
      // @ts-ignore the BE has a string as a type but it should be a LanguageObject
      name: {
        en: selectedCategory?.name?.en as string,
        es: selectedCategory?.name?.es as string,
      },
      subcategories: selectedCategory.subcategories as IntlSubcategoryEntity[],
    });
    dispatch(setSelectedCategory(selectedCategory));
    dispatch(setSelectedCategoryIndex(categoryIndex));
    dispatch(setIsRecommendCategory(isRecommended));
    setIsEditCategory(true);
  };

  const onCategoryDelete = ({
    name,
    isFeaturedCategory,
  }: {
    name: LanguageObject;
    isFeaturedCategory: boolean;
  }) => {
    setIsFeatured(isFeaturedCategory);
    setCategoryName(getNameFromLanguage(name));
    setIsEditCategory(false);
    setShowDeleteCategory(true);
  };

  const onDeleteCategory = (name: string) => {
    if (name) {
      setIsEditCategory(false);
    }

    deleteCategory({
      countryCode: selectedCountry.LABEL_KEY,
      id: selectedCategoryId ?? '',
    })
      .unwrap()
      .then(() => {
        refetchAdminCategories();
        setShowDeleteCategory(false);
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('Admin.deleteCategoryToast', {
              categoryName: typeof name === 'string' ? name : categoryName,
            })}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        console.error(err);
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id} severity="error">
            {Constants.IS_IN_CYPRESS_TEST_LOCAL || Constants.IS_IN_JEST_TEST
              ? 'Delete Category Fail In Cypress Test'
              : err.error}
          </ToastMessageBlock>
        ));
        setShowDeleteCategory(false);
        notifyBugsnag(err);
      });
  };

  const onShowToggleVisibilityPopUp = (
    cat: IntlCategoryEntity,
    index: string,
  ) => {
    setCategoryForVisibility({
      ...categoryForVisibility,
      enabled: cat.enabled as boolean,
      featured: cat.featured as boolean,
      index,
      name: getNameFromLanguage(cat.name as LanguageObject),
    });
    setShowConfirmationVisibilityPopUp(true);
  };

  const updateArrayIndex = (
    array: any,
    removedIndex: number,
    addedIndex: number,
  ) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(
      (item, idx) => {
        return { ...(item as any), displayIndex: idx };
      },
    );
  };

  const onDrop = (type: string, dropResult: DropResult) => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex } = dropResult;

    const updatedCategories =
      type === Constants.DRAGGABLE_TYPES.CATEGORY
        ? updateArrayIndex(categories, removedIndex, addedIndex as number)
        : categories;
    setCategories(updatedCategories);

    const updatedRecommended =
      type === Constants.DRAGGABLE_TYPES.RECOMMENDED
        ? updateArrayIndex(recommended, removedIndex, addedIndex as number)
        : recommended;
    setRecommended(updatedRecommended);

    const payload =
      type === Constants.DRAGGABLE_TYPES.CATEGORY
        ? updatedCategories
        : updatedRecommended;
    editCategories(payload as IntlCategoryEntity[])
      .unwrap()
      .then(() => {
        refetchCategories();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const toggleCategoryVisibility = (
    categoryIndex: any,
    isRecommended: boolean,
  ) => {
    setShowConfirmationVisibilityPopUp(false);
    let updatedCategory: IntlCategoryEntity;
    const slug = isRecommended
      ? recommended?.[categoryIndex]?.id
      : categories?.[categoryIndex]?.id;
    dispatch(setIsLoadingAfterEdit(true));
    getAdminCategoryDetails(
      authState,
      slug as string,
      selectedCountry.LABEL_KEY,
      isInternationalUser,
      bugsnagInitialized,
    )
      .then(details => {
        updatedCategory = isRecommended
          ? {
              ...details,
              enabled: !recommended?.[categoryIndex]?.enabled,
            }
          : {
              ...details,
              enabled: !categories?.[categoryIndex]?.enabled,
            };
      })
      .then(() => editCategory(updatedCategory as any))
      .then(() => {
        refetchAdminCategories();
      })
      .catch(e => {
        notifyBugsnag(e);
      })
      .finally(() => {
        if (isRecommended) {
          dispatch(setSelectedCategory(recommended?.[categoryIndex]));
        } else {
          dispatch(setSelectedCategory(categories?.[categoryIndex]));
        }
        dispatch(setIsLoadingAfterEdit(false));
      });
  };

  const handleChangeManageCountry = (countrySelected: string) => {
    dispatch(setManagingCountry(countrySelected));
    setShowAddRecommendedCategory(false);
    setShowAddCategory(false);
  };

  const refetchCategories = () => {
    setShowAddCategory(false);
    setShowAddRecommendedCategory(false);
    refetchAdminCategories();
  };

  const handleAddProcedureToSubtitleClose = () => {
    dispatch(setShowAddAdminAddProcedureToSubtitle(false));
  };

  const handleAdminAddProcedure = async (selectedDocuments: any) => {
    const clonedCategory = cloneDeep(temporaryCategoryForAddingProcedure);
    clonedCategory.subcategories[ // @ts-ignore
      adminSelectedSubcategoryIndexForAddProcedurePopup
    ].subtitles[adminSelectedSubtitleIndexForAddProcedurePopup].documents =
      clonedCategory.subcategories[ // @ts-ignore
        adminSelectedSubcategoryIndexForAddProcedurePopup
      ].subtitles[adminSelectedSubtitleIndexForAddProcedurePopup].documents
        .concat(selectedDocuments);

    dispatch(setSelectedSubcategoryIndex(null));
    dispatch(setSelectedSubtitleIndex(null));

    dispatch(setShowAddAdminAddProcedureToSubtitle(false));
    dispatch(setTemporaryCategory(clonedCategory));
  };

  return (
    <>
      <AddProcedurePopUp
        adminAddingProcedure={true}
        isOpen={showAddAdminAddProcedureToSubtitle}
        onAdminAddProcedure={handleAdminAddProcedure}
        onClose={handleAddProcedureToSubtitleClose}
        plan={{}}
        refetch={refetchAdminCategories}
        selectedCountryCode={selectedCountry?.LABEL_KEY}
      />
      {isEditCategory ? (
        <EditCategory
          category={category}
          countryName={selectedCountry.LABEL_KEY}
          isCanada={
            selectedCountry.LABEL_KEY ===
            Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
          }
          onBack={() => setIsEditCategory(false)}
          onCategoryDelete={name => onDeleteCategory(name)}
          refetchCategories={refetchAdminCategories}
        />
      ) : (
        <>
          <LoadingOverlay isOpen={isFetching || isLoadingAfterEdit} />
          <Row>
            <Col>
              <StyledAdminContentHeader>
                <StyledDropdownWithCapitalizedLabel
                  data-testid="AdminManageCountryDropDownMenu"
                  getOptionId={country => (country as Country).ID as string}
                  getOptionText={country =>
                    t(`Admin.${(country as Country).LABEL_KEY}`)
                  }
                  label={t('Admin.managingCountry')}
                  onChange={newValue =>
                    handleChangeManageCountry(newValue as string)
                  }
                  options={selectableCountries}
                  renderOption={country =>
                    t(`Admin.${(country as Country).LABEL_KEY}`)
                  }
                  value={selectedCountry}
                />
              </StyledAdminContentHeader>
              <Row>
                {recommended?.length ? (
                  <>
                    <RecommendedAdminHeader>
                      {t('Admin.recommended')}
                    </RecommendedAdminHeader>
                    <ContainerWrapper>
                      {/** @ts-ignore it's yelling about children but we need children for the drag and drop */}
                      <DragAndDropContainer
                        className="StyledDragAndDropContainer"
                        dragHandleSelector=".recommend-drag-handle"
                        dropPlaceholder={{
                          className: 'shadow-on-drop',
                        }}
                        onDrop={e =>
                          onDrop(Constants.DRAGGABLE_TYPES.RECOMMENDED, e)
                        }
                      >
                        {recommended.map(
                          (featuredCategory, featuredCategoryIndex) => (
                            //@ts-ignore it's yelling about children but we need children for the drag and drop
                            <Draggable
                              key={`${featuredCategory.id}-${featuredCategoryIndex}`}
                            >
                              <StyledHref
                                $isDesktop={isDesktopWidth}
                                key={featuredCategory.id}
                              >
                                {
                                  // @ts-ignore overload props error
                                  <AdminCardCategory
                                    category={featuredCategory}
                                    categoryIsVisible={
                                      featuredCategory.enabled as boolean
                                    }
                                    className="recommend-drag-handle"
                                    handleCategoryAdminPopupMenu={() =>
                                      dispatch(
                                        setSelectedCategoryId(
                                          featuredCategory.id,
                                        ),
                                      )
                                    }
                                    handleCategoryVisibility={() =>
                                      onShowToggleVisibilityPopUp(
                                        featuredCategory,
                                        featuredCategoryIndex.toString(),
                                      )
                                    }
                                    isAdminPage={true}
                                    onCategoryDelete={() =>
                                      onCategoryDelete({
                                        name: featuredCategory.name as LanguageObject,
                                        isFeaturedCategory: true,
                                      })
                                    }
                                    onCategoryEdit={() =>
                                      onCategoryEdit(
                                        featuredCategory,
                                        featuredCategoryIndex,
                                        true,
                                      )
                                    }
                                    showAdminFunctionality={
                                      showAdminFunctionality
                                    }
                                  />
                                }
                              </StyledHref>
                            </Draggable>
                          ),
                        )}
                      </DragAndDropContainer>
                    </ContainerWrapper>
                  </>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    onClick={() => setShowAddRecommendedCategory(prev => !prev)}
                    variant="text"
                  >
                    <StyledPlusIcon icon={IconPlus} size="sm" />
                    <Typography
                      color="secondary"
                      data-testid="AddRecommendedCategory"
                      variant="body1"
                    >
                      {t('Admin.addRecommended')}
                    </Typography>
                  </Button>
                  {showAddRecommendedCategory && (
                    <AddCategory
                      categoriesArrayLength={recommended?.length}
                      isRecommended={true}
                      onCancel={() => setShowAddRecommendedCategory(false)}
                      onClose={refetchCategories}
                      selectedCountry={selectedCountry.LABEL_KEY}
                      translationNotRequired={[
                        Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY,
                        Constants.ADMIN_MANAGING_COUNTRIES.GB.LABEL_KEY,
                      ].includes(selectedCountry.LABEL_KEY)}
                    />
                  )}
                </Col>
                {categories?.length ? (
                  <>
                    <CategoriesAdminHeader>
                      {t('Admin.categories')}
                    </CategoriesAdminHeader>
                    <ContainerWrapper>
                      {/** @ts-ignore it's yelling about children but we need children for the drag and drop */}
                      <DragAndDropContainer
                        className="StyledDragAndDropContainer"
                        dragHandleSelector=".category-drag-handle"
                        dropPlaceholder={{
                          className: 'shadow-on-drop',
                        }}
                        onDrop={e =>
                          onDrop(Constants.DRAGGABLE_TYPES.CATEGORY, e)
                        }
                      >
                        {categories.map((mappedCategory, categoryIndex) => (
                          // @ts-ignore it's yelling about children but we need children for the drag and drop
                          <Draggable
                            key={`${mappedCategory.id}-${categoryIndex}`}
                          >
                            <StyledHref
                              $isDesktop={isDesktopWidth}
                              key={mappedCategory.id}
                            >
                              {
                                // @ts-ignore overload props error
                                <AdminCardCategory
                                  category={mappedCategory}
                                  categoryIsVisible={
                                    mappedCategory.enabled as boolean
                                  }
                                  className="category-drag-handle"
                                  handleCategoryAdminPopupMenu={() =>
                                    dispatch(
                                      setSelectedCategoryId(mappedCategory.id),
                                    )
                                  }
                                  handleCategoryVisibility={() => {
                                    onShowToggleVisibilityPopUp(
                                      mappedCategory,
                                      categoryIndex.toString(),
                                    );
                                  }}
                                  isAdminPage={true}
                                  onCategoryDelete={() => {
                                    onCategoryDelete({
                                      name: mappedCategory.name as LanguageObject,
                                      isFeaturedCategory: false,
                                    });
                                  }}
                                  onCategoryEdit={() =>
                                    onCategoryEdit(
                                      mappedCategory,
                                      categoryIndex,
                                      false,
                                    )
                                  }
                                  showAdminFunctionality={
                                    showAdminFunctionality
                                  }
                                />
                              }
                            </StyledHref>
                          </Draggable>
                        ))}
                      </DragAndDropContainer>
                    </ContainerWrapper>
                  </>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    onClick={() => setShowAddCategory(prev => !prev)}
                    variant="text"
                  >
                    <StyledPlusIcon icon={IconPlus} size="sm" />
                    <Typography color="secondary" data-testid="AddCategory">
                      {t('Admin.addCategory')}
                    </Typography>
                  </Button>
                  {showAddCategory && (
                    <AddCategory
                      categoriesArrayLength={categories?.length}
                      onCancel={() => setShowAddCategory(false)}
                      onClose={refetchCategories}
                      selectedCountry={selectedCountry.LABEL_KEY}
                      translationNotRequired={[
                        Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY,
                        Constants.ADMIN_MANAGING_COUNTRIES.GB.LABEL_KEY,
                      ].includes(selectedCountry.LABEL_KEY)}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <ConfirmationModal
              bodyText={t('Generic.deleteObject', {
                name: categoryName,
                object: isFeatured
                  ? t('Admin.recommendedCategory').toLowerCase()
                  : t('Admin.category').toLowerCase(),
              })}
              headerText={t('Generic.deleteHeader', {
                type: isFeatured
                  ? t('Admin.recommendedCategory')
                  : t('Admin.category'),
              })}
              isOpen={showDeleteCategory}
              onClose={() => setShowDeleteCategory(false)}
              // @ts-ignore
              primaryButtonHandler={onDeleteCategory}
              primaryButtonText={t('Button.delete')}
              primaryButtonVariant="destructive"
              secondaryButtonHandler={prev => setShowDeleteCategory(!prev)}
              secondaryButtonText={t('Button.cancel')}
            />
            <ConfirmationModal
              bodyText={
                categoryForVisibility.enabled
                  ? t('Admin.immediatelyMakeHiddenCategoryBody', {
                      categoryName: categoryForVisibility.name,
                      countryName: selectedCountry.LABEL_KEY,
                    })
                  : t('Admin.immediatelyMakeVisibleCategoryBody', {
                      categoryName: categoryForVisibility.name,
                      countryName: selectedCountry.LABEL_KEY,
                    })
              }
              headerText={
                categoryForVisibility.enabled
                  ? t('Admin.makeHiddenHeader')
                  : t('Admin.makeVisibleHeader')
              }
              isOpen={showConfirmationVisibilityPopUp}
              onClose={() => setShowConfirmationVisibilityPopUp(false)}
              primaryButtonColor={
                categoryForVisibility.enabled ? 'primary' : 'secondary'
              }
              primaryButtonHandler={() =>
                toggleCategoryVisibility(
                  categoryForVisibility.index,
                  categoryForVisibility.featured,
                )
              }
              primaryButtonText={
                categoryForVisibility.enabled
                  ? t('Button.makeHidden')
                  : t('Button.makeVisible')
              }
              secondaryButtonHandler={prev =>
                setShowConfirmationVisibilityPopUp(!prev)
              }
              secondaryButtonText={t('Button.cancel')}
            />
          </Row>
        </>
      )}
    </>
  );
};

const AdminCardCategory = styled(CardCategory)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const CategoriesAdminHeader = styled(Typography)`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledHref = styled.div<HrefStyleProps>`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;

const StyledPlusIcon = styled(Icon)`
  margin-right: 8px;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const RecommendedAdminHeader = styled(Typography)`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const ContainerWrapper = styled.div`
  width: 100%;
  .shadow-on-drop {
    background-color: ${({ theme }) => theme.grayScale.gray2};
    padding: 10px;
    border-radius: 5px;
  }
`;

const StyledDropdownWithCapitalizedLabel = styled(Dropdown)`
  margin-top: 1em;
  .cfa-dropdown-label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledAdminContentHeader = styled.div`
  margin-bottom: 48px;
`;
export default withRoles(Admin, [Constants.USER_PERMISSIONS.ADMIN], []);
