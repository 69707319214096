import Constants from 'constants/index';
// @ts-ignore
import { generateReport, printToNative } from 'utils/generateReport';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilters } from 'store/managePlansFilter/selector';
import {
  useGetReportsPlansAssignableQuery,
  useGetAssignedStatusQuery,
  useGetCourseReportQuery as useGetCourseReportQueryOld,
  useGetReportsTeamMembersQuery,
} from 'services/pathwayApi';
import { setManagePlans } from 'store/managePlansFilter/slice';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectLocationsWithOperatorPermission,
  isUserLicensee,
  selectUserLicenseeLocations,
  selectUserIsLicenseeStaff,
} from 'store/user/selectors';
// @ts-ignore
import uniqBy from 'lodash/uniqBy';
import {
  useGetCourseReportQuery,
  useGetReportsTrainingPlanStatusesQuery,
  useGetStatusesAssignedToChecklistQuery,
} from 'services/xpApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  CourseReportDTO,
  UserChecklistResultDTO,
  UserChecklistStatusResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { convertDateForReports } from '@/utils/date';
import { messageReactNative } from '@/utils/messageReactNative';
import { arrayToCommaString } from '@/utils/arrayToCommaString';
import { generateTotalTimeFromSteps } from '@/utils/time';
import PrintReportModal from '@/components/popups/PrintReportModal';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import { getNameFromLanguage } from '@/utils/language';
import { setHeader } from '@/store/header/slice';
import { getCompletionDate } from '@/utils/reports';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { LanguageObject, ReportToPrint } from '@/types/types';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';
import CheckboxList from '@/components/Checkbox/CheckboxList';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';

interface PrintReportProps {
  reportToPrint: ReportToPrint;
  turnOffPrintTrigger: () => void;
}

const PrintReport: React.FC<PrintReportProps> = ({
  reportToPrint,
  turnOffPrintTrigger,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const currentFilters: string[] = useSelector(selectFilters);
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const isLicenseeUser = useSelector(isUserLicensee);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const licenseeLocations = isLicenseePlans
    ? userLicenseeLocations?.map(location => location.number) ?? []
    : undefined;

  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastLeaderPermission,
  );

  const isLeaderOrOperator =
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;
  const [triggerTeamMembersReportRefetch, setTriggerTeamMembersReportRefetch] =
    useState(false);
  const [triggerComplianceReportHandler, setTriggerComplianceReportHandler] =
    useState(false);
  const [planDetails, setPlanDetails] = useState({
    category: '',
    id: '',
    isCompliance: false,
    locations: [] as string[],
    name: '',
  });
  const [statusReport, setStatusReport] = useState<CourseReportDTO>({
    courseId: '',
    courseName: '',
    foodSafetyLetters: [],
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const [teamMembersReport, setTeamMembersReport] =
    useState<UserChecklistStatusResponse>({});

  const categoryLabels = useCategoryLabels(currentFilters);

  if (isLicenseeUser || isLicenseePlans || userIsLicenseeStaff) {
    delete categoryLabels?.[Constants.PLAN_CATEGORIES.COMPLIANCE];
  }

  const getPlanLocations = () => {
    if (userIsLicenseeStaff || isLicenseeUser) {
      return activeLicenseeLocation?.number
        ? [activeLicenseeLocation?.number]
        : [];
    }
    if (isLicenseePlans) {
      return licenseeLocations as string[];
    }
    return locationsWithAtLeastLeaderPermission;
  };

  const planLocations = getPlanLocations();

  const { data: oldUnOrderedPlans, isSuccess: isSuccessPlansOld } =
    useGetReportsPlansAssignableQuery(
      {
        locations: planLocations,
      },
      {
        skip:
          !!xpApiFeatureFlag ||
          ((userIsLicenseeStaff || isLicenseeUser) &&
            !activeLicenseeLocation?.number),
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: xpUnOrderedPlans, isSuccess: isSuccessPlansXp } =
    useGetReportsPlansAssignableQuery(
      //TODO proxy does not exist yet, replace with proxy EP when available P-606
      {
        locations: planLocations,
      },
      {
        skip:
          !xpApiFeatureFlag ||
          ((userIsLicenseeStaff || isLicenseeUser) &&
            !activeLicenseeLocation?.number),
        refetchOnMountOrArgChange: true,
      },
    );

  const isSuccess: boolean = xpApiFeatureFlag
    ? isSuccessPlansXp
    : isSuccessPlansOld;

  const unOrderedPlans = xpApiFeatureFlag
    ? xpUnOrderedPlans
    : oldUnOrderedPlans;

  const {
    data: oldStatusReportData,
    isFetching: oldStatusReportIsFetching,
    isSuccess: oldIsStatusReportSuccess,
  } = useGetCourseReportQueryOld(
    {
      courseId: planDetails.id,
      location: selectedReportLocations,
    },
    {
      skip:
        !planDetails.isCompliance ||
        !planDetails.id ||
        !isLeaderOrOperator ||
        !selectedReportLocations.length ||
        !!xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpApiStatusReportData,
    isFetching: xpApiStatusReportIsFetching,
    isSuccess: xpApiIsStatusReportSuccess,
  } = useGetCourseReportQuery(
    {
      courseId: planDetails.id,
      locations: selectedReportLocations,
    },
    {
      skip:
        !planDetails.isCompliance ||
        !planDetails.id ||
        !isLeaderOrOperator ||
        !selectedReportLocations.length ||
        !xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const statusReportData: CourseReportDTO = xpApiFeatureFlag
    ? xpApiStatusReportData
    : (oldStatusReportData as any);
  const statusReportIsFetching: boolean = xpApiFeatureFlag
    ? xpApiStatusReportIsFetching
    : oldStatusReportIsFetching;
  const isStatusReportSuccess: boolean = xpApiFeatureFlag
    ? xpApiIsStatusReportSuccess
    : oldIsStatusReportSuccess;

  // Team Members
  const {
    data: oldReportsTeamMembersData,
    isFetching: oldReportsTeamMembersIsFetching,
    isSuccess: oldIsReportsTeamMembersDataSuccess,
    refetch: oldRefetchReportsTeamMembers,
  } = useGetReportsTeamMembersQuery(
    {
      locations: planLocations,
    },
    {
      skip: !!xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpReportsTeamMemberStatuses,
    isFetching: xpReportsTeamMembersStatusesIsFetching,
    isSuccess: xpReportsTeamMembersStatusesIsSuccess,
    refetch: refetchReportsTeamMembersStatuses,
  } = useGetReportsTrainingPlanStatusesQuery(
    {
      locations: planLocations,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !xpApiFeatureFlag,
    },
  );

  const reportsTeamMembersData: UserChecklistStatusResponse = xpApiFeatureFlag
    ? xpReportsTeamMemberStatuses
    : (oldReportsTeamMembersData as any);
  const reportsTeamMembersIsFetching = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsFetching
    : oldReportsTeamMembersIsFetching;
  const isReportsTeamMembersDataSuccess = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsSuccess
    : oldIsReportsTeamMembersDataSuccess;

  const {
    data: oldAssignedTeamMembersList,
    isFetching: oldAssignedTeamMembersListIsFetching,
    isSuccess: oldIsAssignedTeamMembersListSuccess,
  } = useGetAssignedStatusQuery(
    {
      checklist: planDetails.id,
      location: planLocations,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planDetails.id || planDetails.isCompliance || !!xpApiFeatureFlag,
    },
  );
  const {
    data: xpAssignedTeamMembersList,
    isFetching: xpAssignedTeamMembersListIsFetching,
    isSuccess: xpIsAssignedTeamMembersListSuccess,
  } = useGetStatusesAssignedToChecklistQuery(
    {
      checklistId: planDetails.id,
      locations: planLocations,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planDetails.id || planDetails.isCompliance || !xpApiFeatureFlag,
    },
  );
  const assignedTeamMembersList: UserChecklistResultDTO = xpApiFeatureFlag
    ? xpAssignedTeamMembersList
    : (oldAssignedTeamMembersList as any);
  const assignedTeamMembersListIsFetching: boolean = xpApiFeatureFlag
    ? xpAssignedTeamMembersListIsFetching
    : oldAssignedTeamMembersListIsFetching;
  const isAssignedTeamMembersListSuccess: boolean = xpApiFeatureFlag
    ? xpIsAssignedTeamMembersListSuccess
    : oldIsAssignedTeamMembersListSuccess;

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Set Plans
  useEffect(() => {
    if (isSuccess) {
      const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
        return {
          ...plan,
          planName: getNameFromLanguage(
            plan?.checklist?.name as LanguageObject,
          ).toLowerCase(),
        };
      });
      if (isLicenseeUser || isLicenseePlans) {
        dispatch(setManagePlans({ plans: storePlansWithName ?? [] }));
      } else {
        dispatch(
          setManagePlans({
            plans:
              storePlansWithName?.concat(unOrderedPlans?.courses as any) ?? [],
          }),
        );
      }
    }
  }, [dispatch, isLicenseePlans, isLicenseeUser, isSuccess, unOrderedPlans]);

  useEffect(() => {
    if (isStatusReportSuccess && !statusReportIsFetching) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData, statusReportIsFetching]);

  useEffect(() => {
    if (isReportsTeamMembersDataSuccess) {
      setTeamMembersReport(reportsTeamMembersData);
    }
  }, [isReportsTeamMembersDataSuccess, reportsTeamMembersData]);

  const onPrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }: {
    category: string;
    id: string;
    isCompliancePlan: boolean;
    locations: string[];
    planName: string;
  }) => {
    turnOffPrintTrigger();
    setPlanDetails({
      category,
      id,
      isCompliance: isCompliancePlan,
      locations,
      name: planName,
    });
    if (isCompliancePlan) {
      if (
        planName.includes(Constants.FOOD_SAFETY_COURSE_NAME) ||
        locations?.length > 1
      ) {
        setShowChooseReportLocationsPopup(true);
      } else {
        // need to wait for setPlanDetails to populate the state :(
        setTriggerComplianceReportHandler(true);
      }
    } else if (locations?.length > 1) {
      setShowChooseReportLocationsPopup(true);
    } else {
      // We don't have the data to print yet so we need to refetch first
      setTriggerTeamMembersReportRefetch(true);
    }
  };

  useEffect(() => {
    if (triggerTeamMembersReportRefetch) {
      !!xpApiFeatureFlag
        ? refetchReportsTeamMembersStatuses()
        : oldRefetchReportsTeamMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isStatusReportSuccess,
    xpApiFeatureFlag,
    reportsTeamMembersData,
    triggerTeamMembersReportRefetch,
  ]);

  useEffect(() => {
    if (reportToPrint && reportToPrint.id) {
      onPrintReport({
        category: reportToPrint.category,
        id: reportToPrint.id,
        isCompliancePlan: reportToPrint.isCompliancePlan,
        locations: reportToPrint.locations,
        planName: reportToPrint.planName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToPrint]);

  const getComplianceReportToPrint = useCallback(() => {
    return [...statusReport?.userData!]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA?.firstName!.localeCompare(teamMemberB?.firstName!);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE
            .NAME]: `${teamMember.firstName} ${teamMember.lastName}`,
          [Constants.REPORT_TABLE.DATE_OF_BIRTH]: teamMember.birthDate
            ? teamMember.birthDate
            : t('Generic.na'),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            getCompletionDate(teamMember),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            teamMember?.certificate?.expiration &&
            teamMember?.courseStatus !==
              Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
              ? convertDateForReports(teamMember.certificate.expiration)
              : t('Generic.na'),
        };
      });
  }, [statusReport?.userData, t]);

  const reportData = uniqBy(
    teamMembersReport?.users
      ?.map(teamMember => {
        return {
          ...teamMember.user,
          plansAssigned: teamMember.plansAssigned,
          plansCompleted: teamMember.plansCompleted,
        };
      })
      ?.filter(teamMember1 => {
        return assignedTeamMembersList
          ?.status!.filter(
            teamMember =>
              teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
          )
          .some(teamMember2 => teamMember1.adId === teamMember2.userId);
      })
      .map(user => {
        return {
          ...user,
          completionDate: assignedTeamMembersList?.status?.find(
            teamMember => teamMember?.userId === user?.adId,
          )?.completionDate,
          status: assignedTeamMembersList?.status?.find(
            teamMember => teamMember.userId === user.adId,
          )?.status,
          steps: assignedTeamMembersList?.status?.find(
            teamMember => teamMember.userId === user.adId,
          )?.steps,
        };
      }),
    'adId',
  );

  const getStoreReportToPrint = useCallback(() => {
    return uniqBy(
      reportData.filter((teamMember: any) =>
        planDetails?.locations?.length > 1 && teamMember?.locations?.length
          ? teamMember?.locations.some((location: string) =>
              selectedReportLocations.includes(location),
            )
          : teamMember,
      ),
      'adId',
    )
      .sort((teamMemberA: any, teamMemberB: any) => {
        return (teamMemberA.name ?? '').localeCompare(teamMemberB.name ?? '');
      })
      .map((teamMember: any) => {
        return {
          [Constants.REPORT_TABLE.NAME]: teamMember.name,
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]: `${
            assignedTeamMembersList?.status?.find(
              userStatus => userStatus.userId === teamMember.adId,
            )?.stepsComplete || 0
          }/${assignedTeamMembersList?.checklist?.stepsTotal}`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]:
            generateTotalTimeFromSteps(
              teamMember.steps!,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          [Constants.REPORT_TABLE.STATUS]: t(
            `TrainingPlans.statusOptions.${
              Constants.TRAINING_PLANS_STATUSES[
                teamMember.status as keyof typeof Constants.TRAINING_PLANS_STATUSES
              ]
            }`,
          ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: teamMember.completionDate
            ? convertDateForReports(teamMember.completionDate)
            : t('Generic.na'),
          id: assignedTeamMembersList?.checklist?.id,
        };
      });
  }, [
    assignedTeamMembersList?.checklist?.id,
    assignedTeamMembersList?.checklist?.stepsTotal,
    assignedTeamMembersList?.status,
    planDetails?.locations?.length,
    reportData,
    selectedReportLocations,
    t,
  ]);

  //eslint-disable-next-line
  const tableHead = planDetails.isCompliance
    ? [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.COMPLETION_DATE,
          Constants.REPORT_TABLE.EXPIRATION_DATE,
        ],
      ]
    : [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.ITEMS_COMPLETED,
          Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
          Constants.REPORT_TABLE.STATUS,
          Constants.REPORT_TABLE.COMPLETION_DATE,
        ],
      ];
  if (showDoBChecked) {
    tableHead?.[0]?.splice(1, 0, Constants.REPORT_TABLE.DATE_OF_BIRTH);
  }

  const getReportToGenerate = useCallback(() => {
    return {
      category: t(mapCategoryToAliasTranslation(planDetails?.category ?? '')),
      foodSafetyImage:
        statusReport?.foodSafetyLetters?.[0]?.foodSafetyLetter?.content,
      id: planDetails?.id,
      location: `${t('Generic.at')} ${
        !selectedReportLocations.length && planDetails?.locations
          ? planDetails?.locations.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
      }`,
      name: planDetails?.name,
      reportToPrint: planDetails.isCompliance
        ? getComplianceReportToPrint().map(teamMember =>
            tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
          )
        : getStoreReportToPrint().map((teamMember: any) =>
            tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
          ),
      tableHead,
    };
  }, [
    getComplianceReportToPrint,
    getStoreReportToPrint,
    planDetails,
    selectedReportLocations,
    statusReport?.foodSafetyLetters,
    t,
    tableHead,
  ]);

  type Report = ReturnType<typeof getReportToGenerate>;

  const getMessageToSend = useCallback(
    (report: Report) => {
      return {
        category: report.category,
        location: report.location,
        name: report.name,
        reportToPrint: planDetails.isCompliance
          ? getComplianceReportToPrint()
          : getStoreReportToPrint(),
        tableHead,
      };
    },
    [
      getComplianceReportToPrint,
      getStoreReportToPrint,
      planDetails.isCompliance,
      tableHead,
    ],
  );

  /** If a report does not have a location screen, we need to wait until the data is
   * returned from the query.  Once we have the data from the backend, we can then
   * show the printable report.
   */
  useEffect(() => {
    if (
      triggerTeamMembersReportRefetch &&
      isReportsTeamMembersDataSuccess &&
      isAssignedTeamMembersListSuccess &&
      !assignedTeamMembersListIsFetching &&
      !reportsTeamMembersIsFetching
    ) {
      const reportToGenerate = getReportToGenerate();
      const messageToSend = getMessageToSend(reportToGenerate);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      setTriggerTeamMembersReportRefetch(false);
      resetPlanState();
    }
  }, [
    assignedTeamMembersListIsFetching,
    getMessageToSend,
    getReportToGenerate,
    isAssignedTeamMembersListSuccess,
    isReportsTeamMembersDataSuccess,
    reportsTeamMembersIsFetching,
    triggerTeamMembersReportRefetch,
  ]);

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastLeaderPermission);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastLeaderPermission, noLocationsSelected]);

  const resetPlanState = () => {
    setPlanDetails({
      category: '',
      id: '',
      isCompliance: false,
      locations: [],
      name: '',
    });
    setStatusReport({
      courseId: '',
      courseName: '',
      foodSafetyLetters: [],
      locations: [''],
      timeStamp: '',
      userData: [],
    });
  };

  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastLeaderPermission?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      const reportToGenerate = getReportToGenerate();
      const messageToSend = getMessageToSend(reportToGenerate);
      setShowChooseReportLocationsPopup(false);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      handleCurrentFilteredLocations();
      resetPlanState();
    }
  }, [
    getMessageToSend,
    getReportToGenerate,
    handleCurrentFilteredLocations,
    locationsWithAtLeastLeaderPermission?.length,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (
      !!planDetails.id &&
      !!statusReport.courseId &&
      !!triggerComplianceReportHandler &&
      !!isStatusReportSuccess &&
      !statusReportIsFetching
    ) {
      handlePrintReport();
      setTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    isStatusReportSuccess,
    planDetails.id,
    statusReport.courseId,
    statusReportIsFetching,
    triggerComplianceReportHandler,
  ]);

  const handleShowDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
    resetPlanState();
  };

  return (
    <PrintReportModal
      bodyText={t('TrainingPlans.chooseWhatToInclude')}
      children={
        !planDetails?.isCompliance
          ? planDetails?.locations?.map((location, idx) => (
              <CheckboxList
                id={location}
                idx={idx}
                key={idx}
                selectedLocations={selectedReportLocations}
                setSelectedLocations={setSelectedReportLocations}
              />
            ))
          : // Compliance plans
          locationsWithAtLeastLeaderPermission.length > 1
          ? locationsWithAtLeastLeaderPermission.map(
              (id: string, idx: number) => (
                <CheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ),
            )
          : null
      }
      handleShowDoBCheckbox={handleShowDoBCheckbox}
      headerText={t('Generic.printReport')}
      isFoodSafety={planDetails?.name?.includes(
        Constants.FOOD_SAFETY_COURSE_NAME,
      )}
      isOpen={showChooseReportLocationsPopup}
      noLocationsSelected={noLocationsSelected}
      onClose={onPrintReportCancel}
      primaryButtonHandler={handlePrintReport}
      primaryButtonText={t('Button.print')}
      secondaryButtonHandler={onPrintReportCancel}
      secondaryButtonText={t('Button.cancel')}
      selectedReportLocations={selectedReportLocations}
      showDoBChecked={showDoBChecked}
    />
  );
};

export default PrintReport;
