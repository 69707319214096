import Constants from 'constants/index';
import { formatDocumentTag } from 'utils/formatDocumentTag';
import { isApiError } from 'utils/request';
import { hasNoSpecialCharacters } from 'utils/string';
import { useFlags } from 'launchdarkly-react-client-sdk';
import first from 'lodash/first';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ReduxScrollPosition,
  resetState,
  setData,
  setId,
  setPageNumber,
  setQuery,
  setYPosition,
} from 'store/scrollPosition/slice';
import { selectUserCountry } from 'store/user/selectors';
import theme from 'styles/theme';
// @ts-ignore
import { useAmplitudeTrack } from 'amplitude/useAmplitude';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import {
  useAddSearchResultFeedbackMutation as useXpApiAddSearchResultFeedbackMutation,
  useGetSearchResultsQuery as useXpApiGetSearchResultsQuery,
} from 'services/xpApi';
import {
  addSearchResults,
  ReduxSearch,
  resetSearchState,
  setCurrentPage,
  setHasNextPage,
  setNumFound,
  setPageSize,
  setQueryId,
  setResult,
  setSearchResults,
  setShowingResults,
  setStoredQuery,
} from 'store/search/slice';
import { Button, LoadingIndicator, Typography } from 'cfa-react-components';
import SearchResultCard, {
  SearchResultCardProps,
} from './components/SearchResultCard';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import GenericError from '@/components/Error/GenericError';
import DocumentHead from '@/components/document/DocumentHead';
import { getCurrentLanguage } from '@/i18n/language';
import {
  useAddSearchResultFeedbackMutation,
  useGetSearchResultsQuery,
} from '@/services/pathwayApi';
import { setDocument } from '@/store/document-persisted/slice';
import { scrollPosition } from '@/store/scrollPosition/selectors';
import {
  selectCurrentPage,
  selectHasNextPage,
  selectNumFound,
  selectPageSize,
  selectQueryId,
  selectResult,
  selectSearchResults,
  selectShowingResults,
  selectStoredQuery,
} from '@/store/search/selectors';
import { pluralize } from '@/utils/language';

const SearchResultsPage = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const history = useHistory();
  const queryId: ReduxSearch['queryId'] = useSelector(selectQueryId);
  const storedQuery: ReduxSearch['query'] = useSelector(selectStoredQuery);
  const [addSearchResultFeedbackXpApi] =
    useXpApiAddSearchResultFeedbackMutation();
  const searchResults: ReduxSearch['searchResults'] =
    useSelector(selectSearchResults);
  const result: ReduxSearch['result'] = useSelector(selectResult);
  const hasNextPage: ReduxSearch['hasNextPage'] =
    useSelector(selectHasNextPage);
  const currentPage: ReduxSearch['currentPage'] =
    useSelector(selectCurrentPage);
  const pageSize: ReduxSearch['pageSize'] = useSelector(selectPageSize);
  useSelector(selectPageSize);
  const numFound: ReduxSearch['numFound'] = useSelector(selectNumFound);
  const showingResults: ReduxSearch['showingResults'] =
    useSelector(selectShowingResults);
  const position: ReduxScrollPosition = useSelector(scrollPosition);
  const selectedCountry: { id?: string } = useSelector(selectUserCountry);
  const dispatch = useDispatch();
  const [activeThumbsUp, setActiveThumbsUp] = useState<any[]>([]);
  const [activeThumbsDown, setActiveThumbsDown] = useState<any[]>([]);
  const { search } = useLocation();
  const query = new URLSearchParams(search).get('query');
  const languageCode = getCurrentLanguage();
  const [misspellingSuggestion, setMisspellingSuggestion] = useState('');

  const maxResults = Constants.MAX_SEARCH_RESULTS;

  useEffect(() => {
    if (query !== storedQuery) {
      dispatch(resetSearchState());
      dispatch(setStoredQuery(query));
    }
  }, [dispatch, query, storedQuery]);

  const {
    searchResultFeedback: featureFlagSearchResultFeedback,
    xpApi: xpApiFeatureFlag,
  } = useFlags();

  const {
    data: xpAPIResult,
    isFetching: xpAPIIsFetching,
    error: xpAPIError,
  } = useXpApiGetSearchResultsQuery(
    {
      country: selectedCountry?.id,
      language: languageCode,
      pageNumber: currentPage,
      query: query ?? '',
    },
    {
      refetchOnMountOrArgChange: false,
      skip:
        !xpApiFeatureFlag || xpApiFeatureFlag === undefined || !!position.id,
    },
  );

  const {
    data: coreResult,
    isFetching: coreIsFetching,
    error: coreError,
  } = useGetSearchResultsQuery(
    {
      language: languageCode,
      page: 1,
      pageSize: pageSize,
      query: query!,
    },
    {
      refetchOnMountOrArgChange: false,
      skip: xpApiFeatureFlag || xpApiFeatureFlag === undefined || !!position.id,
    },
  );

  const isFetching = xpAPIIsFetching || coreIsFetching;
  const error = xpAPIError ?? coreError;

  useEffect(() => {
    dispatch(setHasNextPage(searchResults?.length < numFound));
  }, [dispatch, numFound, searchResults?.length]);

  useEffect(() => {
    if (
      xpApiFeatureFlag &&
      !!xpAPIResult &&
      xpAPIResult?.results !== searchResults
    ) {
      if (
        xpAPIResult.page === currentPage &&
        currentPage > 1 &&
        xpAPIResult.queryId !== queryId
      ) {
        dispatch(setQueryId(xpAPIResult.queryId));
        dispatch(addSearchResults(xpAPIResult.results));
      } else {
        dispatch(setQueryId(xpAPIResult.queryId));
        dispatch(setSearchResults(xpAPIResult.results));
      }
      setMisspellingSuggestion(first(xpAPIResult.spellingSuggestions) ?? '');
      dispatch(setResult(xpAPIResult));
    } else if (!xpApiFeatureFlag && !!coreResult) {
      dispatch(setSearchResults(coreResult.results));
      setMisspellingSuggestion(
        // @ts-ignore (not sure how to resolve this one - similar to AddProcedurePopUp.tsx line 674)
        first(coreResult.spellCorrectedQueryResults)?.suggestedQueryText,
      );
      dispatch(setResult(coreResult));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xpAPIResult, coreResult]);

  useEffect(() => {
    if (result && (!!result.totalResults || !!(result as any).numberFound)) {
      dispatch(
        setNumFound(
          xpApiFeatureFlag
            ? Math.min(result.totalResults, maxResults)
            : Math.min((result as any)?.numberFound ?? 0, maxResults),
        ),
      );
    }
  }, [dispatch, maxResults, result, xpApiFeatureFlag]);

  useEffect(() => {
    if (!position.id) {
      const oldShowingResults = Math.min(
        Math.ceil(pageSize * currentPage),
        maxResults,
      );
      const xpApiShowingResults = Math.min(
        Math.ceil(Constants.DEFAULT_PAGING_SIZE * currentPage),
        maxResults,
      );
      const newShowingResults = !!xpApiFeatureFlag
        ? xpApiShowingResults
        : oldShowingResults;

      dispatch(
        setShowingResults(
          hasNextPage ? newShowingResults : numFound > 0 ? numFound : null,
        ),
      );
    }
  }, [
    currentPage,
    dispatch,
    hasNextPage,
    maxResults,
    numFound,
    pageSize,
    position.id,
    showingResults,
    xpApiFeatureFlag,
  ]);

  const track = useAmplitudeTrack();

  useEffect(() => {
    if (
      (result as any)?.status === 'fulfilled' &&
      showingResults &&
      (query?.length ?? 0) > 0
    ) {
      if (track !== null) {
        // ensure we have initialized gtag scripts
        track('search_result', {
          num_results: numFound ?? 0,
          query: query ?? 'empty',
        });
      }
    }
  }, [(result as any)?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCustomPage = useCallback(
    (num: number) => {
      if (!!xpApiFeatureFlag) {
        dispatch(setCurrentPage(num));
      } else {
        dispatch(setPageSize(num * 12 > 100 ? 100 : num * 12));
      }
    },
    [dispatch, xpApiFeatureFlag],
  );

  useEffect(() => {
    if (position.pageNumber) {
      fetchCustomPage(position.pageNumber);
    }
  }, [fetchCustomPage, position.pageNumber]);

  useEffect(() => {
    // dispatch scroll position to store on scroll updates
    const handleScroll = () => {
      dispatch(setYPosition(window.scrollY));
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch]);

  useEffect(() => {
    // scroll to the correct position on page load
    if (!isFetching) {
      window.scrollTo({
        behavior: 'instant',
        top: position.yPostition,
      });
    }
  }, [isFetching]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // reset scroll data on query change, do not reset on position.id change
    if (query !== position.query) {
      dispatch(resetState());
      dispatch(setQuery(query));
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!position.pageNumber) {
      dispatch(setPageNumber(1));
    }
  }, [position.pageNumber, dispatch]);

  const fetchNextPage = (num: number) => {
    if (!!xpApiFeatureFlag) {
      dispatch(setCurrentPage(num));
    } else {
      dispatch(setPageSize(num * 12 > 100 ? 100 : num * 12));
    }
  };

  /* Leaving comment in the code as a reminder of how to handle mutation
      with trigger (addSearchResultFeedback) and response (addResult)
  const [addSearchResultFeedback, addResult] = useAddSearchResultFeedbackMutation();
 useEffect(() => {
   console.log('result', result, 'addResult', addResult);
   if (addResult.error)  console.log(addResult.error);
  }, [result, addResult]);  */

  const [addSearchResultFeedback] = useAddSearchResultFeedbackMutation();

  const onSearchResultFeedback = ({
    resultId,
    resultQueryId,
  }: {
    resultId?: string;
    resultQueryId?: string;
  }) => {
    if (!resultQueryId || !resultId) {
      notifyBugsnag(
        new Error(
          `No ${
            !resultQueryId ? 'queryId' : 'resultId'
          } found in SearchResults`,
        ),
      );
      return;
    }

    const resp: ReturnType<typeof addSearchResultFeedbackXpApi> =
      xpApiFeatureFlag
        ? addSearchResultFeedbackXpApi({
            queryId: resultQueryId,
            resultId,
          })
        : (addSearchResultFeedback({
            queryId: resultQueryId,
            resultId,
          }) as any);

    resp.unwrap().catch(err => {
      const errorDetails = {
        error: JSON.stringify(err),
        query,
        queryId: resultQueryId,
        resultId,
      };
      notifyBugsnag(
        new Error(
          `Error in addSearchResultFeedback: ${JSON.stringify(errorDetails)}`,
        ),
      );
    });
  };

  const goToDetailsPage = (id: string) => {
    history.push(`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${id}`);
  };

  const onSearchCardClick: SearchResultCardProps['onClick'] = props => {
    const { id, resultId, documentId } = props;
    dispatch(setId(id));
    dispatch(setData((result as any)?.data));
    onSearchResultFeedback({
      resultQueryId: result?.queryId,
      resultId,
    });
    goToDetailsPage(documentId);
  };

  const getFeedbackText = (id: any) => {
    const thanksEl = (color: string) => (
      <span style={{ color }}>{t('Search.submitFeedback')}</span>
    );
    if (activeThumbsUp.includes(id)) {
      return thanksEl(theme.tertiaryPalette.green);
    } else if (activeThumbsDown.includes(id)) {
      return thanksEl(theme.secondaryPalette.red);
    } else {
      return <span>{t('Search.rateResult')}:</span>;
    }
  };

  const onThumbsUp: SearchResultCardProps['onThumbsUp'] = (id, resultId) => {
    setActiveThumbsUp([...activeThumbsUp, id]);
    setActiveThumbsDown(activeThumbsDown.filter(item => id !== item));
    onSearchResultFeedback({
      resultQueryId: result?.queryId,
      resultId,
    });
  };

  const onThumbsDown: SearchResultCardProps['onThumbsDown'] = (
    id,
    resultId,
  ) => {
    setActiveThumbsUp(activeThumbsUp.filter(item => id !== item));
    setActiveThumbsDown([...activeThumbsDown, id]);
    onSearchResultFeedback({
      resultQueryId: result?.queryId,
      resultId,
    });
  };

  const onSearchMisspellingSuggestionClick = (suggestion: string) => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`,
      search: `query=${suggestion}`,
    });
  };

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  const onLoadMore = () => {
    dispatch(setId(''));
    dispatch(setData({}));
    dispatch(setPageNumber(position.pageNumber + 1));
    fetchNextPage(position.pageNumber + 1);
  };

  return (
    <SearchResultsContainer>
      <LoadingOverlay
        isOpen={!!isFetching && !((searchResults?.length ?? 0) > 0)}
      />
      <DocumentHead pageTitle={query ?? ''} />
      {(showingResults || misspellingSuggestion) && !isFetching ? (
        <>
          {misspellingSuggestion && (
            <StyledMisspellingSuggestionWrapper data-testid="MisspellingSuggestionWrapper">
              {t('Search.showingResultsSuggestion')}
              {': '}
              <StyledMisspellingSuggestion
                data-testid="MisspellingSuggestion"
                onClick={() =>
                  onSearchMisspellingSuggestionClick(misspellingSuggestion)
                }
              >
                {misspellingSuggestion}
              </StyledMisspellingSuggestion>
            </StyledMisspellingSuggestionWrapper>
          )}
          <Typography variant="body1">
            {numFound} {t('Search.numberOfResultsFor')} <b>{query}</b>
          </Typography>
        </>
      ) : (
        <Typography variant="body1">
          {numFound} {t('Search.numberOfResultsFor')} <b>{query}</b>
        </Typography>
      )}
      <div>
        {!searchResults?.length ? (
          <StyledNoResults>{t('Search.noResults')}</StyledNoResults>
        ) : (
          searchResults?.map((searchResultDocument, index) => {
            const {
              icon,
              id,
              contentApiUrl,
              contentUrl,
              lastModified,
              name,
              otherContentUrl,
              searchId,
              sourceSystem,
              tags,
              type,
            } = searchResultDocument;

            // document prop
            const isTridion =
              sourceSystem === Constants.DOCUMENT_TYPES.TRIDION.toUpperCase();
            const docUseContentUrl =
              isTridion ||
              sourceSystem ===
                Constants.EXPANDED_FILE_FORMAT_TYPES.GAME.toUpperCase() ||
              sourceSystem ===
                Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE.toUpperCase();
            const documentUrl = {
              base: docUseContentUrl ? contentUrl! : contentApiUrl!,
              translated: otherContentUrl!,
            };

            // locationType prop
            const newLocationTypes = tags?.map((tag: string) =>
              formatDocumentTag(tag),
            );

            // resultId prop
            const newResultId = searchId;

            return (
              <SearchResultCard
                documentId={id!}
                documentType={type!}
                featureFlagSearchResultFeedback={
                  featureFlagSearchResultFeedback
                }
                getFeedbackText={getFeedbackText}
                icon={hasNoSpecialCharacters(icon!) ? icon! : 'placeholder'}
                id={id!}
                isMarketTest={false}
                isThumbsDown={activeThumbsDown.includes(id)}
                isThumbsUp={activeThumbsUp.includes(id)}
                isTridion={isTridion}
                key={index}
                lastModified={new Date(lastModified!).toLocaleDateString()}
                locationTypes={newLocationTypes!}
                name={name!}
                onClick={doc => {
                  onSearchCardClick(doc);
                  dispatch(
                    setDocument({
                      document: searchResultDocument,
                      routedFrom: 'search',
                      icon,
                    }),
                  );
                }}
                onThumbsDown={onThumbsDown}
                onThumbsUp={onThumbsUp}
                resultId={newResultId!}
                url={documentUrl}
              />
            );
          })
        )}
      </div>
      {result && hasNextPage && (
        <LoadMoreButton
          color="secondary"
          data-testid="LoadMore"
          disabled={isFetching}
          onClick={onLoadMore}
          size="lg"
          variant="filled"
        >
          <>
            {(xpAPIIsFetching || coreIsFetching) && (
              <LoadMoreLoadingIndicator disabled size="sm" variant="inline" />
            )}
            {t('Button.loadMore')}
          </>
        </LoadMoreButton>
      )}
      {showingResults && (
        <StyledShowingResultsFooter variant="body1">
          {t('Search.showingResultsFooter', {
            numberOfResults: numFound <= 100 ? numFound : '100+',
            resultsText: pluralize(
              numFound,
              t('Search.resultText'),
              t('Search.resultsText'),
            ),
            showingResults: showingResults,
          })}
        </StyledShowingResultsFooter>
      )}
      <SpacerDiv />
    </SearchResultsContainer>
  );
};

const SearchResultsContainer = styled.div`
  position: relative;
`;

const LoadMoreButton = styled(Button)`
  margin: 0 auto;
  margin-top: 32px;
  display: block;
`;
const LoadMoreLoadingIndicator = styled(LoadingIndicator)`
  margin-right: 10px;
  margin-top: -5px;
`;
const StyledMisspellingSuggestionWrapper = styled.span`
  display: block;
  font-weight: normal;
  color: ${props => props.theme.grayScale.gray7};
  margin-bottom: 15px;
`;
const StyledMisspellingSuggestion = styled.strong`
  color: ${props => props.theme.primaryPalette.navyBlue};
  font-weight: 700;
  cursor: pointer;
`;
const StyledShowingResultsFooter = styled(Typography)`
  text-align: center;
  margin-top: 16px;
  color: ${props => props.theme.grayScale.gray6};
`;
const StyledNoResults = styled.div`
  padding: 30px 20px;
`;
const SpacerDiv = styled.div`
  height: 400px;
`;

export default SearchResultsPage;
