import Constants from 'constants/index';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUpdateTrainingPlanMutation } from 'services/pathwayApi';
import {
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithLeaderPermission,
  selectLocationsWithOperatorPermission,
  selectUserLanguage,
} from 'store/user/selectors';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { Card } from 'cfa-react-components';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import {
  ChecklistDTO,
  ChecklistSectionDTO,
  ChecklistSectionStepDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import TaskContainer, { SectionFunctionProps } from './TaskContainer';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import Section from '@/pages/TrainingPlans/BuildPlans/EditablePlanItems/Section';
import { TaskProps } from '@/types/types';

interface PlanItemsProps {
  immutablePlan: any;
  refetch: () => void;
  addProcedure: (procedure: string) => void;
  addQuiz: (quiz: string) => void;
  setIsDrop: (isDrop: boolean) => void;
}

const PlanItems: React.FC<PlanItemsProps> = ({
  immutablePlan,
  refetch,
  addProcedure,
  addQuiz,
  setIsDrop,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const userLanguage = useSelector(selectUserLanguage);
  const [editMode, setEditMode] = useState(false);
  const [sectionBeingRenamed, setSectionBeingRenamed] = useState({
    id: '',
    name: '',
  });
  const [taskToDelete, setTaskToDelete] = useState<{
    id: string;
    name: string;
    sectionId: string | null;
    type: string;
  }>({
    id: '',
    name: '',
    sectionId: '',
    type: '',
  });
  const [showDeleteTaskPopup, setShowDeleteTaskPopup] = useState(false);
  const [showDeleteSectionPopup, setShowDeleteSectionPopup] = useState(false);
  const [activeSectionId, setActiveSectionId] = useState('');
  const [indexToAdd, setIndexToAdd] = useState<number>();
  const [plan, setPlan] = useState(immutablePlan);

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const locationsWithOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const locationsWithLeaderPermission = useSelector(
    selectLocationsWithLeaderPermission,
  );

  useEffect(() => {
    if (immutablePlan) {
      setPlan(immutablePlan);
    }
  }, [immutablePlan]);

  const onDeleteSectionCancel = () => {
    setSectionBeingRenamed({ id: '', name: '' });
    setShowDeleteSectionPopup(false);
  };

  const onDeleteSection = (section: { id: string; name: string }) => {
    setSectionBeingRenamed({ id: '', name: '' });
    setShowDeleteSectionPopup(false);
    const payload = {
      locations: plan.locations,
      checklist: {
        ...plan,
        sections: [
          ...plan.sections.filter((value: { id: string }) => {
            return !(value?.id === section.id);
          }),
        ],
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${section.name} ${t(
              'TrainingPlans.toastMessage.sectionDeleted',
            )}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const deleteSectionPopUp = (sectionId: string, sectionName: string) => {
    setSectionBeingRenamed({ id: sectionId, name: sectionName });
    setShowDeleteSectionPopup(true);
  };

  const isAllowedToEdit = () => {
    if (locationsWithAtLeastTrainer) {
      return true;
    }
    if (locationsWithOperatorPermission.length > 0) {
      return true;
    }
    return locationsWithLeaderPermission.length > 0;
  };

  const renameSectionPopUp = (sectionId: string) => {
    setEditMode(true);
    setSectionBeingRenamed({ id: sectionId, name: '' });
  };

  const updateSectionName = (updatedSectionTitle: string) => {
    if (!updatedSectionTitle.trim()) {
      return;
    }

    const sections = sectionBeingRenamed
      ? plan.sections.map((section: { id: string; name: string }) =>
          section.id === sectionBeingRenamed.id
            ? {
                ...section,
                name: {
                  ...((section?.name || {}) as { id: string; name: string }),
                  [userLanguage]: updatedSectionTitle,
                },
              }
            : section,
        )
      : plan.sections;
    const payload = {
      locations: plan.locations,
      checklist: { ...plan, sections },
    };

    saveUpdates(payload);
  };

  const [taskUpdated, setTaskUpdated] = useState(false);

  const updateTask = (task: TaskProps, sectionId: string) => {
    const sections = plan.sections.map((section: ChecklistSectionDTO) =>
      section.id === sectionId
        ? {
            ...section,
            steps:
              section.steps &&
              section.steps.map(step => {
                return step.id === task.id
                  ? {
                      ...step,
                      name: { ...step.name, [userLanguage]: task.name },
                      note: { ...step?.note, [userLanguage]: task.note },
                      urls: [
                        {
                          urlSet: {
                            ...step?.urls?.[0]?.urlSet,
                            [userLanguage]: task.url,
                          },
                        },
                      ],
                    }
                  : step;
              }),
          }
        : section,
    );

    const steps = plan.steps.map((step: ChecklistSectionStepDTO) => {
      return step.id === task.id
        ? {
            ...step,
            name: { ...step.name, [userLanguage]: task.name },
            note: { ...step?.note, [userLanguage]: task.note },
            urls: [
              {
                urlSet: {
                  ...step?.urls?.[0]?.urlSet,
                  [userLanguage]: task.url,
                },
              },
            ],
          }
        : step;
    });

    const payload = {
      locations: plan.locations,
      checklist: sectionId ? { ...plan, sections } : { ...plan, steps },
    };

    saveUpdates(payload);
    setTaskUpdated(true);
  };

  const onUpdate = (
    type: string,
    updatedSectionTitle: string | null,
    task: TaskProps,
    sectionId: string,
  ) => {
    type === Constants.TRAINING_MENU_OPTIONS.SECTION && updatedSectionTitle
      ? updateSectionName(updatedSectionTitle)
      : updateTask(task, sectionId);
    setEditMode(false);
    setSectionBeingRenamed({ id: '', name: '' });
  };

  const saveUpdates = (payload: {
    locations: string[];
    checklist: ChecklistDTO;
  }) => {
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        setEditMode(false);
        setSectionBeingRenamed({ id: '', name: '' });
        refetch();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const editSectionTitle = (section: ChecklistSectionDTO) => {
    return (
      isAllowedToEdit() && editMode && sectionBeingRenamed.id === section.id
    );
  };

  const onDeleteTaskInsideSection = ({
    id,
    name,
    sectionId,
    type,
  }: SectionFunctionProps) => {
    setTaskToDelete({ id, name, sectionId, type });
    setShowDeleteTaskPopup(true);
  };

  const onDeleteTask = () => {
    const steps = taskToDelete.sectionId
      ? plan.steps
      : plan.steps.filter(
          (step: ChecklistSectionStepDTO) => step.id !== taskToDelete.id,
        );
    const sections = taskToDelete.sectionId
      ? plan.sections.map((section: ChecklistSectionDTO) => {
          return {
            ...section,
            steps:
              section.steps &&
              section.steps.filter(step => step.id !== taskToDelete.id),
          };
        })
      : plan.sections;

    const payload = {
      locations: plan.locations,
      checklist: {
        ...plan,
        steps,
        sections,
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${taskToDelete.name} ${t('TrainingPlans.toastMessage.deleted')}`}
          </ToastMessageBlock>
        ));
        setShowDeleteTaskPopup(false);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const onDeleteTaskOutsideSection = ({
    id,
    name,
    type,
  }: {
    id: string;
    name: string;
    type: string;
  }) => {
    setTaskToDelete({ id, name, sectionId: null, type });
    setShowDeleteTaskPopup(true);
  };

  const updateArrayIndex = (
    array: ChecklistSectionStepDTO[],
    removedIndex: number,
    addedIndex: number,
  ) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(
      (item: {}, idx) => {
        return { ...item, order: idx };
      },
    );
  };

  let stepToMove: ChecklistSectionStepDTO;

  const onDrop = (
    dropResult: DropResult,
    type?: string,
    sectionId?: string,
  ) => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex, payload } = dropResult;
    setIsDrop(true);
    const movingStepOutOfSection =
      payload?.sectionId &&
      activeSectionId === '' &&
      type === Constants.DRAGGABLE_TYPES.STEP_IN_SECTION
        ? true
        : false;

    const movingStepIntoSection =
      payload?.sectionId === undefined &&
      activeSectionId &&
      type === Constants.DRAGGABLE_TYPES.STEP
        ? true
        : false;

    const movingStepInsideSection =
      (payload?.sectionId === activeSectionId && removedIndex && addedIndex) ||
      (payload?.sectionId === activeSectionId &&
        (removedIndex === 0 || addedIndex === 0))
        ? true
        : false;

    const movingStepInBetweenSections =
      payload?.sectionId &&
      payload?.sectionId !== activeSectionId &&
      type === Constants.DRAGGABLE_TYPES.STEP_IN_SECTION
        ? true
        : false;

    const movingIndividualSteps =
      !activeSectionId &&
      !payload?.sectionId &&
      type === Constants.DRAGGABLE_TYPES.STEP
        ? true
        : false;

    stepToMove = movingStepOutOfSection
      ? plan?.sections
          .filter((section: ChecklistSectionDTO) => {
            return section.id === payload.sectionId;
          })?.[0]
          ?.steps.find(
            (step: ChecklistSectionStepDTO) => step.id === payload.id,
          )
      : movingStepIntoSection
      ? plan.steps.filter(
          (step: ChecklistSectionStepDTO) => step.id === payload.id,
        )[0]
      : null;

    const moveBetweenSections = () => {
      stepToMove = plan?.sections
        .filter(
          (section: ChecklistSectionDTO) => section.id === payload.sectionId,
        )?.[0]
        ?.steps.filter(
          (step: ChecklistSectionStepDTO) => step.id === payload.id,
        )[0];

      const updatedSections = [
        ...plan.sections.map((section: ChecklistSectionDTO) => {
          return section?.id === payload.sectionId
            ? {
                ...section,
                steps: section.steps && [
                  ...section.steps.filter(step => step.id !== payload.id),
                ],
              }
            : { ...section };
        }),
      ];

      return [
        ...updatedSections.map(section => {
          return section?.id === activeSectionId
            ? {
                ...section,
                steps: updateArrayIndex(
                  [...section.steps, stepToMove],
                  [...section.steps, stepToMove].length - 1,
                  indexToAdd as number,
                ),
              }
            : { ...section };
        }),
      ];
    };

    const updatedSections =
      // Moving section
      type === Constants.DRAGGABLE_TYPES.SECTION
        ? updateArrayIndex(plan.sections, removedIndex, addedIndex as number)
        : movingStepInsideSection
        ? [
            ...plan.sections.map((section: ChecklistSectionDTO) => {
              return section?.id === sectionId
                ? {
                    ...section,
                    steps: updateArrayIndex(
                      section.steps as ChecklistSectionStepDTO[],
                      removedIndex,
                      addedIndex as number,
                    ),
                  }
                : { ...section };
            }),
          ]
        : movingStepOutOfSection
        ? [
            ...plan.sections.map((section: ChecklistSectionDTO) => {
              return section?.id === payload.sectionId
                ? {
                    ...section,
                    steps:
                      section.steps &&
                      section.steps.filter(step => step.id !== payload.id),
                  }
                : { ...section };
            }),
          ]
        : movingStepIntoSection
        ? [
            ...plan.sections.map((section: ChecklistSectionDTO) => {
              return section?.id === activeSectionId
                ? {
                    ...section,
                    steps: updateArrayIndex(
                      [
                        ...(section.steps as ChecklistSectionStepDTO[]),
                        stepToMove,
                      ],
                      [
                        ...(section.steps as ChecklistSectionStepDTO[]),
                        stepToMove,
                      ].length - 1,
                      indexToAdd as number,
                    ),
                  }
                : { ...section };
            }),
          ]
        : movingStepInBetweenSections
        ? moveBetweenSections()
        : plan.sections;

    const updatedSteps = movingIndividualSteps
      ? updateArrayIndex(plan.steps, removedIndex, addedIndex as number)
      : movingStepOutOfSection ||
        (movingStepInBetweenSections && !activeSectionId)
      ? updateArrayIndex(
          [...plan.steps, stepToMove],
          [...plan.steps, stepToMove].length - 1,
          indexToAdd as number,
        )
      : movingStepIntoSection
      ? plan.steps.filter(
          (step: ChecklistSectionStepDTO) => step.id !== payload.id,
        )
      : plan.steps;

    const pay_load = {
      locations: plan.locations,
      checklist: {
        ...plan,
        sections: updatedSections,
        steps: updatedSteps,
      },
    };

    if (
      stepToMove ||
      /** These typed out null or undefined checks are necessary over the shorthand
       * ie !stepToMove.  I haven't been able to figure out why but the logic just
       * breaks without these explicity set */
      (stepToMove === undefined && removedIndex && addedIndex) ||
      (stepToMove === null && removedIndex !== null && addedIndex !== null)
    ) {
      setPlan((prevState: ChecklistDTO) => ({
        ...prevState,
        sections: updatedSections,
        steps: updatedSteps,
      }));
      updateTrainingPlan(pay_load)
        .unwrap()
        .then(() => {
          refetch();
        })
        .catch(err => {
          notifyBugsnag(err);
        });
    }
  };

  return (
    <>
      <ContainerWrapper>
        {/** @ts-ignore overload error */}
        <Container
          dragHandleSelector=".step-drag-handle"
          dropPlaceholder={{
            className: 'shadow-on-drop',
          }}
          getChildPayload={index => plan?.steps?.[index]}
          groupName="steps"
          nonDragAreaSelector=".cfa-textfield"
          onDragEnter={() => setActiveSectionId('')}
          onDrop={e => onDrop(e, Constants.DRAGGABLE_TYPES.STEP)}
          onDropReady={addedIndex =>
            setIndexToAdd(addedIndex.addedIndex as number)
          }
        >
          {plan?.steps?.map((step: ChecklistSectionStepDTO, idx: number) => (
            // @ts-ignore it's yelling about children but we need children for the drag and drop
            <DraggablePlanItem
              data-testid="Draggable"
              key={`${step.id}-${idx}`}
            >
              <PlanItemCard elevation={1}>
                <TaskContainer
                  className="step-drag-handle"
                  isAllowedToEdit={isAllowedToEdit()}
                  onCancel={onCancel}
                  onDelete={onDeleteTaskOutsideSection}
                  onEdit={(type, updatedSectionTitle, task, sectionId) =>
                    onUpdate(type, updatedSectionTitle, task, sectionId)
                  }
                  step={step}
                  taskUpdated={taskUpdated}
                />
              </PlanItemCard>
            </DraggablePlanItem>
          ))}
        </Container>
      </ContainerWrapper>

      <ContainerWrapper>
        {/** @ts-ignore it's yelling about children but we need children for the drag and drop */}
        <Container
          dragHandleSelector=".section-drag-handle"
          dropPlaceholder={{
            className: 'shadow-on-drop',
          }}
          nonDragAreaSelector=".cfa-textfield"
          onDrop={e => onDrop(e, Constants.DRAGGABLE_TYPES.SECTION)}
        >
          {plan?.sections?.map((section: ChecklistSectionDTO, idx: number) => (
            // @ts-ignore it's yelling about children but we need children for the drag and drop
            <Draggable key={section.id}>
              <Section
                addProcedure={() => addProcedure(section.id as string)}
                addQuiz={() => addQuiz(section.id as string)}
                deleteSectionPopUp={deleteSectionPopUp}
                editSectionTitle={editSectionTitle}
                index={idx}
                isAllowedToEdit={isAllowedToEdit()}
                onCancel={onCancel}
                onClickThreeDotMenuDeleteTask={onDeleteTaskInsideSection}
                onDrop={(
                  e: {
                    addedIndex: number;
                    payload: { id: string; sectionId: string };
                    removedIndex: number;
                  },
                  id: string,
                ) => onDrop(e, Constants.DRAGGABLE_TYPES.STEP_IN_SECTION, id)}
                onEdit={(
                  type: string,
                  updatedSectionTitle: string | null,
                  task: TaskProps,
                  id: string,
                ) => onUpdate(type, updatedSectionTitle, task, id)}
                plan={plan}
                refetch={refetch}
                renameSectionPopUp={renameSectionPopUp as () => void}
                section={section}
                setActiveSectionId={id => setActiveSectionId(id)}
                setIndexToAdd={index =>
                  setIndexToAdd(index.addedIndex as number)
                }
                taskUpdated={taskUpdated}
              />
            </Draggable>
          ))}
        </Container>
      </ContainerWrapper>
      <ConfirmationModal
        bodyText={t('Generic.deleteObject', {
          name: sectionBeingRenamed.name ?? '',
          object: t('Generic.section').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: t('Generic.section'),
        })}
        isOpen={showDeleteSectionPopup}
        onClose={onDeleteSectionCancel}
        primaryButtonHandler={() => onDeleteSection(sectionBeingRenamed)}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={onDeleteSectionCancel}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={
          taskToDelete.type === Constants.STEP_TYPES.TASK
            ? t('Generic.deleteObject', {
                name: taskToDelete.name ?? '',
                object: t('Generic.task').toLowerCase(),
              })
            : t('Generic.removeObject', {
                name: taskToDelete.name ?? '',
                object:
                  taskToDelete.type === Constants.STEP_TYPES.DOCUMENT
                    ? t('Generic.resource').toLowerCase()
                    : t('Generic.quiz').toLowerCase(),
              })
        }
        headerText={
          taskToDelete.type === Constants.STEP_TYPES.TASK
            ? t('Generic.deleteHeader', {
                type: t('Generic.task'),
              })
            : t('Generic.removeHeader', {
                type:
                  taskToDelete.type === Constants.STEP_TYPES.DOCUMENT
                    ? t('Generic.resource')
                    : taskToDelete.type === Constants.STEP_TYPES.QUIZ
                    ? t('Generic.quiz')
                    : t('Generic.task'),
              })
        }
        isOpen={showDeleteTaskPopup}
        onClose={() => {
          setShowDeleteTaskPopup(false);
        }}
        primaryButtonHandler={onDeleteTask}
        primaryButtonText={
          taskToDelete.type === Constants.STEP_TYPES.TASK
            ? t('Button.delete')
            : t('Button.remove')
        }
        primaryButtonVariant="destructive"
        secondaryButtonHandler={() => {
          setShowDeleteTaskPopup(false);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

const PlanItemCard = styled(Card)`
  margin: 24px 0;
  padding: 16px;
`;

const DraggablePlanItem = styled(Draggable)`
  &:last-child ${PlanItemCard} {
    margin-bottom: 0;
  }
`;

const ContainerWrapper = styled.div`
  width: 100%;
  .shadow-on-drop {
    background-color: ${({ theme }) => theme.grayScale.gray2};
    padding: 10px;
    border-radius: 5px;
  }
`;

export default PlanItems;
