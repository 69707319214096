import Constants from 'constants/index';
import { createSlice } from '@reduxjs/toolkit';
import { getNameFromLanguage } from '@/utils/language';
import { PaginatedPlan } from '@/types/types';

const applyFilters = (
  plans: PaginatedPlan[],
  categoryFilters: string[],
  searchFilter: string,
  locationFilters: string[],
) => {
  let filteredPlans = plans.map(plan => ({
    ...plan,
    name: plan.courseName,
  }));

  if (locationFilters.length) {
    filteredPlans = filteredPlans.filter(plan => {
      return locationFilters.includes(plan?.checklist?.ownerId);
    });
  }
  if (categoryFilters.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { category } = plan.courseID ? plan : plan.checklist;
      const hasCategory =
        // Compliance plans do not have a category so we check for undefined
        (category === undefined &&
          categoryFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE)) ||
        categoryFilters.includes(category);
      return hasCategory;
    });
  }
  if (searchFilter.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const nameLocalLang = plan?.checklist
        ? getNameFromLanguage(plan.checklist.name)
        : getNameFromLanguage(plan.name!);
      return nameLocalLang.toLowerCase().includes(searchFilter.toLowerCase());
    });
  }
  return filteredPlans;
};

const applySort = (plans: PaginatedPlan[], sort: string): PaginatedPlan[] => {
  let sortedPlans = plans;
  const liftedPlans = plans.map(plan => {
    if (plan.checklist) {
      return {
        ...plan,
        created: plan.checklist.created,
        name: plan.checklist.name,
      };
    } else {
      return {
        ...plan,
        created: plan.createdDate,
        name: plan.courseName,
      };
    }
  });

  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedPlans = (liftedPlans as any).toSorted((a: any, b: any) =>
        getNameFromLanguage(a.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(b.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedPlans = (liftedPlans as any).toSorted((a: any, b: any) =>
        getNameFromLanguage(b.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(a.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.NEWEST:
      sortedPlans = (liftedPlans as any).toSorted(
        (a: any, b: any) =>
          new Date(b.created).getTime() - new Date(a.created).getTime(),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.OLDEST:
      sortedPlans = (liftedPlans as any).toSorted(
        (a: any, b: any) =>
          new Date(a.created).getTime() - new Date(b.created).getTime(),
      );
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedPlans as PaginatedPlan[];
};

const applyPagination = (
  plans: PaginatedPlan[],
  page: number,
  pageSize: number,
) => {
  const total = plans.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedPlans = plans.slice(0, showing);
  return { loadedPlans, showing, total };
};

export interface ManagePlansFilterState {
  plans: PaginatedPlan[];
  categoryFilters: string[];
  searchFilter: string;
  locationFilters: string[];
  sort: string;
  filteredPlans: PaginatedPlan[];
  sortedPlans: PaginatedPlan[];
  paginatedPlans: PaginatedPlan[];
  page: number;
  pageSize: number;
  showing: number;
  total: number;
}

const initialState: ManagePlansFilterState = {
  plans: [],
  categoryFilters: [],
  searchFilter: '',
  locationFilters: [],
  sort: Constants.PLANS_SORT_OPTIONS.NEWEST,
  filteredPlans: [],
  sortedPlans: [],
  paginatedPlans: [],
  page: 1,
  pageSize: 12,
  showing: 0,
  total: 0,
};

export const slice = createSlice({
  name: 'managePlansFilter',
  initialState,
  reducers: {
    /**
     * We share the same store between Training Plans --> Manage Plans and Reports --> Plans
     * because the data and designs are identical.  Rather than create a separate store with
     * a lot of extra lines of code, I thought clearing the store would be a simpler approach.
     */
    resetManagePlans: state => {
      state.categoryFilters = [];
      state.searchFilter = '';
      state.categoryFilters = [];
      state.page = 1;
      state.showing = 0;
      state.sort = Constants.PLANS_SORT_OPTIONS.NEWEST;
      state.total = 0;
    },
    setManagePlans: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.plans = action.payload.plans;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    addManagePlansLocationFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.locationFilters = [...state.locationFilters, action.payload.filter];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    addManagePlansCategoryFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.categoryFilters = [...state.categoryFilters, action.payload.filter];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setManagePlansSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = action.payload.searchFilter;
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setManagePlansSort: (state, action) => {
      state.sort = action.payload.sort;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearManagePlansCheckboxFilters: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.categoryFilters = [];
      state.locationFilters = [];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearManagePlansSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = '';
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    loadMorePlans: state => {
      state.page += 1;
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    removeManagePlansFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      const { filter } = action.payload;
      if (state.categoryFilters.includes(filter)) {
        state.categoryFilters = state.categoryFilters.filter(
          categoryFilter => categoryFilter !== filter,
        );
      }
      if (state.locationFilters.includes(filter)) {
        state.locationFilters = state.locationFilters.filter(
          locationFilter => locationFilter !== filter,
        );
      }
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.locationFilters,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addManagePlansCategoryFilter,
  addManagePlansLocationFilter,
  clearManagePlansCheckboxFilters,
  clearManagePlansSearchFilter,
  loadMorePlans,
  removeManagePlansFilter,
  resetManagePlans,
  setManagePlans,
  setManagePlansSearchFilter,
  setManagePlansSort,
} = slice.actions;

export default slice.reducer;
