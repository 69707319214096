import Constants from 'constants/index';

interface getLaunchdDarklyUserProps {
  audience: any;
  email: string;
  fullName: string;
  isInternationalUser: boolean;
  locations: string[];
  userType: string;
}

export const getLaunchdDarklyUser = ({
  audience,
  email,
  fullName,
  isInternationalUser,
  locations,
  userType,
}: getLaunchdDarklyUserProps) => {
  if (isInternationalUser) {
    return {
      anonymous: true,
      audience,
      kind: 'user',
      locations,
      userType,
    };
  }
  return {
    audience,
    email,
    key: email,
    kind: 'user',
    locations,
    name: fullName,
    userType,
  };
};

export const getUserInfo = (
  token: any,
  language?: string,
  country?: { id: string },
) => {
  const {
    nickname = '',
    family_name = '',
    email = '',
    userType = '',
  } = {
    ...token,
  };

  const fullName = `${nickname} ${family_name}`;
  const firstName = `${nickname}`;

  return {
    firstName,
    fullName,
    email,
    userType,
    language,
    country,
  };
};

export const getUserInfoFromAuth = (data: any) => {
  const uniqueLocations = Array.from(
    new Set(data.user.locations.map((loc: any) => loc.location.number)),
  );
  const reducedPermissions = data.user.locations.reduce(
    // @ts-ignore
    (acc, { location: { number }, permissions }) => {
      const updatedAcc = { ...acc };
      permissions.forEach((permission: string) => {
        updatedAcc[permission] = [...(updatedAcc[permission] || []), number];
      });
      return updatedAcc;
    },
    {},
  );
  const isLicensee = data.user.audience === Constants.USER_AUDIENCE.LICENSEE;

  return {
    audience: data.user.audience,
    cdn: data.cdn,
    country: { id: data.user.country },
    features: data.features,
    isLicensee,
    language: data.user.language,
    locations: uniqueLocations,
    locationsWithPermissions: data.user.locations,
    permissions: reducedPermissions,
    selectedLocation: data.user.selectedLocation,
    userId: data.user.id,
  };
};

export const getHighestUserPermission = (permissions = {}) => {
  if (Constants.USER_PERMISSIONS.ADMIN in permissions) {
    return Constants.USER_PERMISSION_NAMES.ADMIN;
  }
  if (Constants.USER_PERMISSIONS.STAKEHOLDER in permissions) {
    return Constants.USER_PERMISSION_NAMES.STAKEHOLDER;
  }
  if (Constants.USER_PERMISSIONS.OPERATOR in permissions) {
    return Constants.USER_PERMISSION_NAMES.OPERATOR;
  }
  if (Constants.USER_PERMISSIONS.LEADER in permissions) {
    return Constants.USER_PERMISSION_NAMES.LEADER;
  }
  if (Constants.USER_PERMISSIONS.TRAINER in permissions) {
    return Constants.USER_PERMISSION_NAMES.TRAINER;
  }
  return Constants.USER_PERMISSION_NAMES.LOGIN;
};
