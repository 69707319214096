import Constants from 'constants/index';
import { useDeviceInfo } from 'utils/device';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IconChartBar,
  IconNotes,
  IconSettings,
  IconReportSearch,
  IconTool,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserFeatureFlags } from 'store/user/selectors';
import { useIsNative } from 'hooks/useIsNative';
import { selectShouldShowMobileNavigationMenu } from 'store/native/selector';
import { selectIsTrainingMode } from 'store/trainingMode/selectors';
import { useEffect } from 'react';
import { setNativeOptions } from 'store/native/slice';
import { clearDocument, setDocument } from 'store/document-persisted/slice';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';
import { Features } from '@/store/user/slice';

interface NavbarProps {
  children: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = ({ children }) => {
  const { isDesktop } = useDeviceInfo();
  const { t } = useTranslation();
  const location = useLocation();
  const isNative = useIsNative();
  const dispatch = useDispatch();
  const isTrainingMode = useSelector(selectIsTrainingMode);
  const userFeatureFlags: Features = useSelector(selectUserFeatureFlags);

  /** Begin important Native Options code **/
  const androidString = window.injectedObject;
  const nativeString = window.nativeOptions;

  useEffect(() => {
    let androidNativeOptions = '';
    let appleNativeOptions = '';
    if (androidString) {
      androidNativeOptions = JSON.parse(androidString.toString()).nativeOptions;
    } else if (nativeString) {
      appleNativeOptions = JSON.parse(nativeString.toString());
    }
    if (appleNativeOptions || androidNativeOptions) {
      dispatch(setNativeOptions(appleNativeOptions || androidNativeOptions));
    } else if (isNative) {
      const defaultNativeMenuOptions = Constants.DEFAULT_NATIVE_MENU_OPTIONS;
      dispatch(setNativeOptions(defaultNativeMenuOptions));
    }
  }, [androidString, dispatch, isNative, nativeString]);
  /** End important Native Options code **/

  useEffect(() => {
    dispatch(setDocument({ routedFrom: 'link', document: null }));
    return () => {
      const docPath = `/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/`;
      if (location.pathname?.startsWith(docPath)) {
        dispatch(clearDocument());
      }
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  const showMobileNavigationMenu = useSelector(
    selectShouldShowMobileNavigationMenu,
  );

  const isExploreTab =
    location.pathname === '/' ||
    location.pathname.startsWith(
      `/${Constants.ROUTE_PATH_NAMES.CATEGORY_PATH_NAME}`,
    );

  const isPlansTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
  );

  const isSettingsTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`,
  );

  const isAdminTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`,
  );

  const isReportsTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}`,
  );

  const tabs = [
    {
      label: t('TrainingPlans.tabExplore'),
      icon: <IconReportSearch />,
      to: '/',
      isActive: isExploreTab,
      showTab: true,
    },
    {
      label: t('TrainingPlans.tabPlans'),
      icon: <IconNotes />,
      to: userFeatureFlags?.plans?.create?.enabled
        ? `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`
        : `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
      isActive: isPlansTab,
      showTab: userFeatureFlags?.plans?.enabled,
    },
    {
      label: t('Generic.reports'),
      icon: <IconChartBar />,
      to: `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`,
      isActive: isReportsTab,
      showTab: userFeatureFlags?.reporting?.enabled,
    },
    {
      label: t('Generic.settings'),
      icon: <IconSettings />,
      to: `/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`,
      isActive: isSettingsTab,
      showTab: userFeatureFlags?.settings?.enabled,
    },
    {
      label: t('Generic.admin'),
      icon: <IconTool />,
      to: `/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`,
      isActive: isAdminTab,
      showTab: userFeatureFlags?.admin?.enabled,
    },
  ];

  const filteredTabs = tabs.filter(tab => {
    if (!!tab.showTab) {
      return true;
    }
    return false;
  });

  return (
    <>
      {isTrainingMode ? (
        children
      ) : isDesktop && !isNative ? (
        <NavbarDesktop tabs={filteredTabs}>{children}</NavbarDesktop>
      ) : (
        <NavbarMobile showMenu={showMobileNavigationMenu} tabs={filteredTabs}>
          {children}
        </NavbarMobile>
      )}
    </>
  );
};

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Navbar;
