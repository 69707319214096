import Constants from 'constants/index';
import { Tooltip } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'styles/theme';
import {
  IconAlertTriangleFilled,
  IconPrinter,
  IconUserPlus,
} from '@tabler/icons-react';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import {
  useGetAssignedCoursesQuery,
  useGetTeamMembersQuery,
} from 'services/pathwayApi';
import { useGetUsersForLocationsQuery } from 'services/xpApi';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ComplianceCourseEnrollmentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCardContent from '../PlanCardContent/PlanCardContent';
import PlanCard from '../PlanCard';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import { getNameFromLanguage } from '@/utils/language';
import { ComplianceCourse } from '@/types/types';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';

interface ManagePlansCompliancePlanCardProps {
  course: ComplianceCourse;
  enrollments: ComplianceCourseEnrollmentDTO[];
  onPrintReport: (id: string) => void;
  onShowEnableCompliancePlan: (id: string, name: string) => void;
}

export interface ComplianceCourseWithUserId
  extends ComplianceCourseEnrollmentDTO {
  userId: string;
}

const ManagePlansCompliancePlanCard: React.FC<
  ManagePlansCompliancePlanCardProps
> = ({ course, enrollments, onPrintReport, onShowEnableCompliancePlan }) => {
  const { t } = useTranslation();
  const { licensee: licenseeFeatureFlag } = useFlags();

  const [totalTeamMembers, setTotalTeamMembers] = useState<number>();
  const completedUsers = Object.values(enrollments)?.filter(
    enrollment =>
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED,
  ).length;
  const allUsersCompleted =
    !!totalTeamMembers && completedUsers === totalTeamMembers;
  const percentComplete =
    totalTeamMembers && (completedUsers / totalTeamMembers) * 100;
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const { data: oldAllTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { skip: !!licenseeFeatureFlag, refetchOnMountOrArgChange: true },
  );

  const { data: xpAllTeamMembersData } = useGetUsersForLocationsQuery(
    { locations: locationsWithAtLeastTrainer },
    { skip: !licenseeFeatureFlag },
  );

  const { data: complianceStatus, isSuccess: isAssignedCoursesSuccess } =
    useGetAssignedCoursesQuery({
      courseId: course.id as string,
      location: locationsWithAtLeastTrainer,
    });

  const data = licenseeFeatureFlag
    ? xpAllTeamMembersData
    : oldAllTeamMembersData;

  useEffect(() => {
    if (
      isAssignedCoursesSuccess &&
      complianceStatus?.enrollments !== undefined
    ) {
      setTotalTeamMembers(
        data
          ?.map(teamMember => {
            return {
              userId:
                (
                  complianceStatus?.enrollments?.[
                    teamMember?.adId as string
                  ] as ComplianceCourseWithUserId
                )?.userId ?? teamMember.adId,
              hidden:
                complianceStatus?.enrollments?.[teamMember?.adId as string]
                  ?.hidden ?? false,
            };
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                user => user.userId === value.userId && !user.hidden,
              ),
          ).length,
      );
    }
  }, [complianceStatus, data, isAssignedCoursesSuccess]);

  return (
    <>
      {!!course && (
        <>
          <PlanCard
            buttons={
              <PopoverMenuButton dataTestId="PlanCardMenu">
                <PopoverMenuButtonItem
                  dataTestId="EnablePlan"
                  icon={<IconUserPlus />}
                  isVisible={!course?.enabled}
                  onClick={() =>
                    onShowEnableCompliancePlan(
                      course?.id,
                      getNameFromLanguage(course?.courseName),
                    )
                  }
                  text={t('Button.enablePlan')}
                />
                <PopoverMenuButtonItem
                  dataTestId="PrintReport"
                  icon={<IconPrinter />}
                  onClick={() => onPrintReport(course?.id)}
                  text={t('Button.printReport')}
                />
              </PopoverMenuButton>
            }
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            dataTestId={'ManagePlansPlanCard'}
            disabled={!course?.enabled}
            link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${
              course?.id
            }/${
              Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME
            }?compliancePlan=${getNameFromLanguage(course?.courseName)}`}
            progress={percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              {allUsersCompleted ? (
                <PlanCardSubHeader
                  className="success"
                  dataTestId="PlanTaskCount"
                >
                  {t('Generic.completedByAll')}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader dataTestId="PlanTaskCount">
                  {totalTeamMembers ? (
                    <>
                      <Tooltip
                        content={t(
                          'TrainingPlans.accountability.outOfCompliance',
                        )}
                        placement="bottom"
                      >
                        <WarningIconAlertTriangleFilled
                          style={{ marginRight: '4px' }}
                        />
                      </Tooltip>
                      {`${completedUsers}/${totalTeamMembers} ${t(
                        'Generic.teamMembersCompleted',
                      )}`}
                    </>
                  ) : (
                    ` ${t('Generic.loading')}`
                  )}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
          </PlanCard>
        </>
      )}
    </>
  );
};

const WarningIconAlertTriangleFilled = styled(IconAlertTriangleFilled)`
  height: 16px;
  width: 16px;
  color: ${() => theme.semanticColors.warning};
`;

export default ManagePlansCompliancePlanCard;
