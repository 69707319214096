import { formatDocumentTag } from 'utils/formatDocumentTag';
import { ContentReference } from '@cfacorp-pathway/xp-api-typescript-client';
import { Modal, ModalBody } from 'cfa-react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ChooseDocumentCard } from '@/components/document';

interface ChooseDocumentProps {
  icon?: string;
  isOpen: boolean;
  onClick: (document: ContentReference) => void;
  onClose?: () => void;
  references: ContentReference[];
}

const ChooseDocument: FC<ChooseDocumentProps> = ({
  icon = '',
  isOpen,
  onClick,
  onClose,
  references = [],
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={() => {
        onClose?.();
      }}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ModalBody>
        <StyledTitle>{t('Generic.chooseVersion')}</StyledTitle>
        <StyledDocumentsWrapper>
          {!!references?.length &&
            [...references].map((document, index) => (
              <ChooseDocumentCard
                icon={icon}
                key={index}
                name={document.name ?? ''}
                onClick={() => onClick(document)}
                tags={document.tags?.map(tag => formatDocumentTag(tag))}
              />
            ))}
        </StyledDocumentsWrapper>
      </ModalBody>
    </Modal>
  );
};

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-size: 2em;
  font-weight: 700;
  padding: 2em 0 1em;
`;

const StyledDocumentsWrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  width: 100%;
`;

export default ChooseDocument;
