import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React from 'react';
import FilterAndSortTeamMembers from '@/pages/Reports/TeamMembers/FilterAndSortTeamMembers';
// @ts-ignore until this file is converted in a separate ticket (not created yet)
import TeamMemberCard from '@/pages/TrainingPlans/ManagePlans/TeamMemberCard/TeamMemberCard';
import {
  clearTeamMembersCheckboxFilters,
  loadMoreTeamMembers,
  setTeamMembersSort,
} from '@/store/teamMembersFilter/slice';
import SortFilterHeader from '@/components/SortFilterHeader/SortFilterHeader';
import { useSortOptions } from '@/hooks/useSortOptions';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from '@/store/teamMembersFilter/selector';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import { TeamMemberWithPlans } from '@/types/types';
import { selectUserIsLicenseeStaff } from '@/store/user/selectors';
import NoMessage from '@/pages/TrainingPlans/SharedComponents/NoMessage';
import LoadMorePaginator from '@/components/LoadMorePaginator/LoadMorePaginator';

interface TeamMembersListProps {
  isFetching: boolean;
  onPrintReport: (id: string, name: string) => void;
}

const TeamMembersList: React.FC<TeamMembersListProps> = ({
  isFetching,
  onPrintReport,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sortOptions = useSortOptions({ reportsTeamMembersTab: true });
  const sort: string = useSelector(selectSort);
  const searchFilter: string = useSelector(selectSearchFilter);
  const { showing, total } = useSelector(selectPagination) as any;
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const teamMembers: TeamMemberWithPlans[] = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const currentFilters: string[] = useSelector(selectFilters);

  return (
    <TeamMembersWrapper>
      <FilterAndSortTeamMembers isFetching={isFetching} />
      <TeamMembersContainer>
        <SortFilterHeader
          label={t('TrainingPlans.filtering.sortBy')}
          onChange={option => {
            dispatch(setTeamMembersSort({ sort: option?.value }));
          }}
          onClear={() => {
            dispatch(clearTeamMembersCheckboxFilters());
          }}
          options={sortOptions}
          showClear={false}
          showCompletedPlansOption={false}
          showMyCompletedPlansOnlySwitchValue={false}
          text={`${total ?? 0} ${t('Reports.tabTeamMembers')}`}
          value={sortOptions?.find(option => option.value === sort)}
        />
        <LoadingOverlay isOpen={isFetching} />
        <TeamMembersCardsList>
          {!!teamMembers?.length && !isFetching && (
            <>
              {teamMembers.map((teamMember, idx) => (
                <TeamMemberCard
                  completedStepCount={teamMember.plansCompleted}
                  id={teamMember.adId ?? ''}
                  isComplete={
                    teamMember.plansCompleted !== 0 &&
                    teamMember.plansCompleted === teamMember.plansAssigned
                  }
                  isReports={true}
                  key={idx}
                  name={teamMember.name}
                  onPrintReport={onPrintReport}
                  startDate={teamMember.startDate}
                  stepCount={teamMember.plansAssigned}
                />
              ))}
            </>
          )}
          {!isFetching &&
            userIsLicenseeStaff &&
            !activeLicenseeLocation?.number && (
              <NoMessage
                message={<Trans i18nKey={'Licensee.chooseALicenseeLocation'} />}
              />
            )}
          {(!!currentFilters?.length || !!searchFilter) && !total && (
            <NoMessage message={t('TrainingPlans.noTeamMembersResults')} />
          )}
        </TeamMembersCardsList>
        <LoadMorePaginator
          onClick={() => dispatch(loadMoreTeamMembers())}
          showing={showing}
          showingText={t('TrainingPlans.showingXOfYTeamMembers', {
            showing,
            total: total ?? 0,
          })}
          total={total ?? 0}
        />
      </TeamMembersContainer>
    </TeamMembersWrapper>
  );
};

const TeamMembersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const TeamMembersContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const TeamMembersCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

export default TeamMembersList;
