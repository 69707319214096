import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import { useEffect, useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconFileCertificate } from '@tabler/icons-react';
import { IconCircleDisabled, IconCircleCheckDisabled } from 'icons';
import { Modal, ModalBody, Typography } from 'cfa-react-components';
import { Widget } from '@typeform/embed-react';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import { v4 as uuidv4 } from 'uuid';
import useBugsnagNotify from 'hooks/useBugsnagNotify';

const PlanItemQuiz = ({
  disabled,
  isActive,
  isComplete,
  language,
  onClick,
  onRefetch,
  planId,
  section,
  statuses,
  step,
  users,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const [lastPolledEventId, setLastPolledEventId] = useState(undefined);
  const [activeQuizScore, setActiveQuizScore] = useState(null);
  const [showPassPopup, setShowPassPopup] = useState(false);
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingRetry, setPollingRetry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showQuizResultsDelayed, setShowQuizResultsDelayed] = useState(false);
  const [showQuizResultsIssue, setShowQuizResultsIssue] = useState(false);
  const [quizKey, setQuizKey] = useState();

  const maxPollingCount = 7; // we poll every 2 seconds, this number would correspond to the time spent polling. 14 seconds at 7

  // list of status objects for current user(s)
  const userStatuses = statuses?.filter(status =>
    users?.find(id => status.userId === id),
  );

  // finds the status of first user taking the quiz that has not passed the quiz
  let activeQuizUserStatuses = userStatuses?.filter(userStatus =>
    userStatus?.steps?.find(
      stepStatus =>
        stepStatus.stepId === step.id &&
        stepStatus.result !== Constants.QUIZ_RESULTS.PASS,
    ),
  );
  if (activeQuizUserStatuses.length === 0) {
    activeQuizUserStatuses = userStatuses;
  }

  const currentUserStatus = activeQuizUserStatuses?.find(
    userStatus => userStatus.userId === users[0],
  );

  const quizStatus = currentUserStatus?.steps?.find(
    stepStatus => stepStatus.stepId === step.id,
  );

  const stepDuration = Math.round((quizStatus?.stepDuration ?? 0) / 60);

  const currentStatusStep = activeQuizUserStatuses?.[0]?.steps?.find(
    stepStatus => stepStatus.stepId === step.id,
  );

  const currentStepEventId = activeQuizUserStatuses?.[0]?.steps?.find(
    stepStatus => stepStatus.stepId === step.id,
  )?.eventId;

  useEffect(() => {
    if (isComplete) {
      return;
    }
    setLastPolledEventId(currentStepEventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuizSubmit = () => {
    setIsLoading(true);
    setPollingCount(1);
  };

  useEffect(() => {
    if (pollingCount > maxPollingCount) {
      if (pollingRetry) {
        setShowQuizResultsIssue(true);
        notifyBugsnag(
          new Error(
            `Quiz error - quiz results issue: ${JSON.stringify({
              step,
              users,
            })}`,
          ),
        );
      } else {
        setShowQuizResultsDelayed(true);
        notifyBugsnag(
          new Error(
            `Quiz error - quiz results delayed:: ${JSON.stringify({
              step,
              users,
            })}`,
          ),
        );
      }
    } else if (pollingCount) {
      const eventIdsAreSame = currentStepEventId === lastPolledEventId;
      if (eventIdsAreSame || !currentStepEventId) {
        setTimeout(() => {
          onRefetch();
          setPollingCount(prevState => prevState + 1);
        }, 2000);
      } else {
        setIsLoading(false);
        setPollingCount(0);
        setPollingRetry(false);
        setLastPolledEventId(currentStepEventId);
        if (currentStatusStep.result === Constants.QUIZ_RESULTS.PASS) {
          setActiveQuizScore(
            Math.round(
              (currentStatusStep.score / currentStatusStep.totalQuestions) *
                100,
            ),
          );
          setShowPassPopup(true);
        } else {
          setShowFailPopup(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingCount]);

  const handlePassContinue = () => {
    onRefetch();
    setShowPassPopup(false);
  };

  const handleFailContinue = () => {
    onRefetch();
    setQuizKey(uuidv4());
    setShowFailPopup(false);
  };

  const handleRetakeQuiz = () => {
    setQuizKey(uuidv4());
    setPollingCount(0);
    setIsLoading(false);
    setPollingRetry(false);
    setShowQuizResultsIssue(false);
    setShowQuizResultsDelayed(false);
    onRefetch();
  };

  const handleClick = () => {
    setQuizKey(uuidv4());
    onClick();
  };

  const quizWidgetMemo = useMemo(
    () => (
      <StyledWidget
        disableScroll={true}
        hidden={{
          userIds: users.toString(),
          checklistId: planId,
          stepId: step.id,
          language: language,
        }}
        hideHeaders={true}
        id={step.quizId}
        key={quizKey}
        onSubmit={() => {
          handleQuizSubmit(step.id);
        }}
      />
    ),
    [users, planId, step.id, step.quizId, language, quizKey],
  );

  return (
    <>
      <div>
        <TaskOrProcedureBody
          $isActive={isActive && !isComplete}
          $isDisabled={disabled}
          $isInSection={!!section}
        >
          {isComplete ? (
            <IconWrapper $isPlanComplete={true}>
              <IconCircleCheckDisabled />
            </IconWrapper>
          ) : (
            <DisabledIconWrapper>
              <IconCircleDisabled />
            </DisabledIconWrapper>
          )}
          <TypeAndNameWrapper
            $isDisabled={disabled}
            onClick={
              !disabled
                ? () => {
                    handleClick();
                  }
                : null
            }
          >
            <TypeHeader data-testid="TaskTitle" variant="overline3">
              {t('Generic.quiz')}
              {isComplete &&
                ` - ${t('Generic.completedIn')} ${stepDuration} ${t(
                  'Generic.mins',
                )}`}
            </TypeHeader>
            <Typography data-testid="QuizName" variant="body1">
              {getNameFromLanguage(step?.name, language)}
            </Typography>
          </TypeAndNameWrapper>
          <QuizIconWrapper
            $canExpand={!disabled}
            onClick={
              !disabled
                ? () => {
                    handleClick();
                  }
                : null
            }
          >
            <IconFileCertificate />
          </QuizIconWrapper>
        </TaskOrProcedureBody>
        {isComplete && (
          <StyledPassMessage data-testid="PassMessage" variant="body1">
            {users.length === 1 && quizStatus?.score
              ? t('TrainingPlans.quizzes.quizSuccessPopup', {
                  score: `${Math.round(
                    (quizStatus?.score / quizStatus?.totalQuestions) * 100,
                  )}`,
                })
              : t('TrainingPlans.quizzes.quizPassed')}
          </StyledPassMessage>
        )}
      </div>
      {isActive && !isComplete && <QuizWrapper>{quizWidgetMemo}</QuizWrapper>}
      <StyledConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizSuccessPopup', {
          score: activeQuizScore,
        })}
        bodyTextColor="success"
        headerText={t('TrainingPlans.quizzes.congratulations')}
        isOpen={showPassPopup}
        onClose={handlePassContinue}
        overlayProps={{ style: { zIndex: 10003 } }}
        primaryButtonHandler={handlePassContinue}
        primaryButtonText={t('Button.continue')}
      />
      <StyledConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizFail')}
        headerText={t('TrainingPlans.quizzes.youDidNotPass')}
        isOpen={showFailPopup}
        onClose={handleFailContinue}
        overlayProps={{ style: { zIndex: 10003 } }}
        primaryButtonHandler={handleFailContinue}
        primaryButtonText={t('Button.continue')}
      />
      <StyledConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizResultsDelayed')}
        headerText={t('TrainingPlans.quizzes.calculatingQuiz')}
        isOpen={showQuizResultsDelayed}
        overlayProps={{ style: { zIndex: 10003 } }}
        primaryButtonHandler={handleRetakeQuiz}
        primaryButtonText={t('Button.retake')}
        secondaryButtonHandler={() => {
          setPollingCount(1);
          setPollingRetry(true);
          setShowQuizResultsDelayed(false);
        }}
        secondaryButtonText={t('Button.wait')}
        showCloseButton={false}
      />
      <StyledConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizResultsIssue')}
        headerText={t('TrainingPlans.quizzes.calculatingQuiz')}
        isOpen={showQuizResultsIssue}
        overlayProps={{ style: { zIndex: 10003 } }}
        primaryButtonHandler={handleRetakeQuiz}
        primaryButtonText={t('Button.retake')}
        showCloseButton={false}
      />
      <StyledModal
        disableOverlayClick={true}
        overlayProps={{ style: { zIndex: 10002 } }}
        show={isLoading}
      >
        <StyledModalBody>
          <Typography color="default" variant="h3">
            {t('TrainingPlans.quizzes.quizWaitingForResults')}
          </Typography>
        </StyledModalBody>
      </StyledModal>
    </>
  );
};

const TypeHeader = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
`;

// this very high z-index is required because typeform's has their z-index set at 10001
const StyledConfirmationModal = styled(ConfirmationModal)`
  z-index: 10003;
`;

// this very high z-index is required because typeform's has their z-index set at 10001
const StyledModal = styled(Modal)`
  z-index: 10002;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 92px 133px;
`;

const TaskOrProcedureBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  cursor: ${props => !props.$isDisabled && 'pointer'};
  padding-bottom: ${props => props.$isActive && '10px'};
`;

const TypeAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${props => !props.$isDisabled && 'pointer'};
`;

const IconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
`;

const DisabledIconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: 'default';
`;

const QuizWrapper = styled.div`
  display: flex;
  height: 500px;
  width: 100%;
`;

const StyledPassMessage = styled(Typography)`
  padding-left: 41px;
  color: ${props => props.theme?.semanticColors?.success};
`;

const StyledWidget = styled(Widget)`
  width: 100%;
`;

const QuizIconWrapper = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
  display: flex;
  width: 25px;
  margin: 0 5px;
  cursor: ${props => props.$canExpand && 'pointer'};
`;

PlanItemQuiz.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  section: PropTypes.object,
  statuses: PropTypes.array.isRequired,
  step: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

PlanItemQuiz.defaultProps = {
  disabled: false,
  section: null,
};

// memo to prevent re-renders when taking the quiz
export default memo(PlanItemQuiz, (prevProps, nextProps) => {
  return (
    prevProps.isActive === nextProps.isActive &&
    prevProps.isComplete === nextProps.isComplete &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.step === nextProps.step &&
    prevProps.statuses === nextProps.statuses &&
    prevProps.language === nextProps.language
  );
});
