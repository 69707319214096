import Constants from 'constants/index';
import { getCountryFromApiCountry } from 'utils/international';
import { getLdClient } from 'utils/ldClient';
import {
  getLaunchdDarklyUser,
  getUserInfo,
  getUserInfoFromAuth,
  // @ts-ignore
} from 'utils/user';
import type {
  AccessToken,
  AuthState,
  OktaAuth,
  UserClaims,
} from '@okta/okta-auth-js';
import { selectUser } from 'store/user/selectors';
import {
  initializeUserFromAuth,
  initializeUser as initializeUserFromStore,
} from 'store/user/slice';
import Bugsnag from '@bugsnag/browser';
import i18n from 'i18next';
import { LDClient } from 'launchdarkly-react-client-sdk';
import type { AppStore } from 'store';
import { sendNativeMessage } from '../hooks/useNativeListener';
import { userInitialized } from '../store/app/slice';
import { pathwayApi } from '../services/pathwayApi';
import { mockedAuthData } from '../utils/testData/loadDefaultAuthUser';
import { formatBugsnagErrorMessage, setupBugsnag } from '@/utils/bugsnag';
import { initializeLanguage } from '@/i18n';
import oktaAuth from '@/okta/oktaConfig';
import { setPreferredLicenseeLocation } from '@/store/licenseeLocationPicker/slice';

// isNative hook stand in
const reactNativeWebView = window.ReactNativeWebView;

let accessToken: string | AccessToken | undefined;
let androidUser: AuthState;
if (
  reactNativeWebView &&
  typeof reactNativeWebView.injectedObjectJson === 'function'
) {
  androidUser = JSON.parse(reactNativeWebView.injectedObjectJson());
}

const getUserFromToken = () => {
  if (window.userFromToken) {
    accessToken = window.accessToken;
    return JSON.parse(window.userFromToken);
  } else if (androidUser) {
    accessToken = androidUser.accessToken;
    return androidUser.userFromToken;
  }
};
const userFromToken = getUserFromToken();

export const initializeUser = (store: AppStore) => {
  if (Constants.IS_IN_CYPRESS_TEST_LOCAL || Constants.IS_IN_JEST_TEST) {
    return store.dispatch(initializeUserFromAuth(mockedAuthData));
  }

  // default to true until it can be set from auth or token
  let isInternationalUser = true;
  const isOperatorOrLicensee = (claims: UserClaims) => {
    return (
      claims['cfa_aud']?.includes(Constants.USER_AUDIENCE.LICENSEE) ||
      claims['cfa_aud']?.includes(Constants.USER_AUDIENCE.OPERATOR)
    );
  };

  const isLicenseeStaff = (claims: UserClaims) => {
    return claims['cfa_perms']?.['P20']?.LICENSEESTAFF?.length > 0;
  };

  (oktaAuth as OktaAuth).authStateManager.subscribe(
    async (authState: AuthState) => {
      if (!authState?.accessToken?.claims) {
        return;
      }
      accessToken = authState?.accessToken?.accessToken;
      isInternationalUser = !!authState?.accessToken?.claims?.[
        'cfa-loc-iso_country'
      ]?.includes(Constants.SUPPORTED_COUNTRIES.GB.id);
      let country = { id: '' };
      let language = '';
      const userFromAuth = await getCfaCdnToken(
        authState?.accessToken?.claims?.email,
        store,
        isInternationalUser,
      );

      // setting international user off of auth if it was made
      if (userFromAuth?.country?.id) {
        country = userFromAuth?.country;
        language = userFromAuth?.language;
        isInternationalUser =
          userFromAuth?.country?.id === Constants.SUPPORTED_COUNTRIES.GB.id;
        store.dispatch(
          initializeUserFromAuth({
            isInternationalUser: isInternationalUser,
          }),
        );
      }

      if (!isInternationalUser) {
        setupBugsnag();
      }

      //convert these to use auth call if possible, currently don't get staff locations back from auth call
      if (isLicenseeStaff(authState.accessToken.claims)) {
        getLocationsForLicenseeStaff(store, isInternationalUser);
      }
      if (isOperatorOrLicensee(authState.accessToken.claims)) {
        if (userFromAuth?.selectedLocation) {
          store.dispatch(
            setPreferredLicenseeLocation(userFromAuth.selectedLocation),
          );
        }
        getLocationConceptsDataAndStore(store, isInternationalUser);
      }

      if (!userFromAuth?.language || !userFromAuth?.country?.id) {
        const { language: languageFromPref, country: countryFromPref } =
          await getUserLanguageAndCountryPreference(
            authState,
            isInternationalUser,
          ).then(
            async ({
              langCode,
              country: userCountry,
              prefLicenseeLocation,
            }) => {
              if (prefLicenseeLocation) {
                store.dispatch(
                  setPreferredLicenseeLocation(prefLicenseeLocation),
                );
              }

              const remotePreferencesStateToLocalUserObject = {
                language: langCode,
                country: userCountry,
              };
              store.dispatch(
                initializeUserFromStore(
                  remotePreferencesStateToLocalUserObject,
                ),
              );
              await i18n.changeLanguage(langCode);
              return remotePreferencesStateToLocalUserObject;
            },
          );
        language = languageFromPref;
        country = countryFromPref;
      } else {
        await i18n.changeLanguage(language);
      }

      const user = getUserInfo(
        authState?.accessToken?.claims,
        language,
        country,
      );
      let isValidUser = false;
      if (!user || !userFromAuth) {
        const err = 'User info cannot be read from token or auth call';
        if (!isInternationalUser) {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        }
        console.error(err);
      } else if (
        !userFromAuth.permissions ||
        !Object.keys(userFromAuth.permissions)?.length
      ) {
        const err = 'User permissions do not exist in auth call';
        if (!isInternationalUser) {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        }
        console.error(err);
      } else {
        isValidUser = true;
      }
      if (!isValidUser) {
        await oktaAuth.signOut({
          postLogoutRedirectUri: Constants.PATHWAY_LOGOUT_REDIRECT,
        });
      } else {
        store.dispatch(initializeUserFromStore(user));
      }
      store.dispatch(userInitialized(true));
    },
  );

  // Loading site from RNWV
  if (userFromToken) {
    if (isLicenseeStaff(userFromToken)) {
      getLocationsForLicenseeStaff(store, isInternationalUser);
    }
    if (isOperatorOrLicensee(userFromToken)) {
      getLocationConceptsDataAndStore(store, isInternationalUser);
    }
    getUserLanguageAndCountryPreference(
      userFromToken,
      isInternationalUser,
    ).then(async ({ langCode, country: userCountry, prefLicenseeLocation }) => {
      if (prefLicenseeLocation) {
        store.dispatch(setPreferredLicenseeLocation(prefLicenseeLocation));
      }

      const remotePreferencesStateToLocalUserObject = {
        language: langCode,
        country: userCountry,
      };
      store.dispatch(
        initializeUserFromStore(remotePreferencesStateToLocalUserObject),
      );

      await i18n.changeLanguage(langCode).then();
      initializeLanguage();

      const user = getUserInfo(userFromToken, langCode, userCountry);
      const userFromAuth = await getCfaCdnToken(
        userFromToken['email'],
        store,
        isInternationalUser,
      );
      let isValidUser = false;
      if (!user || !userFromAuth) {
        const err = 'User info cannot be read from token or auth call';
        if (!isInternationalUser) {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        }
        console.error(err);
      } else if (
        !userFromAuth.permissions ||
        !Object.keys(userFromAuth.permissions)?.length
      ) {
        const err = 'User permissions do not exist in auth call';
        if (!isInternationalUser) {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        }
        console.error(err);
      } else {
        isValidUser = true;
      }
      if (!isValidUser) {
        oktaAuth.signOut({
          postLogoutRedirectUri: Constants.PATHWAY_LOGOUT_REDIRECT,
        });
      } else {
        if (userFromAuth?.selectedLocation) {
          store.dispatch(
            setPreferredLicenseeLocation(userFromAuth.selectedLocation),
          );
        }
        store.dispatch(initializeUserFromStore(user));
      }
      store.dispatch(userInitialized(true));
      return remotePreferencesStateToLocalUserObject;
    });
  }
};

const getCfaCdnToken = async (
  userEmail: string | undefined = '',
  store: AppStore,
  isInternationalUser: boolean,
) => {
  try {
    // TODO: remove flag check when fully migrated to XP API
    const ldClient = await getLdClient(userEmail, isInternationalUser);
    const flags = ldClient.allFlags();

    const usingXpApi = Boolean(flags['xp-api']);
    const baseUrl: string | undefined = usingXpApi
      ? Constants.XP_API_BASE_URL
      : Constants.PATHWAY_NATIVE_API_URL;

    const resp = await fetch(`${baseUrl}/auth`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include',
    });
    const data = await resp.json();
    if (data.cdn && data.features && data.user) {
      const userFromAuth = getUserInfoFromAuth(data);
      store.dispatch(initializeUserFromAuth(userFromAuth));
      store.dispatch(pathwayApi.endpoints.getDocumentCookie.initiate());
      return userFromAuth;
    }
  } catch (err) {
    if (!isInternationalUser) {
      Bugsnag.notify(formatBugsnagErrorMessage(err));
    }
    console.error(err);
  }
};

async function getLocationsForLicenseeStaff(
  store: AppStore,
  isInternationalUser: boolean,
) {
  return fetch(
    `${Constants.PATHWAY_API.BASE_URL}/locations?type=${Constants.LOCATION_TYPES.LIC}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then(data => data.json())
    .then(
      (
        userLocationData: [
          {
            conceptType: string;
            locations: [
              {
                name: string;
                number: string;
              },
            ];
            totalLocations: 0;
          },
        ],
      ) => {
        store.dispatch(
          initializeUserFromStore({
            licenseeLocations:
              userLocationData?.find(
                type => type.conceptType === Constants.LOCATION_TYPES.LIC,
              )?.locations ?? [],
          }),
        );
        return userLocationData;
      },
    )
    .catch(err => {
      if (!isInternationalUser) {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      }
      console.error(err);
    });
}

async function getLocationConceptsDataAndStore(
  store: AppStore,
  isInternationalUser: boolean,
) {
  return fetch(`${Constants.PATHWAY_API.BASE_URL}/locations/me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(data => data.json())
    .then(userLocationData => {
      store.dispatch(
        initializeUserFromStore({
          franchiseeLocations: userLocationData?.franchiseeLocations ?? [],
          licenseeLocations: userLocationData?.licenseeLocations ?? [],
          supportCenterLocation:
            userLocationData?.supportCenterLocation ?? null,
        }),
      );
      return userLocationData;
    })
    .catch(err => {
      if (!isInternationalUser) {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      }
      console.error(err);
    });
}

const getUserLanguageAndCountryPreference = async (
  authState: AuthState,
  isInternationalUser: boolean,
): Promise<{
  langCode: string;
  country: {
    id: string;
  };
  prefLicenseeLocation?: string | null;
}> => {
  let langCode = Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
  let country = Constants.SUPPORTED_COUNTRIES.US;
  let prefLicenseeLocation: string | null = null;

  // when loaded from RNWV, (ie, userFromToken exists), look for injected window.language setting
  // if language not requested, fallback to fetching the user's preference
  if (userFromToken && window.language) {
    const language =
      window.language || Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
    const userCountry = { id: window.country || country?.id };
    return { langCode: language, country: userCountry };
  }

  try {
    const resp = await fetch(
      `${Constants.PATHWAY_API.BASE_URL}/users/preferences`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const prefs: {
      country?: string;
      language?: string;
      location?: string;
      userId?: string;
    } = await resp.json();

    const userPrefLang = prefs?.language;
    const userPrefLoc = prefs?.location;
    if (userPrefLang) {
      langCode = userPrefLang;
    }
    if (userPrefLoc) {
      prefLicenseeLocation = userPrefLoc;
    }
    if (prefs?.country) {
      country = getCountryFromApiCountry(prefs.country);
    } else if (
      authState?.accessToken?.claims?.['cfa-loc-iso_country'].includes(
        Constants.SUPPORTED_COUNTRIES.GB.id,
      )
    ) {
      country = Constants.SUPPORTED_COUNTRIES.GB;
    }
  } catch (err) {
    if (!isInternationalUser) {
      Bugsnag.notify(formatBugsnagErrorMessage(err));
    }
    console.error(err);
  } finally {
    return { langCode, country, prefLicenseeLocation };
  }
};

export const initializeLaunchDarklyUser = ({
  ldClient,
  store,
}: {
  ldClient: LDClient;
  store: AppStore;
}) => {
  const state = store.getState();
  const user = selectUser(state);

  sendNativeMessage({
    type: 'updateUser',
    payload: {
      user,
    },
  });
  const ldUser = getLaunchdDarklyUser(user);
  // if browser setting > cookies > "Do not track" then user info wont show in Users list page in LD
  // although the user info should still have been posted to LD for use in flag targeting: apparently
  // "Do not track" browser setting impacts the LD analytics api (events.launchdarkly.com)
  // but for user flag targeting the feature flag API (app.launchdarkly.com) is used
  // https://docs.launchdarkly.com/guides/best-practices/user-data
  if (ldUser && ldUser.email) {
    ldClient.identify(ldUser, undefined, function () {});
  }
};
