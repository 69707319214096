import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container as DragAndDropContainer,
  DropResult,
} from 'react-smooth-dnd';
import { useEditCategoryMutation } from 'services/pathwayApi';
import styled from 'styled-components';
import { arrayMoveImmutable } from 'array-move';
import isEqual from 'lodash/isEqual';
import {
  selectUsersHighestPermissionLevel,
  selectUserCountry,
} from 'store/user/selectors';
import { selectSubcategoryReducerState } from 'store/subcategory/selectors';
import {
  selectDocumentForDeletion,
  selectDocumentForVisibilityToggle,
  selectDocumentVisibilityFlagForConfirmationPopup,
  selectSelectedAdminSubcategoryIndex,
  selectSelectedAdminSubtitleIndex,
  selectTemporaryCategory,
} from 'store/admin/selectors';
import {
  Button,
  Icon,
  Tag,
  Typography,
  IconButton,
  AccordionItem,
  Accordion,
  TextField,
  TextFieldType,
} from 'cfa-react-components';
import theme from 'styles/theme';
import {
  setSelectedCategoryId,
  setTemporaryCategory,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setShowAddAdminAddProcedureToSubtitle,
  setDocumentForVisibilityToggle,
  setDocumentVisibilityForConfirmationPopup,
  setDocumentForDeletion,
} from 'store/admin/slice';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconCornerDownRight,
  IconEdit,
  IconEye,
  IconEyeOff,
  IconGripVertical,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { setSubcategoryId } from '../../store/subcategory/slice';
import AddResourceToSubtitleButton from './AddResourceToSubtitleButton';
import SubtitleDocuments from './SubtitleDocuments';
import Subtitle from './Subtitle';
import {
  IntlCategoryDTO,
  IntlSubcategoryDTO,
} from '@/services/content-api-types';
import { LanguageObject } from '@/types/types';

interface SubcategoryAccordionProps {
  adminCategoryId: null | string;
  cancelEditSection: boolean;
  category: IntlCategoryDTO;
  countryName: string;
  handleEditSection: ({
    index,
    language,
    name,
    subtitleIndex,
  }: {
    index: any;
    language: string;
    name: LanguageObject | string;
    subtitleIndex: any;
  }) => void;
  handleSubcategoryVisibility: (subcategory: string) => void;
  index: any;
  isAddProcedurePopUp?: boolean;
  isCanada: boolean;
  isEditingSubcategory: boolean;
  onSaveSubtitle: (
    pendingSectionMutation: { name: LanguageObject },
    index: string,
  ) => void;
  onSaveSubtitles: (updatedSubtitles: IntlSubcategoryDTO[], index: any) => void;
  onShowToast: (name: string) => void;
  openInNewTab?: boolean;
  refetchCategories: () => void;
  RenderDocumentEndContent?: () => void;
  setIsEditingSubcategory: (isEditing: true) => void;
  setSubcategory: (isSubcategory?: boolean) => void;
  showAdminFunctionality: boolean;
  showSubtitleEditFunctionality: boolean;
  subcategory: { name: LanguageObject; subtitles: IntlSubcategoryDTO[] };
  subcategoryClassName: string;
  subcategoryIsVisible: boolean;
  triggerCancelEditSectionState: () => void;
  triggerEnableCancelButton: () => void;
}

export interface SubtitleProps {
  index: any;
  name: LanguageObject;
  documents?: DocumentDTO[];
  enabled?: boolean;
  visibleDocuments?: DocumentDTO[];
}

export const SubcategoryAccordion: React.FC<SubcategoryAccordionProps> = ({
  adminCategoryId,
  cancelEditSection,
  category,
  countryName,
  handleEditSection,
  handleSubcategoryVisibility,
  index,
  isAddProcedurePopUp,
  isCanada,
  isEditingSubcategory,
  onSaveSubtitle,
  onSaveSubtitles,
  onShowToast,
  openInNewTab,
  refetchCategories,
  RenderDocumentEndContent,
  setIsEditingSubcategory,
  setSubcategory,
  showAdminFunctionality,
  showSubtitleEditFunctionality,
  subcategory,
  subcategoryClassName,
  subcategoryIsVisible = true,
  triggerCancelEditSectionState,
  triggerEnableCancelButton,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name: subcategoryName, subtitles } = subcategory;
  const selectedSubcategoryId = useSelector(selectSubcategoryReducerState);
  const selectedTemporaryCategory = useSelector(selectTemporaryCategory);
  const selectedCountry = useSelector(selectUserCountry);
  const selectedDocumentForVisibilityToggle = useSelector(
    selectDocumentForVisibilityToggle,
  );
  const selectedSubcategoryIndex = useSelector(
    selectSelectedAdminSubcategoryIndex,
  );
  const selectedSubtitleIndex = useSelector(selectSelectedAdminSubtitleIndex);
  const documentBeingMutatedIsVisible = useSelector(
    selectDocumentVisibilityFlagForConfirmationPopup,
  );
  const selectedDocumentForDeletion = useSelector(selectDocumentForDeletion);
  const [editCategory] = useEditCategoryMutation();
  const [mutableSubtitles, setMutableSubtitles] = useState(subtitles);
  const [showConfirmationVisibilityPopUp, setShowConfirmationVisibilityPopUp] =
    useState(false);
  const [
    showConfirmationForDocumentVisibilityPopup,
    setShowConfirmationForDocumentVisibilityPopup,
  ] = useState(false);
  const [
    showConfirmationForDocumentDeletionPopup,
    setShowConfirmationForDocumentDeletionPopup,
  ] = useState(false);
  const [showDeleteSubcategoryPopUp, setShowDeleteSubcategoryPopUp] =
    useState(false);
  const [showDeleteSectionPopUp, setShowDeleteSectionPopUp] = useState(false);
  const [editSection, setEditSection] = useState(false);
  const initialAddSectionState = {
    name: { en: '', es: '' },
  };
  const [pendingSectionMutation, setPendingSectionMutation] = useState(
    initialAddSectionState,
  );
  const [mutatingSubcategorySection, setMutatingSubcategorySection] =
    useState(false);
  const [addSectionFormIsValid, setAddSectionFormIsValid] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [subtitle, setSubtitle] = useState<SubtitleProps>({
    index: '',
    name: { en: '', es: '' },
    documents: [],
    visibleDocuments: [],
  });

  let userIsAdmin = false;
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  if (showAdminFunctionality) {
    userIsAdmin = Constants.USER_PERMISSIONS.ADMIN === userPermissionLevel;
  }

  useEffect(() => {
    if (isCanada && pendingSectionMutation.name.en.length) {
      setAddSectionFormIsValid(true);
    } else if (
      pendingSectionMutation.name.en.length &&
      pendingSectionMutation.name.es.length
    ) {
      setAddSectionFormIsValid(true);
    } else {
      setAddSectionFormIsValid(false);
    }
  }, [pendingSectionMutation, selectedCountry.LABEL_KEY, isCanada]);

  useEffect(() => {
    setPendingSectionMutation({
      name: { en: '', es: '' },
    });
    setMutatingSubcategorySection(false);
  }, [selectedSubcategoryId, setMutatingSubcategorySection]);

  useEffect(() => {
    if (subtitles) {
      setMutableSubtitles(subtitles);
    }
  }, [subtitles]);

  useEffect(() => {
    if (cancelEditSection) {
      setEditSection(false);
      triggerCancelEditSectionState();
    }
  }, [cancelEditSection, triggerCancelEditSectionState]);

  // We need this to avoid an edge case bug (see P20-2425)
  useEffect(() => {
    if (subtitle?.documents?.length! > 0) {
      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
  }, [subtitle?.documents]);

  const onSubcategoryEdit = () => {
    setSubcategory(true);
    setIsEditingSubcategory(true);
    triggerEnableCancelButton();
  };

  const onNameChange = (e: React.ChangeEvent<TextFieldType>) => {
    const name = e.target.value;
    if (name.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: name,
                es: (selectedTemporaryCategory as any)?.subcategories[
                  index as any
                ]?.name.es,
              },
            },
          }),
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: '',
                es: (selectedTemporaryCategory as any)?.subcategories[index]
                  ?.name.es,
              },
            },
          }),
        }),
      );
    }
  };

  const onTranslationChange = (e: React.ChangeEvent<TextFieldType>) => {
    const translation = e.target.value;
    if (translation.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: (selectedTemporaryCategory as any)?.subcategories[index]
                  ?.name.en,
                es: translation,
              },
            },
          }),
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: (selectedTemporaryCategory as any)?.subcategories[index]
                  ?.name.en,
                es: '',
              },
            },
          }),
        }),
      );
    }
  };

  const updateArrayIndex = (
    array: IntlSubcategoryDTO[],
    removedIndex: number,
    addedIndex: number,
  ) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(item => {
      return { ...item };
    });
  };

  const onDrop = (dropResult: DropResult) => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex } = dropResult;

    const updatedSubtitles = updateArrayIndex(
      mutableSubtitles,
      removedIndex,
      addedIndex as number,
    );
    setMutableSubtitles(updatedSubtitles as IntlSubcategoryDTO[]);
    onSaveSubtitles(updatedSubtitles as IntlSubcategoryDTO[], index);
  };

  const handleAdminAddResourceToNewSubtitle = (
    subcategoryIndex: any,
    subtitleIndex: any,
  ) => {
    dispatch(setSelectedCategoryId(adminCategoryId));
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setShowAddAdminAddProcedureToSubtitle(true));
  };

  const handleDocumentVisibilityToggleForSubtitle = (
    subcategoryIndex: any,
    subtitleIndex: any,
    document: DocumentDTO,
    documentIsMarkedAsVisible: boolean,
  ) => {
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForVisibilityToggle(document));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    setShowConfirmationForDocumentVisibilityPopup(true);
  };

  const handleAddResourceToExistingSubtitle = (
    subcategoryIndex: any,
    subtitleIndex: any,
  ) => {
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setShowAddAdminAddProcedureToSubtitle(true));
    setEditSection(false);
  };

  const handleDocumentDeleteFromSubcategorySubtitle = (
    subcategoryIndex: any,
    subtitleIndex: string,
    document: DocumentDTO,
    documentIsMarkedAsVisible: boolean,
    documentIndex: any,
  ) => {
    dispatch(setSelectedCategoryId(adminCategoryId));
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForDeletion({ ...document, index: documentIndex }));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    setShowConfirmationForDocumentDeletionPopup(true);
  };

  const resetSubtitleMutationState = () => {
    dispatch(setSelectedSubcategoryIndex(null));
    dispatch(setSelectedSubtitleIndex(null));
    dispatch(setDocumentVisibilityForConfirmationPopup(null));
  };

  const onCancelToggleSubtitleDocumentVisibility = () => {
    resetSubtitleMutationState();
    dispatch(setDocumentForVisibilityToggle(null));
    setShowConfirmationForDocumentVisibilityPopup(false);
  };

  const onCancelToggleSubtitleDocumentDeletion = () => {
    resetSubtitleMutationState();
    dispatch(setDocumentForDeletion(null));
    setShowConfirmationForDocumentDeletionPopup(false);
  };

  const deleteDocumentFromSubtitle = (isInEditSectionMode: boolean) => {
    const { id: documentId, name: documentName } =
      selectedDocumentForDeletion as any;
    if (isInEditSectionMode) {
      setSubtitle({
        ...subtitle,
        visibleDocuments: documentBeingMutatedIsVisible
          ? subtitle?.visibleDocuments?.filter(id => id !== documentId)
          : subtitle?.visibleDocuments,
        documents: subtitle?.documents?.filter(({ id }) => id !== documentId),
      } as SubtitleProps);
    }

    const mapSubcategoriesFunc = (
      subcat: IntlSubcategoryDTO,
      mappedSubcatIndex: any,
    ) => {
      return mappedSubcatIndex === selectedSubcategoryIndex
        ? {
            ...subcat,
            subtitles: [
              ...subcat?.subtitles!.map(
                (mappedSubtitle, mappedSubtitleIndex) => {
                  return mappedSubtitleIndex === selectedSubtitleIndex
                    ? {
                        ...mappedSubtitle,
                        documents: mappedSubtitle.documents.filter(
                          doc =>
                            doc.id !== (selectedDocumentForDeletion as any)?.id,
                        ),
                        visibleDocuments:
                          mappedSubtitle?.visibleDocuments?.filter(
                            docId =>
                              docId !==
                              (selectedDocumentForDeletion as any)?.id,
                          ),
                      }
                    : mappedSubtitle;
                },
              ),
            ],
          }
        : subcat;
    };

    const updatedTemporaryCategory = {
      ...category,
      subcategories:
        selectedTemporaryCategory.subcategories.map(mapSubcategoriesFunc),
    };

    if (
      (selectedDocumentForDeletion as any)?.id ===
      category?.subcategories?.[selectedSubcategoryIndex as any]?.subtitles?.[
        selectedSubtitleIndex as any
      ]!.documents?.[(selectedDocumentForDeletion as any)?.index]?.id
    ) {
      const updatedCategory = {
        ...category,

        subcategories: category?.subcategories!.map(mapSubcategoriesFunc),
      };

      editCategory(updatedCategory).then(() => {
        dispatch(setTemporaryCategory(updatedTemporaryCategory));
        onShowToast(getNameFromLanguage(documentName));
        dispatch(setDocumentForDeletion(null));
        setShowConfirmationForDocumentDeletionPopup(false);
      });
    } else {
      dispatch(setTemporaryCategory(updatedTemporaryCategory));
      setShowConfirmationForDocumentDeletionPopup(false);
      dispatch(setDocumentForDeletion(null));
    }
  };

  const onToggleSubtitleDocumentVisibility = (isInEditSectionMode: boolean) => {
    // @ts-ignore
    const { id: documentId } = selectedDocumentForVisibilityToggle;
    const mutableCategory = cloneDeep(selectedTemporaryCategory);
    const documentIsCurrentlyVisible =
      // @ts-ignore
      mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
        selectedSubtitleIndex
      ].visibleDocuments.indexOf(documentId) > -1;

    if (isInEditSectionMode) {
      setSubtitle({
        ...subtitle,
        visibleDocuments: documentIsCurrentlyVisible
          ? subtitle?.visibleDocuments?.filter(id => id !== documentId)
          : // @ts-ignore
            [...subtitle.visibleDocuments, documentId],
      } as SubtitleProps);
    }
    if (documentIsCurrentlyVisible) {
      // @ts-ignore
      mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
        selectedSubtitleIndex
      ].visibleDocuments = mutableCategory.subcategories[ // @ts-ignore
        selectedSubcategoryIndex
      ].subtitles[selectedSubtitleIndex].visibleDocuments
        .filter((id: string) => id !== documentId);
    } else {
      // @ts-ignore
      mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
        selectedSubtitleIndex
      ].visibleDocuments.push(documentId);
    }
    resetSubtitleMutationState();
    dispatch(setTemporaryCategory(mutableCategory));
    dispatch(setDocumentForVisibilityToggle(null));
    setShowConfirmationForDocumentVisibilityPopup(false);
  };

  const onToggleSubcategoryVisibility = () => {
    handleSubcategoryVisibility(getNameFromLanguage(subcategory.name));
    setShowConfirmationVisibilityPopUp(false);
  };

  const onDeleteSubcategory = () => {
    setShowDeleteSubcategoryPopUp(false);
    const futureSubcategories = [...selectedTemporaryCategory.subcategories];
    const removedSubcategory = futureSubcategories.splice(index, 1);

    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: futureSubcategories,
      }),
    );

    // checking to make sure we are not in editing mode
    if (isEqual(selectedTemporaryCategory, category)) {
      editCategory({
        ...category,
        subcategories: futureSubcategories,
      }).then(() => {
        refetchCategories();
        // @ts-ignore
        onShowToast(getNameFromLanguage(removedSubcategory?.[0]?.name));
        dispatch(
          setSubcategoryId({
            id: -1,
          }),
        );
      });
    }
  };

  const onHandleDeleteSection = (idx: any, name: LanguageObject) => {
    setSubtitle({
      index: idx,
      name,
    });
    setShowDeleteSectionPopUp(true);
  };

  const onHandleEditSection = (
    idx: string,
    name: LanguageObject,
    documents: DocumentDTO[],
    visibleDocuments: DocumentDTO[],
  ) => {
    setEditSection(true);
    setSubtitle({
      index: idx,
      name,
      documents,
      visibleDocuments,
    });
  };

  const handleAddSectionCancel = () => {
    setMutatingSubcategorySection(false);
    setPendingSectionMutation(initialAddSectionState);
  };

  const handleAddSection = () => {
    setPendingSectionMutation(initialAddSectionState);
    setMutatingSubcategorySection(true);
  };

  const onPendingSectionNameChange = (
    event: React.ChangeEvent<TextFieldType>,
  ) => {
    const name = event.target.value;
    if (name.length) {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: name, es: pendingSectionMutation.name.es },
      });
    } else {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: '', es: pendingSectionMutation.name.es },
      });
    }
  };

  const onPendingSectionTranslationChange = (
    event: React.ChangeEvent<TextFieldType>,
  ) => {
    const translation = event.target.value;
    if (translation.length) {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: pendingSectionMutation.name.en, es: translation },
      });
    } else {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: pendingSectionMutation.name.en, es: '' },
      });
    }
  };

  const onDeleteSection = () => {
    setShowDeleteSectionPopUp(false);
    const futureSubcategories = [...selectedTemporaryCategory.subcategories];
    const futureSubtitles = [
      ...[...(selectedTemporaryCategory as any)?.subcategories][index]
        ?.subtitles,
    ];
    // @ts-ignore
    const removedSubtitle = futureSubtitles.splice(subtitle.index, 1);

    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: futureSubcategories.map((it, ind) =>
          ind === index
            ? {
                ...(it as any),
                subtitles: (it as any).subtitles.filter(
                  // @ts-ignore
                  // eslint-disable-next-line
                  (it, idx) => idx !== subtitle.index,
                ),
              }
            : it,
        ),
      }),
    );

    // checking to make sure we are not in editing mode
    if (isEqual(selectedTemporaryCategory, category)) {
      editCategory({
        ...category,
        subcategories: futureSubcategories.map((it, ind) =>
          ind === index
            ? {
                ...(it as any),
                subtitles: (it as any).subtitles.filter(
                  // @ts-ignore
                  // eslint-disable-next-line
                  (it, idx) => idx !== subtitle.index,
                ),
              }
            : it,
        ),
      }).then(refetchCategories);
    }

    // We guard against adding a subtitle and deleting it before it's saved
    getNameFromLanguage(removedSubtitle?.[0]?.name) &&
      onShowToast(getNameFromLanguage(removedSubtitle?.[0]?.name));
  };

  const handleDocumentConfirmationsOnClose = () => {
    setShowConfirmationForDocumentDeletionPopup(false);
    setShowConfirmationForDocumentVisibilityPopup(false);
    resetSubtitleMutationState();
    dispatch(setDocumentForDeletion(null));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentBeingMutatedIsVisible),
    );
  };

  const onAddSection = () => {
    setSubtitle({
      ...subtitle,
      documents: [],
      visibleDocuments: [],
    } as SubtitleProps);
    handleAddSection();
  };

  return (
    <Accordion
      data-testid={`Subcategory${index}`}
      defaultActiveItemKey={null}
      elevation={1}
      key={index.toString()}
      onClick={() =>
        (isEditingSubcategory &&
          selectedSubcategoryId.id !== index &&
          !isAddProcedurePopUp) ||
        (!isEditingSubcategory && !isAddProcedurePopUp)
          ? setSubcategory()
          : null
      }
      variant="surface"
    >
      {/** @ts-ignore */}
      <AccordionItem
        data-testid={`SubTitle${index}`}
        header={
          <AccordionHeader>
            {!!userIsAdmin && !!showAdminFunctionality && (
              <StyledSixDotIcon className={subcategoryClassName} />
            )}
            {isEditingSubcategory && selectedSubcategoryId.id === index ? (
              <>
                <SubcategoryTitleRow onClick={e => e.stopPropagation()}>
                  <StyledFormControlWrapper>
                    <TextField
                      data-testid={'EditSubcategoryNameInput'}
                      fullWidth
                      label={t('Admin.subcategory')}
                      onChange={onNameChange}
                      placeholder={'Enter Name'}
                      required={true}
                      value={
                        // @ts-ignore
                        selectedTemporaryCategory.subcategories[index].name.en
                      }
                    />
                  </StyledFormControlWrapper>
                  <StyledTranslationRow>
                    <IconCornerDownRight size={35} />
                    <TextField
                      data-testid={'EditSubcategoryTranslationInput'}
                      fullWidth
                      label={t('Admin.spanishTranslation')}
                      onChange={onTranslationChange}
                      placeholder={'Enter Translation'}
                      required={
                        countryName !==
                        Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
                      }
                      value={
                        // @ts-ignore
                        selectedTemporaryCategory.subcategories[index].name.es
                      }
                    />
                  </StyledTranslationRow>
                </SubcategoryTitleRow>
                {!!userIsAdmin && !!showAdminFunctionality && (
                  <EditAdminFeaturesWrapper>
                    {subcategoryIsVisible ? (
                      <StyledShowTag
                        label={t('Admin.categoryIsVisible')}
                        leadingElement={<IconEye width={10} />}
                        onClick={() => setShowConfirmationVisibilityPopUp(true)}
                        variant="filled"
                      />
                    ) : !subcategoryIsVisible ? (
                      <StyledHideTagWrapper
                        onClick={() => setShowConfirmationVisibilityPopUp(true)}
                      >
                        <StyledHideTag
                          disabled
                          label={t('Admin.categoryHidden')}
                          leadingElement={<IconEyeOff width={10} />}
                          variant="filled"
                        />
                      </StyledHideTagWrapper>
                    ) : null}
                    <StyledDeleteIconButton
                      aria-label="Delete Category"
                      color="default"
                      data-testid="EditCategoryTrashIcon"
                      href=""
                      onClick={() => setShowDeleteSubcategoryPopUp(true)}
                      size="md"
                    >
                      <IconTrash />
                    </StyledDeleteIconButton>
                  </EditAdminFeaturesWrapper>
                )}
              </>
            ) : (
              <AccordionHeaderRow>
                <AccordionTitle variant="body1">
                  {getNameFromLanguage(subcategoryName)}
                </AccordionTitle>
                {!!userIsAdmin && !!showAdminFunctionality && (
                  <AdminFeaturesWrapper>
                    {subcategoryIsVisible ? (
                      <StyledShowTag
                        data-testid="EditSubcategoryToggleShow"
                        label={t('Admin.categoryIsVisible')}
                        leadingElement={<IconEye width={10} />}
                        onClick={(e: React.FormEvent<HTMLFormElement>) => {
                          // We do this to prevent the accordion from opening/closing
                          e.stopPropagation();
                          setShowConfirmationVisibilityPopUp(true);
                        }}
                        variant="filled"
                      />
                    ) : !subcategoryIsVisible ? (
                      <StyledHideTagWrapper
                        onClick={e => {
                          e.stopPropagation();
                          setShowConfirmationVisibilityPopUp(true);
                        }}
                      >
                        <StyledHideTag
                          data-testid="EditSubcategoryToggleHide"
                          disabled
                          label={t('Admin.categoryHidden')}
                          leadingElement={<IconEyeOff width={10} />}
                          variant="filled"
                        />
                      </StyledHideTagWrapper>
                    ) : null}
                    <PopoverMenuButton>
                      <PopoverMenuButtonItem
                        icon={<IconEdit />}
                        onClick={onSubcategoryEdit}
                        text={t('Generic.rename')}
                      />
                      <PopoverMenuButtonItem
                        icon={<IconTrash />}
                        isDestructive={true}
                        onClick={() => setShowDeleteSubcategoryPopUp(true)}
                        text={t('Button.delete')}
                      />
                    </PopoverMenuButton>
                  </AdminFeaturesWrapper>
                )}
              </AccordionHeaderRow>
            )}
          </AccordionHeader>
        }
        onClick={e => e.stopPropagation()}
      >
        <StyledContainerWrapper>
          {/** @ts-ignore it's yelling about children but we need children for the drag and drop */}
          <DragAndDropContainer
            className="StyledDragAndDropContainer"
            dragHandleSelector=".subtitle-drag-handle"
            onDrop={e => onDrop(e)}
          >
            {!editSection &&
              mutableSubtitles?.map((mutableSubtitle, subtitleIndex) => {
                return (
                  <Subtitle
                    countryName={selectedCountry.id as string}
                    handleDocumentDelete={(
                      document,
                      documentIsMarkedAsVisible,
                      documentIndex,
                    ) => {
                      handleDocumentDeleteFromSubcategorySubtitle(
                        index,
                        subtitleIndex as any,
                        document,
                        documentIsMarkedAsVisible,
                        documentIndex,
                      );
                    }}
                    handleDocumentVisibilityToggleForSubtitle={(
                      document,
                      documentIsMarkedAsVisible,
                    ) => {
                      handleDocumentVisibilityToggleForSubtitle(
                        index,
                        subtitleIndex,
                        document,
                        documentIsMarkedAsVisible,
                      );
                    }}
                    key={`subtitle-${subtitleIndex}`}
                    onAdminAddResourceToSubtitle={() =>
                      handleAdminAddResourceToNewSubtitle(index, subtitleIndex)
                    }
                    onDeleteSubtitle={(idx: any, name: LanguageObject) =>
                      onHandleDeleteSection(idx, name)
                    }
                    onEditSubtitle={({
                      index: idx,
                      name,
                      documents,
                      visibleDocuments,
                    }: SubtitleProps) =>
                      onHandleEditSection(
                        idx as string,
                        name,
                        documents as DocumentDTO[],
                        visibleDocuments as DocumentDTO[],
                      )
                    }
                    // @ts-ignore
                    onSaveSubtitles={onSaveSubtitles}
                    openInNewTab={openInNewTab as boolean}
                    RenderDocumentEndContent={
                      RenderDocumentEndContent as () => void
                    }
                    showAdminFunctionality={showAdminFunctionality}
                    showSubtitleEditFunctionality={
                      showSubtitleEditFunctionality
                    }
                    subcategoryIndex={index}
                    // @ts-ignore
                    subtitle={mutableSubtitle}
                    subtitleIndex={subtitleIndex}
                    subtitles={mutableSubtitles}
                    triggerEnableCancelButton={triggerEnableCancelButton}
                    userIsAdmin={userIsAdmin}
                  />
                );
              })}
          </DragAndDropContainer>
        </StyledContainerWrapper>
        {!!userIsAdmin && !!showAdminFunctionality && (
          <>
            {!mutatingSubcategorySection && !editSection ? (
              <AddSectionButton
                color="secondary"
                onClick={onAddSection}
                variant="text"
              >
                <StyledPlusIcon icon={IconPlus} size="sm" />
                <Typography
                  color="secondary"
                  data-testid="AddSubcategorySection"
                  variant="body1"
                >
                  {t('Admin.addSection')}
                </Typography>
              </AddSectionButton>
            ) : (
              <>
                <StyledAddSectionRow>
                  <TextField
                    data-testid={'EditSectionNameInput'}
                    fullWidth
                    label={t('Admin.sectionName')}
                    onChange={
                      editSection
                        ? event => {
                            handleEditSection({
                              index,
                              language: Constants.LANGUAGE_OPTIONS.ENGLISH,
                              name: event.target.value,
                              subtitleIndex: subtitle?.index as string,
                            });
                            setSubtitle(prev => {
                              return {
                                ...prev,
                                name: {
                                  en: event.target.value,
                                  es: prev?.name.es,
                                },
                              } as SubtitleProps;
                            });
                          }
                        : onPendingSectionNameChange
                    }
                    placeholder={t('Admin.addSectionNamePlaceholder')}
                    required={true}
                    value={
                      editSection
                        ? subtitle?.name.en
                        : pendingSectionMutation.name.en
                    }
                  />
                </StyledAddSectionRow>
                <StyledAddSectionRow>
                  <IconCornerDownRight size={35} />
                  <TextField
                    data-testid={'EditSectionNameTranslationInput'}
                    fullWidth
                    label={t('Admin.spanishTranslation')}
                    onChange={
                      editSection
                        ? event => {
                            handleEditSection({
                              index,
                              language: Constants.LANGUAGE_OPTIONS.SPANISH,
                              name: event.target.value,
                              subtitleIndex: subtitle?.index as string,
                            });
                            setSubtitle(prev => {
                              return {
                                ...prev,
                                name: {
                                  en: prev?.name.en,
                                  es: event.target.value,
                                },
                              } as SubtitleProps;
                            });
                          }
                        : onPendingSectionTranslationChange
                    }
                    placeholder={t('Admin.addSectionTranslationPlaceholder')}
                    required={
                      countryName !==
                      Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
                    }
                    value={
                      editSection
                        ? subtitle?.name.es
                        : pendingSectionMutation.name.es
                    }
                  />
                </StyledAddSectionRow>
                <>
                  {showDocuments && (
                    <SubtitleDocuments
                      contentToRenderAtDocumentEnd={RenderDocumentEndContent}
                      disableSixDotMenuHandles={true}
                      handleDocumentDelete={(
                        document,
                        documentIsMarkedAsVisible,
                        documentIndex,
                      ) =>
                        handleDocumentDeleteFromSubcategorySubtitle(
                          index,
                          subtitle?.index as string,
                          document,
                          documentIsMarkedAsVisible,
                          documentIndex,
                        )
                      }
                      handleDocumentVisibilityToggleForSubtitle={(
                        document,
                        documentIsMarkedAsVisible,
                      ) =>
                        handleDocumentVisibilityToggleForSubtitle(
                          index,
                          subtitle?.index as string,
                          document,
                          documentIsMarkedAsVisible,
                        )
                      }
                      openDocumentInNewTab={openInNewTab as boolean}
                      subtitle={subtitle as SubtitleProps}
                    />
                  )}
                  {subtitle?.index !== '' && (
                    <AddResourceToSubtitleButton
                      onAdminAddResourceToSubtitle={() =>
                        handleAddResourceToExistingSubtitle(
                          index,
                          subtitle?.index as string,
                        )
                      }
                    />
                  )}
                </>
                {!editSection && (
                  <StyledAddSectionRow>
                    <AddSectionActionsWrapper>
                      <Button
                        color="secondary"
                        onClick={handleAddSectionCancel}
                        variant="outlined"
                      >
                        {t('Button.cancel')}
                      </Button>
                      <Button
                        color="secondary"
                        data-testid="AddSectionSaveButton"
                        disabled={!addSectionFormIsValid}
                        onClick={() => {
                          setMutatingSubcategorySection(false);
                          onSaveSubtitle(
                            pendingSectionMutation,
                            index as string,
                          );
                        }}
                        variant="filled"
                      >
                        {t('Button.add')}
                      </Button>
                    </AddSectionActionsWrapper>
                  </StyledAddSectionRow>
                )}
              </>
            )}
          </>
        )}
      </AccordionItem>
      <ConfirmationModal
        bodyText={
          subcategoryIsVisible
            ? t('Admin.makeHiddenSubcategoryBody', {
                categoryName: getNameFromLanguage(subcategory?.name),
                countryName,
              })
            : t('Admin.makeVisibleSubcategoryBody', {
                categoryName: getNameFromLanguage(subcategory?.name),
                countryName,
              })
        }
        headerText={
          subcategoryIsVisible
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showConfirmationVisibilityPopUp}
        onClose={(e: React.ChangeEvent<TextFieldType>) => {
          e.stopPropagation();
          setShowConfirmationVisibilityPopUp(false);
        }}
        primaryButtonColor={subcategoryIsVisible ? 'primary' : 'secondary'}
        primaryButtonHandler={(e: React.ChangeEvent<TextFieldType>) => {
          e.stopPropagation();
          onToggleSubcategoryVisibility();
        }}
        primaryButtonText={
          subcategoryIsVisible
            ? t('Button.makeHidden')
            : t('Button.makeVisible')
        }
        secondaryButtonHandler={e => {
          e.stopPropagation();
          setShowConfirmationVisibilityPopUp(prev => !prev);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Generic.deleteObject', {
          name: getNameFromLanguage(subcategory?.name),
          object: t('Admin.subcategory').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: t('Admin.subcategory'),
        })}
        isOpen={showDeleteSubcategoryPopUp}
        onClose={() => setShowDeleteSubcategoryPopUp(false)}
        primaryButtonHandler={onDeleteSubcategory}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={prev => setShowDeleteSubcategoryPopUp(!prev)}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Generic.deleteObject', {
          name: getNameFromLanguage(subtitle?.name as LanguageObject),
          object: t('Generic.section').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: t('Generic.section'),
        })}
        isOpen={showDeleteSectionPopUp}
        onClose={e => {
          e.stopPropagation();
          setShowDeleteSectionPopUp(false);
        }}
        primaryButtonHandler={e => {
          e.stopPropagation();
          onDeleteSection();
        }}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={e => {
          e.stopPropagation();
          setShowDeleteSectionPopUp(prev => !prev);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={
          documentBeingMutatedIsVisible
            ? t('Admin.makeHiddenDocumentBody', {
                countryName: selectedCountry.LABEL_KEY ?? '',
                documentName:
                  getNameFromLanguage(
                    // @ts-ignore
                    selectedDocumentForVisibilityToggle?.name,
                  ) ?? '',
              })
            : t('Admin.makeVisibleDocumentBody', {
                countryName: selectedCountry.LABEL_KEY ?? '',
                documentName:
                  getNameFromLanguage(
                    // @ts-ignore
                    selectedDocumentForVisibilityToggle?.name,
                  ) ?? '',
              })
        }
        headerText={
          documentBeingMutatedIsVisible
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showConfirmationForDocumentVisibilityPopup}
        onClose={e => {
          e.stopPropagation();
          handleDocumentConfirmationsOnClose();
        }}
        primaryButtonColor={
          documentBeingMutatedIsVisible ? 'primary' : 'secondary'
        }
        primaryButtonHandler={e => {
          e.stopPropagation();
          onToggleSubtitleDocumentVisibility(editSection);
        }}
        primaryButtonText={
          documentBeingMutatedIsVisible
            ? t('Button.makeHidden')
            : t('Button.makeVisible')
        }
        secondaryButtonHandler={e => {
          e.stopPropagation();
          onCancelToggleSubtitleDocumentVisibility();
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Generic.removeObject', {
          // @ts-ignore
          name: getNameFromLanguage(selectedDocumentForDeletion?.name) ?? '',
          object: t('Generic.resource').toLowerCase(),
        })}
        headerText={t('Generic.removeHeader', {
          type: t('Generic.resource'),
        })}
        isOpen={showConfirmationForDocumentDeletionPopup}
        onClose={e => {
          e.stopPropagation();
          handleDocumentConfirmationsOnClose();
        }}
        primaryButtonHandler={e => {
          e.stopPropagation();
          deleteDocumentFromSubtitle(editSection);
        }}
        primaryButtonText={t('Button.remove')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={e => {
          e.stopPropagation();
          onCancelToggleSubtitleDocumentDeletion();
        }}
        secondaryButtonText={t('Button.cancel')}
      />
    </Accordion>
  );
};

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const AccordionHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const AddSectionButton = styled(Button)`
  min-width: 0;
  padding: 0 1em !important;
`;

const AccordionTitle = styled(Typography)`
  margin-left: 16px;
`;

const StyledTranslationRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledDeleteIconButton = styled(IconButton)`
  margin-left: auto;
`;

const StyledAddSectionRow = styled.div`
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AddSectionActionsWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
`;

const StyledPlusIcon = styled(Icon)`
  margin-right: 8px;
  color: ${() => theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const SubcategoryTitleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
  padding: 0 16px;
`;

const StyledFormControlWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
`;

const StyledSixDotIcon = styled(IconGripVertical)`
  width: 25px;
  height: 25px;
  cursor: move;
  z-index: 2;
  color: ${() => theme.grayScale.gray3};
`;

const StyledContainerWrapper = styled.div`
  width: 100%;
  border-top: ${props => `1px solid ${props.theme.grayScale.gray2}`};
  padding-top: 15px;
`;

const AdminFeaturesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const EditAdminFeaturesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledShowTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 16px;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledHideTagWrapper = styled('span')`
  margin-left: 16px;
  cursor: pointer;
`;

const StyledHideTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;
