import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { BaseReferenceDTO } from '@/services/content-api-types';
import useDocumentCookieRefresh from '@/hooks/useDocumentCookieRefresh';
import Constants from '@/constants';

interface DocumentPreviewProps {
  previewDocument: Document | BaseReferenceDTO;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  previewDocument,
}) => {
  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();
  const url = useMemo(() => {
    if (isBaseReferenceDTO(previewDocument)) {
      return `${Constants.PATHWAY_API_CONTENT_URL}/${previewDocument.versionId}/${previewDocument.fileId}/index.html`;
    }
    if (
      [Document.sourceSystem.BRIGHTCOVE, Document.sourceSystem.GAME].includes(
        previewDocument.sourceSystem!,
      )
    ) {
      return previewDocument.contentUrl;
    }
    return previewDocument.contentApiUrl;
  }, [previewDocument]);

  if (!isDocumentCookieSuccess) {
    return null;
  }

  return (
    <StyledIFrame allow="fullscreen" className="content-iframe" src={url} />
  );
};

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

const isBaseReferenceDTO = (
  doc: Document | BaseReferenceDTO,
): doc is BaseReferenceDTO => {
  return (doc as BaseReferenceDTO).documentId !== undefined;
};

export default memo(DocumentPreview);
