import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import Document from './Document';
import { SubtitleProps } from './SubCategoryAccordion';

interface SubtitleDocumentsProps {
  contentToRenderAtDocumentEnd: any;
  disableSixDotMenuHandles?: boolean;
  handleDocumentVisibilityToggleForSubtitle: (
    document: DocumentDTO,
    documentIsCurrentlyVisible: boolean,
  ) => void;
  handleDocumentDelete: (
    document: DocumentDTO,
    documentIsCurrentlyVisible: boolean,
    documentIndex: any,
  ) => void;
  openDocumentInNewTab: boolean;
  subtitle: SubtitleProps;
}

const SubtitleDocuments: React.FC<SubtitleDocumentsProps> = ({
  contentToRenderAtDocumentEnd,
  disableSixDotMenuHandles,
  openDocumentInNewTab,
  handleDocumentVisibilityToggleForSubtitle,
  handleDocumentDelete,
  subtitle,
}) => {
  const documentVisibilityCheck = (
    document: DocumentDTO,
    documentSubtitle: SubtitleProps,
  ) => {
    const { visibleDocuments: visibleDocumentIds, documents: allDocumentIds } =
      documentSubtitle;
    const visibleDocumentIndex = visibleDocumentIds?.indexOf(
      document?.id as DocumentDTO,
    );
    const hiddenDocumentIndex = allDocumentIds?.indexOf(
      document?.id as DocumentDTO,
    );

    if (
      typeof visibleDocumentIndex !== 'undefined' &&
      visibleDocumentIndex >= 0
    ) {
      return true;
    }
    if (
      visibleDocumentIndex &&
      hiddenDocumentIndex &&
      visibleDocumentIndex < 0 &&
      hiddenDocumentIndex >= 0
    ) {
      return false;
    }
  };
  return (
    <>
      {subtitle?.documents?.map((document: DocumentDTO, documentIndex: any) => {
        const documentIsCurrentlyVisible = documentVisibilityCheck(
          document,
          subtitle,
        );
        return (
          <Document
            disableSixDotMenuHandles={disableSixDotMenuHandles}
            document={document}
            documentIsCurrentlyVisible={documentIsCurrentlyVisible as boolean}
            handleDocumentDeleteButton={() => {
              handleDocumentDelete(
                document,
                documentIsCurrentlyVisible as boolean,
                documentIndex,
              );
            }}
            handleDocumentVisibilityToggle={() => {
              handleDocumentVisibilityToggleForSubtitle(
                document,
                documentIsCurrentlyVisible as boolean,
              );
            }}
            inSectionPreview={true}
            key={documentIndex}
            openInNewTab={openDocumentInNewTab}
            RenderDocumentEndContent={contentToRenderAtDocumentEnd}
          />
        );
      })}
    </>
  );
};

export default SubtitleDocuments;
