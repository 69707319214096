import { isSignalPath } from 'utils/url';
import { useEffect } from 'react';
import { sendNativeMessage } from 'hooks/useNativeListener';
import { getCurrentLanguage } from 'i18n/language';
import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Header from './Header/Header';
import Subheader from './Subheader/Subheader';
import WebAlertBannerContainer from './WebAlertBanner/WebAlertBannerContainer';
import SmartBannerContainer from './SmartBanner/SmartBannerContainer';
import Footer from './Footer/Footer';
import TrainingModeWrapper from './TrainingModeWrapper/TrainingModeWrapper';
import LayoutWrapper from './LayoutWrapper/LayoutWrapper';
import MainContent from './Content/MainContent';
import RoutesContainer from '@/app/Routes';

const Layout = () => {
  const location = useLocation();
  const isSignalHeadlessView = isSignalPath(location) ?? false;

  useEffect(() => {
    sendNativeMessage({
      type: 'languageChanged',
      payload: getCurrentLanguage(),
    });
  }, []);

  return !isSignalHeadlessView ? (
    <>
      <LayoutWrapper>
        <TrainingModeWrapper>
          <Navbar>
            <WebAlertBannerContainer />
            <SmartBannerContainer />
            <Header />
            <Subheader />
            <MainContent>
              <Toaster position="top-center" />
              <RoutesContainer />
            </MainContent>
            <Footer />
          </Navbar>
        </TrainingModeWrapper>
      </LayoutWrapper>
    </>
  ) : (
    <MainContent>
      <Toaster position="top-center" />
      <RoutesContainer />
    </MainContent>
  );
};

export default Layout;
