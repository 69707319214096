import { getNameFromLanguage } from 'utils/language';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Typography,
} from 'cfa-react-components';
import { IconArrowLeft } from '@tabler/icons-react';
import { ChecklistTemplateDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import Section from '@/pages/TrainingPlans/BuildPlans/EditablePlanItems/Section';
import { LanguageObject } from '@/types/types';

interface PreviewTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCopy: (id: string) => void;
  onTemplateBackButtonClick: () => void;
  template: ChecklistTemplateDTO;
}

const PreviewTemplateModal: React.FC<PreviewTemplateModalProps> = ({
  isOpen,
  onClose,
  onCopy,
  onTemplateBackButtonClick,
  template,
}) => {
  const { t } = useTranslation();

  return (
    <StyledModal
      onClose={onClose}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <StyledModalHeader>
        <StyledHeaderContentWrapper>
          <BackButtonWrapper>
            <StyledBackButton
              color="secondary"
              onClick={onTemplateBackButtonClick}
              size="sm"
              variant="text"
            >
              <StyledIconArrowLeft />
            </StyledBackButton>
          </BackButtonWrapper>
          <div>
            <StyledOverlineTypography variant="overline1">
              {t('TrainingPlans.preview')}
            </StyledOverlineTypography>
            {`${getNameFromLanguage(template.name as LanguageObject)}`}
          </div>
          <StyledEmptyDiv />
        </StyledHeaderContentWrapper>
      </StyledModalHeader>
      <StyledModalBody>
        <StyledContainer>
          {template?.sections?.map((section, index) => (
            <Section
              index={index}
              isPreview={true}
              key={section.id}
              plan={template}
              section={section}
            />
          ))}
        </StyledContainer>
      </StyledModalBody>
      <StyledModalFooter>
        <StyledCopyButton
          color="secondary"
          data-testid="copyTemplateFromPreviewButton"
          onClick={() => onCopy(template.id as string)}
          variant="filled"
        >
          {t('Button.copy')}
        </StyledCopyButton>
      </StyledModalFooter>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;
const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: center;
  /* needed to override cfa design system */
  padding-bottom: 0 !important;
`;

const StyledHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledEmptyDiv = styled.div`
  flex: 1;
  min-width: 155px;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  white-space: nowrap;
`;

const StyledBackButton = styled(Button)`
  padding-left: 0;
  transform: translateX(-10px);
`;

const StyledIconArrowLeft = styled(IconArrowLeft)`
  padding-right: 4px;
`;

const StyledOverlineTypography = styled(Typography)`
  margin-bottom: 0.5rem;
`;

const StyledModalBody = styled(ModalBody)`
  text-align: unset;
`;

const StyledModalFooter = styled(ModalFooter)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledCopyButton = styled(Button)`
  min-width: unset;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem 0;
  width: 100%;
`;

export default PreviewTemplateModal;
