import { ContentReference } from '@cfacorp-pathway/xp-api-typescript-client';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  contentReferenceToDocument,
  extractBodyContent,
  fixTridionImageSources,
} from './helpers';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { XpApiError } from '@/services/api-types';
import {
  useGetCompliancePlanUrlQuery,
  useGetResourceQuery,
} from '@/services/xpApi';
import { setDocument, setIcon } from '@/store/document-persisted/slice';
import { selectUserCountry, selectUserLanguage } from '@/store/user/selectors';
import { selectDocumentFromDashboardOrResource } from '@/store/document-persisted/selectors';
import { selectAppReducerState } from '@/store/app/selectors';

const documentParamsSchema = z.object({
  documentId: z.string(),
  isCompliance: z
    .string()
    .default('false')
    .transform(val => val === 'true'),
});

/** Document params coming from route. */
export const useDocumentParams = () => {
  const params = useParams();
  const location = useLocation();
  const search = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParams: Record<string, string> = {};
    queryParams.forEach((value, key) => {
      searchParams[key] = value;
    });
    return searchParams;
  }, [location.search]);

  const parsedParams = useMemo(
    () => documentParamsSchema.safeParse({ ...params, ...search }),
    [params, search],
  );

  if (!parsedParams.success) {
    throw new Error(
      `Document Route Params Error: ${JSON.stringify(
        parsedParams.error.errors,
        null,
        2,
      )}`,
    );
  }

  return parsedParams.data;
};

/** Select and find document or fetch document if none in state. */
export const useDocument = (routeDocumentId: string, isCompliance: boolean) => {
  const [chosenReference, setChosenReference] = useState<ContentReference>();

  const documentState = useAppSelector(selectDocumentFromDashboardOrResource);
  const needFetchResource =
    !documentState || documentState?.id !== routeDocumentId;

  const dispatch = useAppDispatch();
  const { resource, error, isFetching } = useDocumentResource(
    routeDocumentId,
    needFetchResource && !isCompliance,
  );

  // auto choose reference if only one
  useEffect(() => {
    if (!resource?.references) {
      return;
    }

    if (resource.references.length > 1) {
      dispatch(setIcon(resource.icon));
    }

    if (resource.references.length === 1) {
      setChosenReference(resource.references[0]);
      return;
    }
  }, [resource]); // eslint-disable-line react-hooks/exhaustive-deps

  // set document based on chosen reference
  useEffect(() => {
    if (!chosenReference) {
      return;
    }

    const document = contentReferenceToDocument(
      chosenReference,
      routeDocumentId,
    );

    dispatch(
      setDocument({
        document,
        routedFrom: 'link',
        icon: resource?.icon,
      }),
    );
  }, [chosenReference]); // eslint-disable-line react-hooks/exhaustive-deps

  const chooseReference = useMemo(
    () => ({
      references: resource?.references ?? [],
      setChosenReference,
      modalOpen:
        chosenReference === undefined &&
        (resource?.references?.length ?? 0) > 1 &&
        needFetchResource,
    }),
    [chosenReference, resource?.references, needFetchResource],
  );

  return {
    ...documentState,
    icon: resource?.icon || documentState?.icon,
    error,
    isFetching,
    chooseReference,
  };
};

export const useDocumentResource = (
  documentId: string,
  fetchResource: boolean,
) => {
  const userCountry = useAppSelector(selectUserCountry);
  const userLanguage = useAppSelector(selectUserLanguage);
  const { data, error, ...rest } = useGetResourceQuery(
    {
      id: documentId,
      country: userCountry.id,
      language: userLanguage,
    },
    {
      skip: !userLanguage || !userCountry.id || !fetchResource,
    },
  );

  return { resource: data, error: error as XpApiError | undefined, ...rest };
};

const documentCache = new Map<string, string>();

export const useTridionDocument = (url?: string) => {
  const [htmlContent, setHtmlContent] = useState<string>();
  const [error, setError] = useState(false);
  const { userInitialized } = useAppSelector(selectAppReducerState);

  useEffect(() => {
    if (!url || !userInitialized) {
      return;
    }

    (async () => {
      if (documentCache.has(url)) {
        setHtmlContent(documentCache.get(url));
        return;
      }

      const response = await fetch(url, {
        credentials: 'include',
      });

      if (!response.ok) {
        console.error(
          `Tridion document failed to fetch: ${response.status} ${response.statusText}`,
        );
        setError(true);
      }

      const html = await response.text();
      setHtmlContent(response.ok ? extractBodyContent(html) : html);
      if (response.ok) {
        documentCache.set(url, extractBodyContent(html));
      }
    })();
  }, [url, userInitialized]);

  const contentFixedSrc = useMemo(
    () => fixTridionImageSources(htmlContent),
    [htmlContent],
  );

  return { htmlContent: contentFixedSrc, error };
};

export const useComplianceCourse = (courseId: string) => {
  const { data: complianceUrl, error } = useGetCompliancePlanUrlQuery({
    courseId,
  });

  return {
    complianceUrl: complianceUrl?.url,
    error: error as XpApiError | undefined,
  };
};
