import {
  Button,
  Surface,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import styled from 'styled-components';
import ToggleButtons from './ToggleButtons';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import {
  isOperatorConsultant,
  selectAllLocationsWithAtLeastTrainer,
  selectUserIsLicenseeStaff,
  selectUserLicenseeLocations,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { setShowLicenseeLocationPicker } from '@/store/licenseeLocationPicker/slice';
import {
  addTeamMembersLocationFilter,
  removeTeamMembersFilter,
} from '@/store/teamMembersFilter/slice';
import { selectFilters } from '@/store/teamMembersFilter/selector';
import CheckboxFilterSection from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import StickyArea from '@/components/Cards/StickyFilterCard/StickyArea';

interface FilterAndSortTeamMembersProps {
  isFetching: boolean;
}

const FilterAndSortTeamMembers: React.FC<FilterAndSortTeamMembersProps> = ({
  isFetching,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { licensee: licenseeFeatureFlag } = useFlags();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const currentFilters: string[] = useSelector(selectFilters);
  const locations: string[] = useSelector(selectAllLocationsWithAtLeastTrainer);

  return (
    <>
      {!isSmAndDown &&
        ((locations && locations?.length > 1) ||
          (userIsLicenseeStaff && licenseeFeatureFlag)) &&
        !isFetching && (
          <StickyArea>
            {!!isOperatorConsultantUser && licenseeFeatureFlag && (
              <ToggleButtons />
            )}
            <FilterCardSurface elevation={1} radii="sm" variant="outlined">
              {isOperatorConsultantUser &&
                isLicenseePlans &&
                licenseeFeatureFlag && (
                  <CheckboxFilterSection
                    labels={userLicenseeLocations.reduce((acc, location) => {
                      // eslint-disable-next-line no-param-reassign
                      acc = {
                        ...acc,
                        [location?.number]: {
                          translationString: location?.name,
                          value: !!currentFilters.includes(location?.number),
                        },
                      };
                      return acc;
                    }, {})}
                    onChange={(value: string) => {
                      if (!!currentFilters.includes(value)) {
                        dispatch(removeTeamMembersFilter({ filter: value }));
                      } else {
                        dispatch(
                          addTeamMembersLocationFilter({ filter: value }),
                        );
                      }
                    }}
                    title={t('Licensee.licenseeLocations')}
                  />
                )}
              {locations?.length > 1 && (
                <CheckboxFilterSection
                  labels={locations.reduce(
                    (acc, loc) => ({
                      ...acc,
                      [loc]: {
                        translationString: loc,
                        value: !!currentFilters.includes(loc),
                      },
                    }),
                    {},
                  )}
                  onChange={(value: string) => {
                    if (!!currentFilters.includes(value)) {
                      dispatch(removeTeamMembersFilter({ filter: value }));
                    } else {
                      dispatch(addTeamMembersLocationFilter({ filter: value }));
                    }
                  }}
                  title={t('Generic.locations')}
                />
              )}
              {!!userIsLicenseeStaff && !isSmAndDown && licenseeFeatureFlag && (
                <StaffChooseLocationContainer>
                  <Typography fontWeight="bold" variant="body1">
                    {t('Licensee.licenseeLocation')}
                  </Typography>
                  {activeLicenseeLocation?.name && (
                    <Typography variant="body1">
                      {`${activeLicenseeLocation.number} ${activeLicenseeLocation.name}`}
                    </Typography>
                  )}
                  <Button
                    color="secondary"
                    onClick={() => {
                      dispatch(setShowLicenseeLocationPicker());
                    }}
                    size="sm"
                    style={{ width: 'fit-content' }}
                    variant="outlined"
                  >
                    {activeLicenseeLocation?.name
                      ? t('Generic.change')
                      : t('Generic.choose')}
                  </Button>
                </StaffChooseLocationContainer>
              )}
            </FilterCardSurface>
          </StickyArea>
        )}
    </>
  );
};

const FilterCardSurface = styled(Surface)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
  overflow-y: auto;
`;

const StaffChooseLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

export default FilterAndSortTeamMembers;
