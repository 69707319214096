import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import ChickenSpinner from '@/components/ChickenSpinner';

interface PdfDownloadProps {
  url: string;
}

const PdfDownload: React.FC<PdfDownloadProps> = ({ url }) => {
  const handleDownload = useCallback(
    (name?: string) => {
      var link = document.createElement('a');
      link.download = name as string;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [url],
  );

  useEffect(() => {
    handleDownload();
  }, [handleDownload]);

  return (
    <StyledPdfContainer>
      <ChickenSpinner />
    </StyledPdfContainer>
  );
};

const StyledPdfContainer = styled.div`
  height: 100vh;
  witdh: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PdfDownload;
