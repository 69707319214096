import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  selectUserPermissions,
  selectUserRestrictions,
} from 'store/user/selectors';
import ConfirmationModal from '@/components/popups/ConfirmationModal';

export const withRoles =
  (
    Component: React.ElementType,
    requiredRoles: string[] = [],
    restrictedRoles: string[] = [],
  ) =>
  (props: any) => {
    const noRequiredRoles = !requiredRoles.length;
    const userPermissions = useSelector(selectUserPermissions);

    const userRestrictions = useSelector(selectUserRestrictions);
    const { t } = useTranslation();
    const history = useHistory();

    // use values from auth call stored in the user redux store when xpAPi is turned on
    const hasRoles = requiredRoles.some(role => userPermissions[role]);

    const hasRestrictedRoles = restrictedRoles.some(role =>
      userRestrictions.includes(role),
    );

    if (!!hasRestrictedRoles) {
      return (
        <ConfirmationModal
          bodyText={t('LoadingResourceError.unauthorizedParagraph')}
          headerText={t('GenericError.unauthorized')}
          isError={true}
          isOpen={true}
          onClose={() => history.push('/')}
          primaryButtonHandler={() => history.push('/')}
          primaryButtonText={t('Button.returnToHomepage')}
          primaryButtonVariant="danger"
        />
      );
    } else if (!hasRoles && !noRequiredRoles) {
      return (
        <ConfirmationModal
          bodyText={t('UserNotAssignedToRoleError.errorParagraph')}
          headerText={t('GenericError.unauthorized')}
          isError={true}
          isOpen={true}
          onClose={() => history.push('/')}
          primaryButtonHandler={() => history.push('/')}
          primaryButtonText={t('Button.returnToHomepage')}
          primaryButtonVariant="danger"
        />
      );
    } else {
      return <Component {...props} />;
    }
  };
