import { Typography } from 'cfa-react-components';
import styled from 'styled-components';

export const StyledContentWrapper = styled.div<{ $isPrinting: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  align-items: ${({ $isPrinting }) => !$isPrinting && 'center'};
`;

export const PageWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  padding: ${({ $isMobile }) => !$isMobile && '40px 0 0 0'};
`;

export const PrintTitle = styled.h1`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  margin-bottom: 40px;
  padding-left: 40px;
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DesktopWrapper = styled.div<{ $isPrinting: boolean }>`
  display: flex;
  justify-content: center;
  ${({ $isPrinting }) =>
    $isPrinting &&
    `
      zoom: 0.75;

      div.fig {
        box-shadow: none;
      }

      h2.heading-sticky {
        position: initial;
        box-shadow: none;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
      }

      iframe {
        display: none;
        height: 0;
      }

      @page {
        size: auto;
        /* this affects the margin in the printer settings */
        margin: 20mm 0 20mm 10mm;
      }
    `}
`;

export const DocumentWrapper = styled.div<{
  $isExtraLg: boolean;
  $isPrinting: boolean;
}>`
  background: ${({ $isExtraLg }) => ($isExtraLg ? 'white' : 'initial')};
  box-shadow: ${({ $isPrinting, theme }) =>
    !$isPrinting && theme.boxShadow.elevation4};
  width: 100%;
  max-width: 652px;
  border-top: ${({ $isPrinting, theme }) =>
    !$isPrinting && `5px solid ${theme.primaryPalette.navyBlue}`};
  position: relative;
  margin-right: ${({ $isExtraLg, $isPrinting }) =>
    $isExtraLg && !$isPrinting ? '312px' : 0};
`;

export const MobileImageWrapper = styled.div`
  display: inline-block;
  margin: 0 auto;
  transform: translateY(-45%);
`;

export const DocumentImageWrapper = styled.div<{ $isMobile: boolean }>`
  margin-left: ${({ $isMobile }) => ($isMobile ? 0 : '40px')};
  transform: ${({ $isMobile }) => ($isMobile ? 'none' : 'translateY(-45%)')};
  border-radius: 50%;
  background-color: white;
  height: 84px;
  width: 84px;
  padding: 3px;
  box-shadow: ${({ theme }) => theme.boxShadow.elevation8};
`;

export const DocumentImageOuterCircle = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  border: ${({ theme }) => `3px solid ${theme.primaryPalette.navyBlue}`};
`;

export const DocumentImage = styled.img`
  background-color: white;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CopyrightFooter = styled.div<{
  $isMobile: boolean;
  $isExtraLg: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${({ $isExtraLg, $isMobile }) =>
    $isExtraLg ? '964px' : $isMobile ? '100%' : '652px'};
  padding-left: ${({ $isMobile }) => ($isMobile ? '20px' : '40px')};
  padding-right: ${({ $isExtraLg, $isMobile }) =>
    $isExtraLg ? '352px' : $isMobile ? '20px' : '40px'};
  margin: 48px auto;
`;

export const CopyrightFooterText = styled(Typography)`
  margin-top: 32px;
`;
