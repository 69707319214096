import { FC, useEffect } from 'react';
import { IframeView } from '../common';
import PageNotFound404 from '@/pages/404/404';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import GenericError from '@/components/Error/GenericError';

import useDocumentCookieRefresh from '@/hooks/useDocumentCookieRefresh';
import {
  useComplianceCourse,
  useComplianceTimer,
} from '@/utils/document-utils';

interface CompliancePlanProps {
  courseId: string;
}

/** View for compliance plans. */
const CompliancePlan: FC<CompliancePlanProps> = ({ courseId }) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { complianceUrl, error } = useComplianceCourse(courseId);
  const { startTimer, stopTimer } = useComplianceTimer(courseId);
  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();

  useEffect(() => {
    startTimer();
    return () => stopTimer();
  }, [startTimer, stopTimer]);

  if (error) {
    if (error.status === 404) {
      notifyBugsnag(error);
      return <PageNotFound404 />;
    } else {
      notifyBugsnag(error);
      return <GenericError />;
    }
  }

  if (!isDocumentCookieSuccess) {
    return null;
  }

  return <IframeView isCompliance url={complianceUrl} />;
};

export default CompliancePlan;
